import _ from "lodash";
import React, { PureComponent } from "react";
import swal from "sweetalert";

import { getAllCMBS, deleteCMBS } from "./utils";
import config from "./config";
import ItemListItem from "./ItemListItem";

import SearchBox from "../../common/searchBox";
import Table from "../../common/table";

import globalConfig from "../../../config/index.json";
import { NO_MATCHING_DOCUMENTS } from "../../../utils/constants";

const headerList = [
  { title: "NO." },
  { title: "CMBS" },
  { title: "Category1" },
  { title: "Category2" },
  { title: "Created At" },
  { title: "Last Updated At" },
  { title: "Action", className: "w100" },
];

class Index extends PureComponent {
  state = {
    loading: true,
    cmbsCodes: [],
    searchField: "cmbs",
    searchValue: "",

    // state for pagination
    cmbsPagination: [],
  };

  componentDidMount() {
    //load list data for the component
    this.getCMBSCodes({ next: true });
  }

  handleChange = (event) => {
    if (event.target.id === "searchKeyInput") {
      this.setState({ searchField: event.target.value });
    } else if (event.target.id === "searchValueInput") {
      this.setState({ searchValue: event.target.value });
    }
  };

  getCMBSCodes = async ({ next = true, refresh = false, reset = false }) => {
    try {
      this.setState({ loading: true });

      let { cmbsPagination } = this.state;
      let nextState = { loading: false };
      let cmbsPaginationNextDoc;

      if (reset) {
        cmbsPagination = [];
      } else if (refresh) {
        cmbsPaginationNextDoc = cmbsPagination[cmbsPagination.length - 2];
        cmbsPagination = _.slice(cmbsPagination, 0, cmbsPagination.length - 1);
      } else if (next) {
        cmbsPaginationNextDoc = cmbsPagination[cmbsPagination.length - 1];
      } else {
        cmbsPaginationNextDoc = cmbsPagination[cmbsPagination.length - 3];
        cmbsPagination = _.slice(cmbsPagination, 0, cmbsPagination.length - 2);
      }

      const getCMBSCodesRes = await getAllCMBS({
        searchField: this.state.searchField,
        searchValue: this.state.searchValue,
        nextDoc: cmbsPaginationNextDoc,
      });

      if (!getCMBSCodesRes.status) {
        throw { message: getCMBSCodesRes.message };
      }

      if (getCMBSCodesRes.data !== NO_MATCHING_DOCUMENTS) {
        const { documents, nextDoc } = getCMBSCodesRes.data;
        nextState.cmbsCodes = documents;
        nextState.cmbsPagination = [...cmbsPagination, nextDoc];
      } else {
        nextState.cmbsCodes = [];
        nextState.cmbsPagination = [];
      }

      // update state
      this.setState(nextState);
    } catch (error) {
      console.log("getCMBSCodes ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  handleRefresh = () => {
    this.setState({ searchValue: "" }, () =>
      this.getCMBSCodes({ reset: true })
    );
  };

  handleSearch = async (selectedFilter, searchValue) => {
    this.setState(
      {
        searchField: selectedFilter,
        searchValue: searchValue,
      },
      () => {
        if (searchValue !== "") {
          this.getCMBSCodes({ reset: true });
        } else {
          swal("Error!", "Search text can not be empty!", "warning");
        }
      }
    );
  };

  handleDelete = async (cmbs = {}) => {
    const willDelete = await swal({
      title: "Are you sure you want to remove CMBS code " + cmbs.id + " ?",
      text: "Once deleted, you will not be able to recover this Item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      this.setState({ loading: true });
      const deleteCMBSRes = await deleteCMBS({ cmbsId: cmbs.id });
      this.setState({ loading: false });
      if (deleteCMBSRes.status) {
        this.getCMBSCodes({ refresh: true });
        swal("success", "CMBS code deleted sucessfully", "success");
      } else {
        swal("Error", deleteCMBSRes.message, "error");
      }
    }
  };

  renderTableHeader = () => {
    return (
      <>
        <h3 className="card-title">CMBS</h3>
        <SearchBox
          filterList={config.searchFilter}
          handleSearch={this.handleSearch}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  };

  renderTableData = () => {
    const startNumber =
      globalConfig.paginationLimit * (this.state.cmbsPagination.length - 1) + 1;

    return this.state.cmbsCodes.map((item, index) => {
      return (
        <ItemListItem
          key={item.id}
          item={{ ...item, orderNumber: startNumber + index }}
          onPressDelete={() => this.handleDelete(item)}
        />
      );
    });
  };

  handlePreviousBtn = async () => {
    this.getCMBSCodes({ next: false }); // false is flag to go to previous page
  };

  handleNextBtn = async () => {
    this.getCMBSCodes({ next: true });
  };

  render() {
    const hasCMBS = this.state.cmbsCodes.length > 0;

    return (
      <Table
        loading={this.state.loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasCMBS}
        hidePagination={false}
        hasPrevious={this.state.cmbsPagination.length > 1}
        hasNext={
          this.state.cmbsPagination[this.state.cmbsPagination.length - 1]
        }
        onNextClick={this.handleNextBtn}
        onPreviousClick={this.handlePreviousBtn}
      >
        {hasCMBS ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default Index;

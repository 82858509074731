import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { Switch, Route, NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";

import { userSignOut } from "../../assets/js/firebase";
import { logout } from "../../utils/index";
import { userInfoHelper, capitalizeEachWord } from "../../utils/helpers";
import { roleRoute } from "../../routes/index";

import { toggleLeftMenuAction } from "../../actions/settingsAction";

import userImage from "../../assets/images/user.png";

import Header from "./Header";
import Footer from "./Footer";
import DefaultLink from "./DefaultLink";
import PageNotFound from "./PageNotFound";

const masterNone = {
  display: "none",
};

const masterBlock = {
  display: "block",
};

const SIDE_MENU = [
  {
    id: "Directories",
    label: "Menu",
    to: "/",
  },
  {
    id: 1,
    icon: "icon-users",
    label: "Users",
    to: "#!",
    content: [
      {
        id: 2,
        label: "Manage Users",
        to: "/users",
      },
      {
        id: 3,
        label: "Add User",
        to: "/users-add",
      },
    ],
  },
  {
    id: 4,
    icon: "fa fa-building-o",
    label: "Hospitals",
    to: "#!",
    content: [
      {
        id: 5,
        label: "Manage Hospitals",
        to: "/hospitals",
      },
      {
        id: 6,
        label: "Add Hospital",
        to: "/hospitals-create",
      },
    ],
  },
  {
    id: 7,
    icon: "fa fa-pencil-square-o",
    label: "Admissions",
    to: "#!",
    content: [
      {
        id: 8,
        label: "Manage Admission",
        to: "/admissions",
      },
    ],
  },
  {
    id: 9,
    icon: "fa fa-file-o",
    label: "Documents",
    to: "#!",
    content: [
      {
        id: 10,
        label: "Create New",
        to: "/documents-create-new",
      },
      {
        id: 11,
        label: "Education",
        to: "/documents-education",
      },
      {
        id: 12,
        label: "My Health",
        to: "/documents-my-health",
      },
    ],
  },
  {
    id: 13,
    icon: "fa fa-bookmark-o",
    label: "CMBS",
    to: "#!",
    content: [
      {
        id: 14,
        label: "Manage CMBS",
        to: "/cmbs",
      },
      {
        id: 15,
        label: "Add CMBS",
        to: "/cmbs-create",
      },
    ],
  },
  {
    id: 16,
    icon: "icon-bell",
    label: "Notifications",
    to: "#!",
    content: [
      {
        id: 17,
        label: "General Message",
        to: "/notifications-general",
      },
    ],
  },
  {
    id: 18,
    icon: "fa fa-server",
    label: "Logs",
    to: "#!",
    content: [
      {
        id: 19,
        label: "All Logs",
        to: "/logs",
      },
    ],
  },
];

class Menu extends Component {
  constructor(props) {
    super(props);

    this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handler = this.handler.bind(this);

    this.state = {
      loading: true,
      isToggleLeftMenu: false,
      isOpenUserMenu: false,
      parentlink: null,
      childlink: null,
      routes: [],
      sideMenuContent: [],
      user: {
        firstName: "",
        lastName: "",
        role: "",
        status: false,
        phone: "",
      },
    };
  }

  async componentDidMount() {
    // get userinfo
    const userInfo = await userInfoHelper(window);

    // get routes based on role
    const routes = [
      ...roleRoute[userInfo.role].routes,
      ...roleRoute.common.routes,
    ];

    // filter the side menu based on role
    let sideMenuContent = [];
    _.map(SIDE_MENU, (menu) => {
      if (menu.content) {
        let submenuContent = [];
        _.map(menu.content, (submenu) => {
          const isFound = _.findIndex(routes, ["path", submenu.to]);
          isFound !== -1 && submenuContent.push(submenu); // add submenu if found
        });
        // if one of the submenu matched, push the parent menu with founded submenu
        if (submenuContent.length > 0) {
          sideMenuContent.push({ ...menu, content: submenuContent });
        }
      } else if (menu.id === "Directories") {
        // directories is key word id, recommend to add to the content
        sideMenuContent.push(menu);
      } else {
        const isFound = _.findIndex(routes, ["path", menu.to]);
        isFound !== -1 && sideMenuContent.push(menu); // add menu if index is not -1
      }
    });

    // update state with new value
    if (userInfo) {
      const user = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        role: userInfo.role,
        status: userInfo.status,
        phone: userInfo.phone,
      };
      this.setState({ user, routes, sideMenuContent, loading: false });
    } else {
      this.setState({ routes, sideMenuContent, loading: false });
    }

    const { location } = this.props;
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];

    if (parentlink && nochildlink && nochildlink === "dashboard") {
      this.handler(parentlink, `${parentlink}${nochildlink}`);
    } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
      this.handler(parentlink, nochildlink);
    } else if (parentlink) {
      this.handler(parentlink, "");
    } else {
      this.handler("hr", "dashboard");
    }
  }

  componentDidUpdate(prevprops, prevstate) {
    const { location } = this.props;
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];
    if (prevprops.location !== location) {
      if (parentlink && nochildlink && nochildlink === "dashboard") {
        this.handler(parentlink, `${parentlink}${nochildlink}`);
      } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
        this.handler(parentlink, nochildlink);
      } else if (parentlink) {
        this.handler(parentlink, "");
      } else {
        this.handler("hr", "dashboard");
      }
    }
  }

  handler(parentlink, nochildlink) {
    this.setState({
      parentlink: parentlink,
      childlink: nochildlink,
    });
  }

  toggleLeftMenu(e) {
    this.props.toggleLeftMenuAction(e);
  }

  toggleUserMenu() {
    this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu });
  }

  toggleSubMenu(e) {
    let menucClass = "";
    if (e.itemId) {
      const mainPathname = this.props.location.pathname.split("-")[0];
      const subClass = e.items.map((menuItem, i) => {
        const isExpandItem =
          mainPathname !== "/" && menuItem.to.startsWith(mainPathname);
        if (menuItem.to === this.props.location.pathname || isExpandItem) {
          menucClass = "in";
        } else {
          menucClass = "collapse";
        }
        return menucClass;
      });
      return subClass;
      // return "collapse";
    } else {
      return e.visible ? "collapse" : "metismenu";
    }
  }

  signOut() {
    userSignOut();
    logout();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const { isOpenUserMenu } = this.state;
    const { darkMinSidebar, istoggleLeftMenu } = this.props;
    const pageHeading = this.state.routes.filter(
      (route) => route.path === this.props.location.pathname
    );

    return (
      <>
        <div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
          <div
            style={this.state.parentlink === "login" ? masterNone : masterBlock}
          >
            <div
              id="header_top"
              className={`header_top ${darkMinSidebar && "dark"}`}
            >
              <div className="container">
                <div className="hleft">
                  <NavLink
                    to="/"
                    onClick={() => this.handler("hr", "dashboard")}
                    className="header-brand"
                  >
                    <i className="fe fe-command brand-logo" />
                  </NavLink>
                </div>
                <div className="hright">
                  <div className="dropdown">
                    <p
                      className="nav-link user_btn"
                      onClick={this.toggleUserMenu}
                    >
                      <img
                        className="avatar"
                        // src="../../assets/images/user.png"
                        src={userImage}
                        alt="fake_alr"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="User Menu"
                      />
                    </p>
                    <p
                      className="nav-link icon menu_toggle"
                      onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}
                    >
                      <i className="fa  fa-align-left" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* user side menu */}
            {/* close when click outside the menu */}
            {isOpenUserMenu && (
              <div className="sidebar-overlay" onClick={this.toggleUserMenu} />
            )}
            <div className={`user_div ${isOpenUserMenu && "open"}`}>
              <h5 className="brand-name mb-4">
                Calvary Administration
                <a href="#" className="user_btn" onClick={this.toggleUserMenu}>
                  <i className="icon-logout" />
                </a>
              </h5>
              <div className="card">
                <div className="card-body">
                  <div className="media">
                    <img
                      className="avatar avatar-xl mr-3"
                      // src="../../assets/images/user.png"
                      src={userImage}
                      alt="avatar"
                    />
                    <div className="media-body">
                      <h5 className="m-0">
                        {`${capitalizeEachWord(
                          this.state.user?.firstName
                        )} ${capitalizeEachWord(this.state.user?.lastName)}`}
                      </h5>
                      {/* <p className="text-muted mb-0">
                        {USER_ROLES[this.state.user.role]}
                      </p> */}
                      <a
                        href="#"
                        className="text-muted mb-0"
                        onClick={this.signOut}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Left side menu */}
            {/* close when click outside the menu */}
            <MediaQuery maxWidth={1200}>
              {istoggleLeftMenu ? (
                <div
                  className="sidebar-overlay"
                  onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}
                />
              ) : null}
            </MediaQuery>
            <div id="left-sidebar" className="sidebar ">
              <h5 className="brand-name">Calvary Administration</h5>
              <nav id="left-sidebar-nav" className="sidebar-nav">
                <MetisMenu
                  className=""
                  content={this.state.sideMenuContent}
                  noBuiltInClassNames={true}
                  classNameContainer={(e) => this.toggleSubMenu(e)}
                  classNameContainerVisible="in"
                  classNameItemActive="active"
                  classNameLinkActive="active"
                  classNameItemHasVisibleChild="active"
                  classNameLink="has-arrow arrow-c"
                  iconNamePrefix=""
                  activeLinkTo={this.props.location.pathname}
                  LinkComponent={(e) => <DefaultLink itemProps={e} />}
                />
              </nav>
            </div>
          </div>

          <div className="page">
            <Header
              dataFromParent={this.props.dataFromParent}
              dataFromSubParent={
                (pageHeading[0] && pageHeading[0].pageTitle) ||
                "Page Not Found 404"
              }
            />
            <Switch>
              {this.state.routes.map((route, idx) => {
                return (
                  <Route
                    key={idx}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              <Route component={PageNotFound} />
            </Switch>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  istoggleLeftMenu: state.settings.isToggleLeftMenu,
});

const mapDispatchToProps = (dispatch) => ({
  toggleLeftMenuAction: (e) => dispatch(toggleLeftMenuAction(e)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);

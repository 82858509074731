import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import { getGeneralMyHealth } from "./utils";
import { deleteMyHealthFolder } from "../../../api/documents";

import Table from "../../common/table";
import config from "./config";

const headerList = [
  { title: "Folder Name" },
  { title: "Description" },
  { title: "Files" },
  { title: "Action", className: "w100" },
];

const initialState = {
  loading: true,
  documentId: "",
  folders: [],
};

class MyHealth extends Component {
  state = initialState;

  async componentDidMount() {
    let newState = {
      documentId: "",
      folders: [],
      loading: false,
    };

    // get the global folder for my health
    const getFolderRes = await getGeneralMyHealth();
    if (getFolderRes.status) {
      newState.folders = getFolderRes.data[0]?.folders || [];
      newState.documentId = getFolderRes.data[0]?.id;
    } else {
      swal("Error", getFolderRes.message, "error");
    }
    this.setState(newState);
  }

  deleteFolder = async (folder) => {
    const willDelete = await swal({
      title: `Are you sure you want to remove "${folder.name}"?`,
      text: "Once deleted, you will not be able to recover this folder",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      this.setState({ loading: true });
      const deleteFolderRes = await deleteMyHealthFolder({
        type: this.state.selectedType,
        documentId: this.state.documentId,
        folderId: folder.id,
      });
      if (deleteFolderRes.message === "OK") {
        let { folders } = this.state;
        const newFolders = _.reject(folders, ["id", folder.id]);
        this.setState({ loading: false, folders: newFolders });
        swal("success", "Folder deleted sucessfully", "success");
      } else {
        this.setState({ loading: false });
        swal("Error", deleteFolderRes.message, "error");
      }
    }
  };

  renderTableHeader = () => {
    return <h3 className="card-title">My Health Folders</h3>;
  };

  renderTableData = () => {
    return this.state.folders.map((folder, idx) => (
      <tr key={idx}>
        <td>{folder.name}</td>
        <td>{folder.description}</td>
        <td>{folder.documents?.length}</td>
        <td>
          <Link
            to={{
              pathname: config.myHealthUpdatePath,
              state: {
                id: this.state.documentId,
                folder: folder,
              },
            }}
            className="btn btn-icon"
          >
            <i className="fa fa-edit" />
          </Link>
          <button
            type="button"
            onClick={() => this.deleteFolder(folder)}
            className="btn btn-icon js-sweetalert"
            title="Delete"
            data-type="confirm"
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </td>
      </tr>
    ));
  };

  render() {
    const hasFolder = this.state.folders.length > 0;
    return (
      <Table
        loading={this.state.loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasFolder}
        hidePagination={true}
      >
        {hasFolder ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default MyHealth;

module.exports = {
  userCollection: "users",
  searchFilter: [
    { value: "email", title: "Email" },
    { value: "firstName", title: "First Name" },
    { value: "lastName", title: "Last Name" },
    { value: "phone", title: "Mobile Number" },
    { value: "role", title: "Role" },
  ],
  emailPagniationLimit: 10,
};

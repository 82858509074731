import React, { Component } from "react";
import swal from "sweetalert";

import {
  getInitialData,
  getAllAdmissions,
  searchItems,
  getItemListObj,
} from "./utils";
import { deleteAdmission } from "../../../api/admission";
import config from "./config";
import globalConfig from "../../../config/index.json";

import ItemListItem from "./ItemListItem";
import SearchBox from "../../common/searchBox";
import Table from "../../common/table";

const headerList = [
  { title: "" },
  { title: "Patient Name" },
  { title: "ID" },
  { title: "Admission Name" },
  { title: "Date" },
  { title: "Status" },
  { title: "Action", className: "w100" },
];

class Index extends Component {
  state = {
    loading: true,
    hospitalData: [],
    selectedHospital: "",
    selectedHospitalName: "",
    admissionData: [],
    searchValue: "",

    // state for pagination
    hasNext: false,
    lastAdmission: [],
  };

  componentDidMount() {
    //load list data for the component
    this.loadInitailData();
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  loadInitailData = async () => {
    try {
      const response = await getInitialData();
      const { selectedHospital, hospitals, admissions } = response?.data;
      let hasNext = false;
      // handle data for pagination
      if (admissions.length > globalConfig.paginationLimit) {
        admissions.pop();
        hasNext = true;
      }

      this.setState({
        selectedHospital: selectedHospital,
        hospitalData: hospitals,
        admissionData: admissions,
        hasNext,
        lastAdmission: [],
        loading: false,
      });

      if (!response.status) {
        swal("Error!", response.message, "error");
      }
    } catch (error) {
      this.setState({ loading: false });
      swal("Error!", "Something went wrong!", "error");
    }
  };

  handleAdmissionCallback = ({ status, message, data: admissions }) => {
    if (status) {
      let hasNext = false;
      // handle data for pagination
      if (admissions.length > globalConfig.paginationLimit) {
        admissions.pop();
        hasNext = true;
      }
      this.setState({ loading: false, admissionData: admissions, hasNext });
    } else {
      this.setState({ loading: false });
      swal("Error!", message, "error");
    }
  };

  handleRefresh = () => {
    this.setState({ loading: true });
    this.loadInitailData();
  };

  loadSearchItems = () => {
    searchItems(this.state.searchField, this.state.searchValue).then((data) => {
      if (0 < data.length) {
        this.setState({
          itemData: data,
          searchValue: "",
        });
      }
    });
  };

  handleSearch = (filter, searchValue) => {
    this.setState({ searchValue });
  };

  handleDelete = async (admission) => {
    const willDelete = await swal({
      title: "Are you sure you want to remove " + admission.specialty + " ?",
      text: "Once deleted, you will not be able to recover this Item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      this.setState({ loading: true });
      const deleteAdmissionRes = await deleteAdmission(admission.id);
      if (deleteAdmissionRes.message === "OK") {
        swal("success", "Item deleted sucessfully", "success");
        // reload the page
        const lastDoc =
          this.state.lastAdmission[this.state.lastAdmission.length - 1];
        const getAdmissionRes = await getAllAdmissions(
          this.state.selectedHospital, // hospitalId
          this.state.selectedHospitalName,
          lastDoc // startAfter
        );
        this.handleAdmissionCallback(getAdmissionRes);
      } else {
        this.setState({ loading: false });
        swal("Error", deleteAdmissionRes.message, "error");
      }
    }
  };

  handleHospitalChange = (event) => {
    const { value, selectedIndex } = event.target;
    const hospitalId = value;
    const hospitalName = event.target[selectedIndex].text; // get hosptial name
    this.setState(
      {
        selectedHospitalName: hospitalName,
        selectedHospital: hospitalId,
        loading: true,
      },
      async () => {
        const getAdmissionRes = await getAllAdmissions(
          this.state.selectedHospital, // hospitalId
          this.state.selectedHospitalName,
          null // startAfter
        );
        this.handleAdmissionCallback(getAdmissionRes); // callback
      }
    );
  };

  renderHospitalData = () => {
    return this.state.hospitalData.map((hospital, index) => {
      return (
        <option key={index} value={hospital.id}>
          {hospital.name}
        </option>
      );
    });
  };

  renderTableHeader = () => {
    return (
      <>
        {/* Hospital Dropdown */}
        <div>
          <select
            className="custom-select"
            value={this.state.selectedHospital}
            onChange={this.handleHospitalChange}
          >
            {this.renderHospitalData()}
          </select>
        </div>

        {/* Search box group */}
        <SearchBox
          filterList={config.searchFilter}
          handleSearch={this.handleSearch}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  };

  renderTableData = () => {
    return this.state.admissionData.map((admission) => {
      const itemData = getItemListObj(admission);
      return (
        <ItemListItem
          key={itemData.id}
          itemData={itemData}
          itemObj={admission}
          itemId={itemData.id}
          onPressDelete={() => this.handleDelete(admission)}
        ></ItemListItem>
      );
    });
  };

  handlePreviousBtn = async () => {
    this.setState({ loading: true });

    // pop twice to get the previous cursor
    this.state.lastAdmission.pop();
    const lastDoc =
      this.state.lastAdmission[this.state.lastAdmission.length - 1];
    const getAdmissionRes = await getAllAdmissions(
      this.state.selectedHospital, // hospitalId
      this.state.selectedHospitalName,
      lastDoc // startAfter
    );
    this.handleAdmissionCallback(getAdmissionRes);
  };

  handleNextBtn = async () => {
    this.setState({ loading: true });

    const { admissionData } = this.state;
    const lastDoc = admissionData[admissionData.length - 1].doc;

    // update state this way to previous re-render
    this.state.lastAdmission.push(lastDoc);

    const getAdmissionRes = await getAllAdmissions(
      this.state.selectedHospital, // hospitalId
      this.state.selectedHospitalName,
      lastDoc // startAfter
    );
    this.handleAdmissionCallback(getAdmissionRes);
  };

  render() {
    const { loading } = this.state;
    const hasAdmission = this.state.admissionData.length > 0;

    return (
      <Table
        loading={loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasAdmission}
        hidePagination={false}
        hasPrevious={this.state.lastAdmission.length > 0}
        hasNext={this.state.hasNext}
        onNextClick={this.handleNextBtn}
        onPreviousClick={this.handlePreviousBtn}
      >
        {hasAdmission ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default Index;

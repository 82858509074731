import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  capitalizeEachWord,
  convertToMomentInstance,
} from "../../../utils/helpers";
import config from "./config";

export default class ItemListItem extends Component {
  render() {
    const {
      id,
      orderNumber,
      goShareCategory,
      goShareCategory2,
      createdAt,
      updatedAt,
    } = this.props.item;
    return (
      <tr>
        {/* number */}
        <td>
          <span>{orderNumber}</span>
        </td>

        {/* cmbs */}
        <td>
          <span>{id}</span>
        </td>

        {/* category1 */}
        <td>
          <span>{capitalizeEachWord(goShareCategory)}</span>
        </td>

        {/* category2 */}
        <td>
          <span>{capitalizeEachWord(goShareCategory2)}</span>
        </td>

        {/* created at */}
        <td>
          <span>
            {createdAt
              ? convertToMomentInstance(createdAt).format("D MMMM yyyy h:mm a")
              : null}
          </span>
        </td>

        {/* updated at */}
        <td>
          <span>
            {updatedAt
              ? convertToMomentInstance(updatedAt).format("D MMMM yyyy h:mm a")
              : null}
          </span>
        </td>

        {/* action */}
        <td>
          {/* edit button */}
          <Link
            to={{
              pathname: config.updatePath,
              item: this.props.item,
            }}
            className="btn btn-icon"
          >
            <i className="fa fa-edit" />
          </Link>

          {/* delete button */}
          <button
            type="button"
            onClick={() => this.props.onPressDelete(this.props.item)}
            className="btn btn-icon js-sweetalert"
            title="Delete"
            data-type="confirm"
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </td>
      </tr>
    );
  }
}

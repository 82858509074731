import axios, { errorHandler } from "./index";

export const updateLocation = async ({
  locationId,
  name,
  shortName,
  siteCode,
  episoftSiteCode,
  address,
  address2,
  suburb,
  state,
  postCode,
  geo,
  cardImage,
  cardURI,
  email,
  phone,
  website,
  goShareIdentifier,
  status,
  visitingHrs,
  shareCont,
  verifyPatientEmail,
  timeZone,
  type,
  isPrivate,
}) => {
  try {
    const instance = await axios();
    const response = await instance.post("/location/updateLocationAP", {
      locationId,
      name,
      shortName,
      siteCode,
      episoftSiteCode,
      address,
      address2,
      suburb,
      state,
      postCode,
      geo,
      cardImage,
      cardURI,
      email,
      phone,
      website,
      goShareIdentifier,
      status,
      visitingHrs,
      shareCont,
      verifyPatientEmail,
      timeZone,
      type,
      isPrivate,
    });
    return response?.data;
  } catch (error) {
    console.log("updateLocation ~ error:", JSON.stringify(error));
    return errorHandler(error);
  }
};

import axios, { errorHandler } from "./index";

export const clearUserTokens = async (userId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/auth/clearUserTokens", {
      userId,
    });
    return response.data;
  } catch (error) {
    console.log("clearUserTokens ~ error", error);
    return errorHandler(error);
  }
};

export const postLoginHandler = async () => {
  try {
    const instance = await axios();
    const response = await instance.post("/auth/postLoginHandler");
    return response.data;
  } catch (error) {
    console.log("postLoginHandler ~ error", error);
    return errorHandler(error);
  }
};

let controller = "/admissions";
let collection = "locations";
let hospitalCollection = "locations";
let admissionCollection = "admissions";
let userCollection = "users";
let educationCollection = "education";
let rootRouteName = "admissions";
let navigationRoot = "/admissions";
let rootPageTitle = "Admissions";

module.exports = {
  controller: controller,
  tableFieldList: [
    "accessToken",
    "bundleId",
    "bundleName",
    "cmbs",
    "nameOverride",
    "description",
    "statusMatch",
    "url",
    "isSent",
    "createdAt",
    "updatedAt",
  ],
  navigationRoot: "/admissions",
  hospitalCollection: hospitalCollection,
  admissionCollection: admissionCollection,
  userCollection: userCollection,
  educationCollection: educationCollection,
  collection: collection,

  searchFilter: [
    { value: "patientEmail", title: "Patient Email" },
    { value: "patientName", title: "Patient Name" },
    { value: "admissionID", title: "Admission ID" },
  ],

  admissionStatus: ["Admit", "Finished"],
  educationStatus: ["discharge", "accept"],

  //EndPoints
  deleteEP: controller + "/delete",
  searchEP: controller + "/search",

  updateEP: controller + "/update",
  getAllEP: controller + "/",

  // Routing values
  rootPageTitle: rootPageTitle,
  rootRouteName: rootRouteName,
  updatePath: navigationRoot + "-update",
  updatePathName: rootRouteName + "-update",
  updatePageTitle: "Update " + rootPageTitle,
};

import config from "./config";

// Axios  Helpers

// End of Axios Helpers

// Object Helpers
export const getCreateReqObj = function createObject(values) {
  let keys = config.tableFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

export const getCreateNotificationReqObj = function createObject(values) {
  let keys = config.notificationFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

export const getCreateEmailReqObj = function createObject(values) {
  let keys = config.emailFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

// End of Object Helpers

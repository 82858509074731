import React, { useEffect } from "react";
import linker from "./LinkingService";
import config from "../../../config";

const Welcome = () => {
  useEffect(() => {
    linker.openURL(config.appDeepLink);
  }, []);

  return <div>Loading calvary app...</div>;
};

export default Welcome;

let controller = "/document";
let collection = "documents";
let hospitalCollection = "documents";
let admissionCollection = "admissions";
let userCollection = "users";
let calvaryUserProfileCollection = "calvaryUserProfiles";
let educationCollection = "education";
let otherLearnCollection = "otherLearnDocuments";
let generalDocumentCollection = "generalDocuments";
let locationCollection = "locations";
let siteSpecificDocumentCollection = "siteSpecificDocuments";
let rootRouteName = "documents";
let navigationRoot = "/documents";
let rootPageTitle = "Documents";

module.exports = {
  controller: controller,
  tableFieldList: [
    "accessToken",
    "bundleId",
    "bundleName",
    "cmbs",
    "nameOverride",
    "description",
    "statusMatch",
    "url",
    "isSent",
    "createdAt",
    "updatedAt",
  ],
  navigationRoot: "/documents",
  hospitalCollection: hospitalCollection,
  admissionCollection: admissionCollection,
  userCollection: userCollection,
  calvaryUserProfileCollection: calvaryUserProfileCollection,
  educationCollection: educationCollection,
  otherLearnCollection: otherLearnCollection,
  generalDocumentCollection: generalDocumentCollection,
  locationCollection: locationCollection,
  siteSpecificDocumentCollection: siteSpecificDocumentCollection,
  collection: collection,

  searchFilter: [
    { value: "patientEmail", title: "Patient Email" },
    { value: "patientName", title: "Patient Name" },
    { value: "admissionID", title: "Admission ID" },
  ],

  admissionStatus: ["Admit", "Finished"],
  educationStatus: ["discharge", "accept"],

  //EndPoints
  deleteEP: controller + "/delete",
  searchEP: controller + "/search",
  createEP: controller + "/create",
  updateEP: controller + "/update",
  getAllEP: controller + "/",

  // Routing values
  rootPageTitle: rootPageTitle,
  rootRouteName: rootRouteName,
  createNewPageTitle: "Create New",
  createNewPath: navigationRoot + "-create-new",
  createNewPathName: rootRouteName + "-create-new",

  myHealthPageTitle: "My Health",
  myHealthPath: navigationRoot + "-my-health",
  myHealthPathName: rootRouteName + "-my-health",
  myHealthUpdatePageTitle: "My Health Update",
  myHealthUpdatePath: navigationRoot + "-my-health-update",
  myHealthUpdatePathName: rootRouteName + "-my-health-update",

  educationPageTitle: "Education",
  educationPath: navigationRoot + "-education",
  educationPathName: rootRouteName + "-education",
  educationUpdatePageTitle: "Education Update",
  educationUpdatePath: navigationRoot + "-education-update",
  educationUpdatePathName: rootRouteName + "-education-update",

  // ids
  learnGeneralDocId: "learnGeneralFolder",
};

import _ from "lodash";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  getAllCalvaryProfiles,
  getUserAdmissions,
  createLearnFolder,
  createGlobalLearnFolder,
  createGlobalMyHealthFolder,
  getAllHospitals,
  createSiteSpecificLearnFolder,
  getUserByEmail,
} from "./utils";

import {
  GLOBAL_TYPE,
  USER_TYPE,
  LEARN,
  MY_HEALTH,
  SITE_SPECIFIC_TYPE,
  PDF,
  VIDEO,
  EDITOR_TOOLBAR_OPTIONS,
  EDITOR_STYLE,
  EDITOR_LABELS,
  EDITOR_BLOCK_TYPE,
  EDITOR_INLINE,
} from "../../../utils/constants";
import FileUpload from "../../Shared/FileUpload";
import Loader from "../../Shared/Loader";
import { capitalizeEachWord, validateEmail } from "../../../utils/helpers";

const initialState = {
  loading: false,
  percentage: 0,
  isModified: false,

  // for dropdown
  types: [GLOBAL_TYPE, SITE_SPECIFIC_TYPE, USER_TYPE],
  sections: [],

  // selected values
  selectedType: "",
  selectedSection: "",
  selectedHospitalId: "",
  selectedEmailAddress: "",
  selectedUserId: "",
  selectedCalvaryProfileId: "",
  selectedAdmissionId: "",

  // list of data
  hospitals: [],
  calvaryProfiles: [],
  admissions: [],

  // folder information
  folderName: "",
  folderDescription: "",
  documents: [],
};

class CreateNew extends Component {
  state = initialState;

  async componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

    const location = this.props.history?.location || {};

    // check payload from routes
    if (
      !_.isEmpty(location) &&
      location?.state?.type &&
      location?.state?.user
    ) {
      this.setState(
        {
          selectedType: location?.state?.type,
          selectedEmailAddress: location?.state?.user?.email,
        },
        () => this.handleUserChange(location?.state?.user?.id)
      );
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleTypeChange = async (event) => {
    this.setState({ loading: true });
    const { value } = event.target;
    let newState = {
      hospitals: [],
      admissions: [],
      selectedType: value,
      selectedSection: "",
      selectedHospitalId: "",
      selectedUserId: "",
      selectedEmailAddress: "",
      selectedCalvaryProfileId: "",
      selectedAdmissionId: "",
      loading: false,
    };
    if (value === GLOBAL_TYPE) {
      newState.sections = [LEARN];
    } else if (value === USER_TYPE) {
      newState.sections = [LEARN];
    } else if (value === SITE_SPECIFIC_TYPE) {
      newState.sections = [LEARN];
      // get all hospitals
      const getHospitalRes = await getAllHospitals();
      if (getHospitalRes.status) {
        newState.hospitals = getHospitalRes.data;
      } else {
        swal("Error", getHospitalRes.message, "error");
      }
    } else {
      newState.sections = [];
    }

    // auto select section if there's only one option
    if (_.isArray(newState.sections) && newState.sections.length === 1) {
      newState.selectedSection = newState.sections[0];
    }

    this.setState(newState);
  };

  clearSelectedUser = () => {
    this.setState({
      selectedEmailAddress: "",
      selectedUserId: "",
      selectedCalvaryProfileId: "",
      calvaryProfiles: [],
      selectedAdmissionId: "",
      admissions: [],
    });
  };

  handleEmailKeyPress = (event) => {
    if (!this.state.selectedUserId && event.keyCode === 13) {
      // The Enter key (key code 13) was pressed
      this.searchEmailAddress();
    }
  };

  searchEmailAddress = async () => {
    const { selectedEmailAddress } = this.state;

    if (!selectedEmailAddress) {
      swal("Error", "Please enter user email address.", "error");
      return;
    } else if (!validateEmail(selectedEmailAddress)) {
      swal("Error", "Please enter a valid email address", "error");
      return;
    }

    // query by email address
    const getUserRes = await getUserByEmail(selectedEmailAddress);

    if (!getUserRes.status) {
      swal("Error", "Something went wrong.", "error");
      return;
    }

    const { data } = getUserRes;

    if (!data) {
      swal("Error", "User does not exist.", "error");
      return;
    }

    this.handleUserChange(data.id);
  };

  handleUserChange = async (userId) => {
    this.setState({
      loading: true,
      selectedUserId: userId,
      selectedCalvaryProfileId: "",
      calvaryProfiles: [],
      selectedAdmissionId: "",
      admissions: [],
    });

    let newState = { loading: false };

    // get calvary profile by userId
    const getCalvaryProfileRes = await getAllCalvaryProfiles(userId);
    if (getCalvaryProfileRes.status) {
      newState.calvaryProfiles = getCalvaryProfileRes.data;
    } else {
      swal("Error", getCalvaryProfileRes.message, "error");
    }

    this.setState(newState);
  };

  handleProfileChange = async (event) => {
    const { value: calvaryProfileId } = event.target;
    this.setState({
      loading: true,
      selectedCalvaryProfileId: calvaryProfileId,
      selectedAdmissionId: "",
      admissions: [],
    });

    let newState = { loading: false };

    // get calvary profile by userId
    const getAdmissionRes = await getUserAdmissions(
      calvaryProfileId,
      this.state.selectedUserId
    );
    if (getAdmissionRes.status) {
      newState.admissions = getAdmissionRes.data;
    } else {
      swal("Error", getAdmissionRes.message, "error");
    }

    this.setState(newState);
  };

  handleAdmissionChange = async (event) => {
    const { value: admissionId } = event.target;
    this.setState({ selectedAdmissionId: admissionId });
  };

  handleChange = (event) => {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
  };

  disabledFolderSection = () => {
    const {
      selectedType,
      selectedSection,
      selectedHospitalId,
      selectedUserId,
      selectedCalvaryProfileId,
      selectedAdmissionId,
      loading,
    } = this.state;

    if (selectedType === GLOBAL_TYPE) {
      return loading || !(selectedType && selectedSection);
    } else if (selectedType === USER_TYPE) {
      if (selectedSection === LEARN) {
        return (
          loading ||
          !(
            selectedAdmissionId &&
            selectedSection &&
            selectedUserId &&
            selectedCalvaryProfileId &&
            selectedType
          )
        );
      }
    } else if (selectedType === SITE_SPECIFIC_TYPE) {
      return (
        loading || !(selectedType && selectedSection && selectedHospitalId)
      );
    }
    return true;
  };

  setLoaderPercentage = (percentage) => {
    this.setState({ percentage: Math.round(percentage) });
  };

  createUserLearnFolder = async (successCallback) => {
    const {
      selectedUserId: userId,
      selectedCalvaryProfileId: calvaryProfileId,
      selectedAdmissionId: admissionId,
      folderName,
      folderDescription,
      documents,
    } = this.state;

    // check required fields are not empty
    if (
      userId &&
      calvaryProfileId &&
      admissionId &&
      folderName &&
      folderDescription &&
      documents.length
    ) {
      this.setState({ loading: true });
      const response = await createLearnFolder(
        {
          calvaryProfileId,
          userId,
          admissionId,
          folderName,
          folderDescription,
          documents,
        },
        this.setLoaderPercentage
      );

      const newState = { loading: false, percentage: 0 };
      if (response.status) {
        newState.folderName = "";
        newState.folderDescription = "";
        newState.documents = [];
        newState.isModified = false;
        swal("Success", "The folder has been created", "success");
        successCallback && successCallback();
      } else {
        swal("Error", response.message, "error");
      }
      this.setState(newState);
    } else {
      swal("Error", "Please fill in all the required field", "error");
    }
  };

  createGlobalLearnFolder = async (successCallback) => {
    const { folderName, folderDescription, documents } = this.state;

    // check required fields are not empty
    if (folderName && folderDescription && documents.length) {
      this.setState({ loading: true });
      const response = await createGlobalLearnFolder(
        {
          folderName,
          folderDescription,
          documents,
        },
        this.setLoaderPercentage
      );

      const newState = { loading: false, percentage: 0 };
      if (response.status) {
        newState.folderName = "";
        newState.folderDescription = "";
        newState.documents = [];
        newState.isModified = false;
        swal("Success", "The folder has been created", "success");
        successCallback && successCallback();
      } else {
        swal("Error", response.message, "error");
      }
      this.setState(newState);
    } else {
      swal("Error", "Please fill in all the required field", "error");
    }
  };

  createGlobalMyHealthFolder = async (successCallback) => {
    const { folderName, folderDescription, documents } = this.state;

    // check required fields are not empty
    if (folderName && folderDescription && documents.length) {
      this.setState({ loading: true });
      const response = await createGlobalMyHealthFolder(
        {
          folderName,
          folderDescription,
          documents,
        },
        this.setLoaderPercentage
      );

      const newState = { loading: false, percentage: 0 };
      if (response.status) {
        newState.folderName = "";
        newState.folderDescription = "";
        newState.documents = [];
        newState.isModified = false;
        swal("Success", "The folder has been created", "success");
        successCallback && successCallback();
      } else {
        swal("Error", response.message, "error");
      }
      this.setState(newState);
    } else {
      swal("Error", "Please fill in all the required field", "error");
    }
  };

  createSiteSpecificLearnFolder = async (successCallback) => {
    const { selectedHospitalId, folderName, folderDescription, documents } =
      this.state;

    // check required fields are not empty
    if (
      selectedHospitalId &&
      folderName &&
      folderDescription &&
      documents.length
    ) {
      this.setState({ loading: true });
      const response = await createSiteSpecificLearnFolder(
        {
          locationId: selectedHospitalId,
          folderName,
          folderDescription,
          documents,
        },
        this.setLoaderPercentage
      );

      const newState = { loading: false, percentage: 0 };
      if (response.status) {
        newState.folderName = "";
        newState.folderDescription = "";
        newState.documents = [];
        newState.isModified = false;
        swal("Success", "The folder has been created", "success");
        successCallback && successCallback();
      } else {
        swal("Error", response.message, "error");
      }
      this.setState(newState);
    } else if (!selectedHospitalId) {
      swal("Error", "Please select one of the hospitals", "error");
    } else {
      swal("Error", "Please fill in all the required field", "error");
    }
  };

  onSubmit = async ({ folderName, folderDescription, documents }, callback) => {
    this.setState({ folderName, folderDescription, documents }, () => {
      const { selectedType, selectedSection } = this.state;

      if (selectedType === GLOBAL_TYPE) {
        if (selectedSection === MY_HEALTH) {
          this.createGlobalMyHealthFolder(callback);
        } else if (selectedSection === LEARN) {
          this.createGlobalLearnFolder(callback);
        } else {
          swal("Error", "Please select the type of the folder", "error");
        }
      } else if (selectedType === USER_TYPE) {
        if (selectedSection === LEARN) {
          this.createUserLearnFolder(callback);
        } else {
          swal("Error", "Please select the section of the folder", "error");
        }
      } else if (selectedType === SITE_SPECIFIC_TYPE) {
        if (selectedSection === LEARN) {
          this.createSiteSpecificLearnFolder(callback);
        } else {
          swal("Error", "Please select the section of the folder", "error");
        }
      } else {
        swal("Error", "Something went wrong.", "error");
      }
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane active" id="user-add" role="tabpanel">
                <div className="card">
                  {loading ? (
                    <Loader percentage={this.state.percentage} />
                  ) : null}
                  <div className="dimmer active">
                    <div className={`card-body`}>
                      <form autoComplete="off">
                        <div className="row clearfix">
                          {/* type dropdown */}
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Types</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="selectedType"
                                value={this.state.selectedType}
                                onChange={this.handleTypeChange}
                              >
                                <option disabled value="">
                                  Select Type
                                </option>
                                {this.state.types.map((type, idx) => (
                                  <option key={idx} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          {/* section dropdown */}
                          {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Sections</label>
                              <select
                                disabled={!this.state.selectedType}
                                className="form-control custom-select show-tick"
                                name="selectedSection"
                                value={this.state.selectedSection}
                                onChange={this.handleChange}
                              >
                                <option value="">Select Section</option>
                                {this.state.sections.map((section, idx) => (
                                  <option key={idx} value={section}>
                                    {section}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div> */}

                          {/* hospitals dropdown */}
                          {this.state.selectedType === SITE_SPECIFIC_TYPE ? (
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Hospitals</label>
                                <select
                                  className="form-control custom-select show-tick"
                                  name="selectedHospitalId"
                                  value={this.state.selectedHospitalId}
                                  onChange={this.handleChange}
                                >
                                  <option value="">Select Hospital</option>
                                  {this.state.hospitals.map((hospital, idx) => (
                                    <option key={idx} value={hospital.id}>
                                      {hospital.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {this.state.selectedType === USER_TYPE ? (
                          <div className="row clearfix">
                            {/* user dropdown */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Users</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email address"
                                    disabled={!!this.state.selectedUserId}
                                    onKeyDown={this.handleEmailKeyPress}
                                    onChange={(event) =>
                                      this.setState({
                                        selectedEmailAddress:
                                          event.target.value,
                                      })
                                    }
                                    value={this.state.selectedEmailAddress}
                                  />
                                  <span className="input-group-append">
                                    <button
                                      className={`btn ${
                                        this.state.selectedUserId
                                          ? ""
                                          : "btn-primary"
                                      }`}
                                      type="button"
                                      onClick={
                                        this.state.selectedUserId
                                          ? this.clearSelectedUser
                                          : this.searchEmailAddress
                                      }
                                    >
                                      {this.state.selectedUserId
                                        ? "Clear"
                                        : "Search"}
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* calvary profile dropdown */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Profiles</label>
                                <select
                                  disabled={!this.state.selectedUserId}
                                  className="form-control custom-select show-tick"
                                  name="selectedCalvaryProfileId"
                                  onChange={this.handleProfileChange}
                                >
                                  <option value="">Select Profile</option>
                                  {this.state.calvaryProfiles.map(
                                    (calvaryProfile) => (
                                      <option
                                        key={calvaryProfile.id}
                                        value={calvaryProfile.id}
                                      >
                                        {capitalizeEachWord(
                                          `${calvaryProfile?.firstname} ${calvaryProfile?.surname}`
                                        )}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>

                            {/* admission dropdown */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Admissions</label>
                                <select
                                  disabled={
                                    !this.state.selectedCalvaryProfileId
                                  }
                                  className="form-control custom-select show-tick"
                                  name="selectedAdmissionId"
                                  onChange={this.handleAdmissionChange}
                                >
                                  <option value="">Select Admission</option>
                                  {this.state.admissions.map((admission) => (
                                    <option
                                      key={admission.id}
                                      value={admission.id}
                                    >
                                      {`${admission?.specialty} (${
                                        admission?.siteCode
                                      }, ${moment(
                                        admission?.admitdtm.toDate()
                                      ).format("DD/MM/YYYY LT")})`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <hr />

                        {/* folder section */}
                        <CreateFolder
                          disabled={this.disabledFolderSection()}
                          onSubmit={this.onSubmit}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const getInitialDocument = () => ({
  id: uuidv4(),
  name: "",
  description: "",
  videoTitle: "",
  fileType: "",
  file: "",
  extension: "",
  editorState: EditorState.createEmpty(),
});

class CreateFolder extends Component {
  state = {
    fileTypes: [PDF, VIDEO],
    folderName: "",
    folderDescription: "",
    documents: [getInitialDocument()],
  };

  onEditorStateChange = (idx) => (editorState) => {
    let { documents } = this.state;

    if (documents[idx].fileType === VIDEO) {
      documents[idx].editorState = editorState;
      this.setState({ documents });
    }
  };

  onAddDocumentClick = () => {
    let { documents } = this.state;

    let errorMessage = "";
    // validate if the previous document are all filled up
    documents.forEach(({ name, description, file }) => {
      if (!(name && description && file)) {
        errorMessage =
          "To add new document, please filled in previous documents information first";
      }
    });

    if (errorMessage) {
      swal("Warning", errorMessage, "warning");
      return;
    }

    // process to add new document
    const documentInfo = getInitialDocument();
    this.setState({ documents: [...documents, documentInfo] });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFileTypeChange = (idx) => (event) => {
    let { documents } = this.state;
    documents[idx].fileType = event.target.value;
    documents[idx].file = "";
    this.setState({ documents });
  };

  onDocumentChange = (idx) => (event) => {
    let { documents } = this.state;
    documents[idx][event.target.name] = event.target.value;
    this.setState({ documents });
  };

  onFileChange = (idx) => (file) => {
    let { documents } = this.state;
    const fileName = file.name;
    const extension = fileName
      .substr(fileName.lastIndexOf("\\") + 1)
      .split(".")[1];
    documents[idx].file = file;
    documents[idx].extension = extension;

    this.setState({ documents });
  };

  onFileDelete = (idx) => () => {
    let { documents } = this.state;
    documents[idx].file = "";
    this.setState({ documents });
  };

  handleValidation = () => {
    const { folderName, folderDescription, documents } = this.state;
    let errorMessage = [];
    if (!folderName) {
      errorMessage.push("Folder name cannot be empty");
    }
    if (!folderDescription) {
      errorMessage.push("Folder Description cannot be empty");
    }

    if (documents.length > 0) {
      documents.forEach(({ name, description, file }) => {
        if (!(name && description && file)) {
          errorMessage.push("One of more document fields are empty");
        }
      });
    } else {
      errorMessage.push(
        "The folder cannot be empty. Please create at least one document"
      );
    }

    if (errorMessage.length) {
      swal("Error", errorMessage.join("\n"), "error");
      return false;
    }
    return true;
  };

  onDeletClick = (name, idx) => async () => {
    const willDelete = await swal({
      title: `Are you sure you want to remove ${
        name || `document number ${idx + 1}`
      }?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      let { documents } = this.state;
      let newDocuments = [];
      documents.forEach((doc, docIdx) => {
        if (idx !== docIdx) {
          newDocuments.push(doc);
        }
      });
      this.setState({ documents: newDocuments });
    }
  };

  onCreateClick = () => {
    if (this.handleValidation()) {
      const { folderName, folderDescription, documents } = this.state;
      this.props.onSubmit(
        { folderName, folderDescription, documents },
        this.resetState
      );
    }
  };

  resetState = () => {
    this.setState({
      folderName: "",
      folderDescription: "",
      documents: [getInitialDocument()],
    });
  };

  render() {
    const { disabled } = this.props;
    return (
      <>
        <div className="card-header">
          <h3 className="card-title">Create Folder</h3>
        </div>

        <div className="row clearfix mt-20">
          {/* folder name */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <label className="form-label">Folder Name *</label>
              <input
                disabled={disabled}
                type="text"
                className="form-control"
                name="folderName"
                placeholder="Name here..."
                onChange={this.handleChange}
                value={this.state.folderName}
              />
            </div>
          </div>
          {/* folder description */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form-group">
              <label className="form-label">Folder Description *</label>
              <input
                disabled={disabled}
                type="text"
                className="form-control"
                name="folderDescription"
                placeholder="Descrption here..."
                onChange={this.handleChange}
                value={this.state.folderDescription}
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          <div
            className={`table-responsive ${disabled ? "dimmer-content" : null}`}
          >
            {/* documents inside the folder */}
            <div className="col-sm-12 border-style">
              <div className="card">
                <div className="card-status card-status-left bg-blue"></div>
                {this.state.documents.map((document, idx) => (
                  <div className="card-body mb-10" key={document.id}>
                    <div className="row clearfix">
                      {/* document order number */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <h2 className="card-title">
                            <b>{`Document number ${idx + 1}`}</b>
                          </h2>
                        </div>
                      </div>

                      {/* delete button */}
                      {idx > 0 || this.state.documents.length > 1 ? (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group float-right">
                            <button
                              type="button"
                              className="btn btn-danger text-white"
                              onClick={this.onDeletClick(
                                document?.file?.name,
                                idx
                              )}
                            >
                              <i className="fe fe-trash mr-2"></i>
                              DELETE
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="row clearfix">
                      {/* document name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">Document Name *</label>
                          <input
                            disabled={disabled}
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={this.onDocumentChange(idx)}
                            placeholder="Document name here..."
                            value={document.name}
                          />
                        </div>
                      </div>
                      {/* document upload */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Document Description *
                          </label>
                          <input
                            disabled={disabled}
                            type="text"
                            className="form-control"
                            name="description"
                            placeholder="Document description here..."
                            onChange={this.onDocumentChange(idx)}
                            value={document.description}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row clearfix mt-20">
                      {/* document file */}
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-20 mt-30">
                        <FileUpload
                          header={
                            <div className="row clearfix">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label">
                                  Document File *
                                </label>
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <select
                                    disabled={disabled}
                                    className="form-control custom-select show-tick"
                                    name="selectedFileType"
                                    onChange={this.handleFileTypeChange(idx)}
                                    value={document.fileType}
                                  >
                                    <option disabled value="">
                                      Select Document Type
                                    </option>
                                    {this.state.fileTypes.map((type) => (
                                      <option key={type} value={type}>
                                        {capitalizeEachWord(type)}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          }
                          disabled={
                            disabled || !this.state.documents[idx]?.fileType
                          }
                          file={this.state.documents[idx]?.file}
                          fileType={this.state.documents[idx]?.fileType}
                          acceptTypes={
                            this.state.documents[idx]?.fileType === PDF
                              ? ".pdf"
                              : ".mp4,.mov"
                          }
                          onChange={this.onFileChange(idx)}
                          onDelete={this.onFileDelete(idx)}
                        />
                      </div>

                      {/* text editor */}
                      {this.state.documents[idx]?.fileType === VIDEO ? (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Video Title</label>
                            <input
                              disabled={disabled}
                              type="text"
                              className="form-control"
                              name="videoTitle"
                              onChange={this.onDocumentChange(idx)}
                              placeholder="Video title here..."
                              value={document.videoTitle}
                            />
                          </div>

                          <div className="form-group border">
                            <Editor
                              editorStyle={EDITOR_STYLE}
                              handlePastedText={() => false}
                              localization={{
                                locale: "en",
                                translations: EDITOR_LABELS,
                              }}
                              editorState={
                                this.state.documents[idx]?.editorState
                              }
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={this.onEditorStateChange(
                                idx
                              )}
                              toolbar={{
                                options: EDITOR_TOOLBAR_OPTIONS,
                                inline: EDITOR_INLINE,
                                blockType: EDITOR_BLOCK_TYPE,
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {this.state.documents.length < 5 ? (
          <button
            disabled={disabled}
            type="button"
            className="btn btn-primary"
            onClick={this.onAddDocumentClick}
          >
            <i className="fe fe-plus mr-2"></i>
            Add Document
          </button>
        ) : null}

        <div className="text-right">
          <button
            disabled={disabled}
            type="button"
            className="btn btn-primary"
            onClick={this.onCreateClick}
          >
            <i className="fe fe-plus mr-2"></i>
            Create
          </button>
        </div>
      </>
    );
  }
}

export default CreateNew;

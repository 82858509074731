import config from "./config";

import { getFirestoreMultipleDocsByPagination } from "../../../assets/js/firebase";
import { ERROR_GETTING_DOCUMENT } from "../../../utils/constants";
import globalConfig from "../../../config/index.json";

// Axios  Helpers

export const getAllLogs = async ({
  searchField,
  searchValue,
  nextDoc = null,
} = {}) => {
  try {
    let condition = [];
    let operator = "==";
    let orderBy = ["createdAt desc"];

    if (searchField && searchValue) {
      if (searchField === "code") {
        searchValue = parseInt(searchValue);
      } else if (searchField === "notEqualCode") {
        searchField = "code";
        operator = "!=";
        searchValue = parseInt(searchValue);
        orderBy.unshift("code asc");
      }
      condition = [...condition, [searchField, operator, searchValue]];
    }

    const getAllLogsRes = await getFirestoreMultipleDocsByPagination(
      config.collection,
      condition,
      {
        nextDoc,
        orderBy,
        limit: globalConfig.paginationLimit,
      }
    );

    if (getAllLogsRes === ERROR_GETTING_DOCUMENT) {
      return {
        status: false,
        data: null,
        message: "Error while getting logs.",
      };
    }

    return { status: true, data: getAllLogsRes };
  } catch (error) {
    console.log("getAllLogs ~ error", error);
    return { status: false, data: null, message: "Something went wrong" };
  }
};

// End of Axios Helpers

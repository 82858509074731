import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  CLINICAL,
  SUPER_ADMIN,
  USER_ROLES,
  USER_TYPE,
} from "../../../utils/constants";
import { roleFormatter, capitalizeEachWord } from "../../../utils/helpers";

export default class UserListItem extends Component {
  render() {
    const { doc, ...user } = this.props.userInfo;
    const { email, firstName, lastName, phone, status } = user; //destructuring

    // formart the role character
    const role = USER_ROLES[roleFormatter(user.role)];
    const currentUserRole =
      USER_ROLES[roleFormatter(this.props.currentUserRole)];

    const showUserDocumentAction =
      this.props.currentUserRole === SUPER_ADMIN ||
      this.props.currentUserRole === CLINICAL;

    let statusValue = "";
    if (status) {
      statusValue = "ACTIVE";
    } else {
      statusValue = "DISABLED";
    }

    return (
      <tr>
        <td className="width45">
          <span
            className="avatar avatar-blue"
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Avatar Name"
          >
            {firstName?.charAt(0)?.toUpperCase()}
            {lastName?.charAt(0)?.toUpperCase()}
          </span>
        </td>
        <td>
          <h6 className="mb-0">
            {capitalizeEachWord(firstName)} {capitalizeEachWord(lastName)}
          </h6>
          <span>{email}</span>
        </td>
        <td>
          <span>{statusValue}</span>
        </td>
        <td>
          <span className="tag tag-primary">{role}</span>
        </td>
        {/* <td>24 Jun, 2015</td> */}
        <td>
          <span>{phone}</span>
        </td>
        <td>
          {/* user document action */}
          {showUserDocumentAction ? (
            <div className="dropdown mr-4">
              <i
                className="fa fa-file-o cursor-pointer"
                data-toggle="dropdown"
                title="User document"
              />

              <div className="dropdown-menu dropdown-menu-right">
                {/* edit button */}
                <Link
                  className="dropdown-item cursor-pointer"
                  to={{
                    pathname: "/documents-create-new",
                    state: { type: USER_TYPE, user },
                  }}
                >
                  <i className="dropdown-icon icon-plus" /> Create user document
                </Link>

                {/* duplicate button */}
                <Link
                  className="dropdown-item cursor-pointer"
                  to={{
                    pathname: "/documents-education",
                    state: { type: USER_TYPE, user },
                  }}
                >
                  <i className="dropdown-icon icon-drawer" /> Manage user
                  document
                </Link>
              </div>
            </div>
          ) : null}

          <Link
            //to="/users-update?name=netflix"
            to={{
              pathname: "/users-update",
              state: {
                user: user,
                userId: user.id,
                search: false,
                currentUserRole: currentUserRole,
              },
            }}
            className="btn btn-icon"
          >
            <i className="fa fa-edit" />
          </Link>
          <button
            type="button"
            onClick={() => this.props.onPressDelete(this.props.userId)}
            className="btn btn-icon js-sweetalert"
            title="Delete"
            data-type="confirm"
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </td>
      </tr>
    );
  }
}

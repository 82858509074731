import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Loader = ({ percentage = 100 }) => {
  return (
    <div className="loader-container">
      <div className="loader-background">
        <CircularProgressbar value={percentage} text={`${percentage}%`} />
      </div>
    </div>
  );
};

export default Loader;

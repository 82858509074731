import firebase from "firebase/app";
import moment from "moment";
import { getCurrentUserInfo } from "../assets/js/firebase";

import { USER_INFO, USER, USER_ROLES } from "./constants";

// make the role to all lowercase, trim them and remove the space between
export const roleFormatter = (role = "") => {
  return role.toLowerCase().trim().replace(/ +/g, "");
};

// capitalize the string
export const capitalize = (word = "") => {
  if (word) {
    word = word.trim();
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return word;
};

// capitalize each word of the string
export const capitalizeEachWord = (word = "") => {
  return word?.trim().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
};

// get user role from firebase custom claims
export const getUserRoleClaim = async () => {
  try {
    const { claims } = await firebase.auth().currentUser.getIdTokenResult();
    const roles = Object.keys(USER_ROLES);

    // determine user claims
    for (let roleIdx = 0; roleIdx < roles.length; roleIdx++) {
      if (claims[roles[roleIdx]]) {
        // role found
        return roles[roleIdx];
      }
    }
    return USER; // return the lowest role for security purpose when role not found
  } catch (error) {
    console.log("getUserRoleClaim ~ error", error);
    // return the lowest role for security purpose
    return USER;
  }
};

// handle userInfo in session (for using in component only)
export const userInfoHelper = async (window) => {
  let userInfo;
  if (window) {
    // get userInfo from session
    userInfo = window.sessionStorage.getItem(USER_INFO);

    if (userInfo) {
      return JSON.parse(userInfo);
    } else {
      // get userInfo from firebase
      userInfo = await getCurrentUserInfo();
      const userRole = await getUserRoleClaim();
      if (userInfo) {
        // set session for userInfo
        userInfo.role = roleFormatter(userRole);
        window.sessionStorage.setItem(USER_INFO, JSON.stringify(userInfo));
        return userInfo;
      }
    }
  }
  return null;
};

// validate email address and return boolean
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// hide first 7 digital of mobile number
export const hidePhoneDigits = (phone = "") => {
  if (phone) {
    return phoneNumberHelper(phone).replace(/\d{7}(\d{4})/, "*******$1");
  }
  return phone;
};

export const validateName = (val = "") => {
  if (typeof val !== "string") {
    return false;
  }
  const re = /[<>&"]/g;
  const slashRe = /\W*(\/>)\W*/g;
  return !re.test(val.trim()) && !slashRe.test(val.trim());
};

// validate mobile phone and return boolean
export const validateMobile = (val) => {
  // let regMobileNo = /^04[0-9]{8}/;
  let regMobileNo = /^4[0-9]{8}/;
  if (!regMobileNo.test(val)) {
    return false;
  }
};

// handle phone number country code
export const phoneNumberHelper = (phoneNumber) => {
  if (phoneNumber.startsWith("0")) {
    // replace the first 0 with +61
    return "+61" + phoneNumber.substring(1);
  } else {
    // phone number doesn't need to manipulate
    return phoneNumber;
  }
};

// convert string, date type and firestore timestamp to moment instance
export const convertToMomentInstance = (date) => {
  try {
    let momentDate = date;

    if (typeof date === "string") {
      momentDate = moment(new Date(date));
    } else if (date instanceof Date) {
      momentDate = moment(date);
    } else if (date instanceof firebase.firestore.Timestamp) {
      momentDate = moment(date.toDate());
    } else {
      momentDate = moment(date);
    }

    return momentDate;
  } catch (error) {
    console.log("convertToMomentInstance ~ error", error);
    return date;
  }
};

// function to format string to be trimed and to lower case letter
export const lowerCaseFormatter = (string = "") => {
  try {
    if (typeof string === "string") {
      return string.trim().toLowerCase();
    } else {
      return string || "";
    }
  } catch (error) {
    console.log("lowerCaseFormatter ~ error", error);
    return string || "";
  }
};

// compare two string by ignoring case by default
export const isSameString = (str1 = "", str2 = "", caseSensitive = false) => {
  try {
    if (!str1 && !str2) {
      return false;
    }

    if (caseSensitive) {
      return str1.trim() === str2.trim();
    } else {
      return lowerCaseFormatter(str1) === lowerCaseFormatter(str2);
    }
  } catch (error) {
    console.log("isSameString ~ error", error);
    return false;
  }
};

export const getFileName = (fullPath = "") => {
  return fullPath.replace(/^.*[\\\/]/, "");
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import firebase from "firebase/app";

import AuthRightComponent from "./AuthRightComponent";
import { validateEmail } from "../../utils/helpers";
import { passwordReset } from "../../api/user";

import CalvaryIcon from "../../assets/images/calvary-icon.svg";
import Routes from "../../routes";

export default class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
      loading: false,
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if user is login, redirect to the home dashboard
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this.props.history.push(Routes.dashboard.index.path);
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true }, () => {
      let email = this.state.email;
      if (!validateEmail(email)) {
        swal({
          text: "Email should be valid email address",
          icon: "error",
          button: "Ok",
        });
        this.setState({ loading: false });
      } else {
        passwordReset(this.state.email)
          .then(() => {
            this.setState({ loading: false });
            swal(
              "Success",
              "A password reset email has been sent to your registered email.",
              "success"
            );
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  validateForm() {
    return this.state.email.length > 0;
  }

  render() {
    return (
      <div className="auth">
        <div className="auth_left">
          <div className="card">
            <div className="text-center mb-5">
              <Link className="header-brand" to="/">
                <img
                  className="fe fe-command brand-logo"
                  src={CalvaryIcon}
                  width="50"
                  alt="Calvary Health Care"
                />
              </Link>
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="card-title">Forgot password</div>
                <p className="text-muted">
                  Enter your email address and a password reset link will be
                  emailed to you.
                </p>
                <div className="form-group">
                  <label className="form-label" htmlFor="exampleInputEmail1">
                    Email address *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-footer">
                  {/* <Link className="btn btn-primary btn-block" to="/login">
										Send me new password
									</Link> */}
                  <input
                    className="btn btn-primary btn-block"
                    id="sign-up-button"
                    type="submit"
                    value="RESET PASSWORD"
                    disabled={!this.validateForm() || this.state.loading}
                  />
                </div>
              </form>
            </div>
            <div className="text-center text-muted">
              Cancel, return to <Link to="/login">login screen.</Link>
            </div>
          </div>
        </div>
        <AuthRightComponent />
      </div>
    );
  }
}

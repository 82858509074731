import config from "./config";

import {
  createFirestoreDoc,
  getFirestoreDoc,
  getFirestoreMultipleDocs,
  searchFirestoreMultiDocs,
} from "../../../assets/js/firebase";

import {
  NO_MATCHING_DOCUMENTS,
  ERROR_GETTING_DOCUMENT,
} from "../../../utils/constants";

import globalConfig from "../../../config/index.json";

// Axios  Helpers

export const getInitialData = async () => {
  let data = {
    hospitals: [],
    admissions: [],
  };

  try {
    const getHospitalRes = await getFirestoreMultipleDocs(
      config.hospitalCollection
    );
    if (
      getHospitalRes === ERROR_GETTING_DOCUMENT ||
      getHospitalRes === NO_MATCHING_DOCUMENTS
    ) {
      // something went wrong or no hospital found
      return {
        status: getHospitalRes === NO_MATCHING_DOCUMENTS,
        data,
        message: getHospitalRes,
      };
    }

    data.hospitals = getHospitalRes;
    const hospitalId = getHospitalRes[1].id; // for admission query base on hospital id
    const hospitalName = getHospitalRes[1].name; // To add to admission data
    data.selectedHospital = hospitalId;

    const getAdmissionRes = await getFirestoreMultipleDocs(
      config.admissionCollection,
      [["locationId", "==", hospitalId]],
      "createdAt",
      null,
      globalConfig.paginationLimit + 1
    );

    if (
      getAdmissionRes === ERROR_GETTING_DOCUMENT ||
      getAdmissionRes === NO_MATCHING_DOCUMENTS
    ) {
      return {
        status: getAdmissionRes === NO_MATCHING_DOCUMENTS,
        data,
        message: getAdmissionRes,
      };
    }

    data.admissions = getAdmissionRes;

    // query userinfo and add each username to admissions array
    await Promise.all(
      getAdmissionRes.map(async (admission, idx) => {
        const { userId } = admission;
        if (userId) {
          const getUserRes = await getFirestoreDoc(
            config.userCollection,
            userId
          );
          if (
            getUserRes === ERROR_GETTING_DOCUMENT ||
            getUserRes === NO_MATCHING_DOCUMENTS
          ) {
            return getUserRes;
          }
          const { firstName, lastName, goShareId } = getUserRes;
          data.admissions[idx].username = `${firstName} ${lastName}`;
          data.admissions[idx].locationName = hospitalName;
          data.admissions[idx].goShareId = goShareId;
        }
        return NO_MATCHING_DOCUMENTS;
      })
    );

    return { status: true, data };
  } catch (error) {
    console.log(" getInitialAdmissionsData ~ error", error);
    return { status: false, data, message: "Something went wrong" };
  }
};

export const getAllAdmissions = async (hospitalId, hospitalName, startAt) => {
  let admissions = [];
  try {
    const getAdmissionRes = await getFirestoreMultipleDocs(
      config.admissionCollection,
      [["locationId", "==", hospitalId]],
      "createdAt",
      startAt,
      globalConfig.paginationLimit + 1
    );

    if (
      getAdmissionRes === ERROR_GETTING_DOCUMENT ||
      getAdmissionRes === NO_MATCHING_DOCUMENTS
    ) {
      return {
        status: getAdmissionRes === NO_MATCHING_DOCUMENTS,
        data: admissions,
        message: getAdmissionRes,
      };
    }

    admissions = getAdmissionRes;

    await Promise.all(
      getAdmissionRes.map(async (admission, idx) => {
        const { userId } = admission;
        if (userId) {
          const getUserRes = await getFirestoreDoc(
            config.userCollection,
            userId
          );
          if (
            getUserRes === ERROR_GETTING_DOCUMENT ||
            getUserRes === NO_MATCHING_DOCUMENTS
          ) {
            return getUserRes;
          }

          const { firstName, lastName, goShareId } = getUserRes;
          admissions[idx].username = `${firstName} ${lastName}`;
          admissions[idx].locationName = hospitalName;
          admissions[idx].goShareId = goShareId;
        }
        return;
      })
    );

    return { status: true, data: admissions };
  } catch (error) {
    console.log("getAllAdmissions ~ error", error);
    return {
      status: false,
      data: admissions,
      message: "Something went wrong.",
    };
  }
};

export const getEducation = async function getEducation(admissionId, callback) {
  let education = {};
  getFirestoreMultipleDocs(config.educationCollection, [
    "admissionId",
    "==",
    admissionId,
  ])
    .then((educationResult) => {
      // check if the result is a proper array and contain data
      if (Array.isArray(educationResult) && educationResult.length > 0) {
        const educationData = educationResult[0] || {};
        education = {
          id: educationData.id,
          ...educationData.document,
        };
        callback(true, education);
      } else if (educationResult === "No matching documents.") {
        callback(true, null);
      }
    })
    .catch(function (error) {
      callback(false, education, error);
    });
};

export const getAllItems = async function getAllItems() {
  return getFirestoreMultipleDocs(config.collection)
    .then((results) => {
      return results;
    })
    .catch(function (error) {
      return error;
    });
};

export const searchItems = async function searchItems(key, value) {
  return searchFirestoreMultiDocs(config.collection, key, value)
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      return error;
    });
};

export const searchByAdmissionID = async function (key, value) {
  return searchFirestoreMultiDocs(config.collection, key, value)
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      return error;
    });
};

export const createItem = async function createItem(dataObj) {
  return createFirestoreDoc(config.educationCollection, dataObj)
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      return error;
    });
};

export const updateItem = async function updateItem(itemId, dataObj) {
  return false;
};

// End of Axios Helpers

// Object Helpers
export const getCreateReqObj = function createObject(values) {
  let keys = config.tableFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

export const getUpdateReqObj = function updateObject(values) {
  const keys = config.tableFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

//object data handling method for item list component, results object values should modify acording to source object
// Do not change results key values, if do it need to modify on item list component /.ItemListItem
export const getItemListObj = (admissionObj) => {
  let result = {
    id: admissionObj.id,
    avatarName: admissionObj.username[0],
    username: admissionObj.username,
    admissionName: admissionObj.specialty,
    date: admissionObj.createdAt.toDate(),
    status: admissionObj.pat_status_label || "Awaiting Admission",
    sourceObj: admissionObj,
  };
  return result;
};

// End of Object Helpers

import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import config from "./config";

export default class ItemListItem extends Component {
  render() {
    const { id, avatarName, username, admissionName, date, status } =
      this.props.itemData;

    return (
      <>
        <tr>
          {/* Patient name */}
          <td className="width45">
            <span
              className="avatar avatar-blue"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {avatarName}
            </span>
          </td>
          <td>
            <h6 className="mb-0">{username}</h6>
            {/* <span>{subTitle}</span> */}
          </td>

          {/* admission id */}
          <td>
            <span>{id}</span>
          </td>

          {/* admission name */}
          <td>
            <span>{admissionName}</span>
          </td>

          {/* admission date */}
          <td>
            <span>{moment(date).format("L LT")}</span>
          </td>

          {/* status */}
          <td>
            <span>{status}</span>
          </td>

          {/* action button */}
          <td>
            <Link
              to={{
                pathname: config.updatePath,
                state: {
                  // item: this.props.itemData.sourceObj,
                  id: id,
                  search: false,
                },
              }}
              className="btn btn-icon"
            >
              <i className="fa fa-edit" />
            </Link>
            <button
              type="button"
              onClick={() => this.props.onPressDelete(id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
              data-type="confirm"
            >
              <i className="fa fa-trash-o text-danger" />
            </button>
          </td>
        </tr>
      </>
    );
  }
}

import config from "./config";

import { updateLocation } from "../../../api/location";
import {
  createFirestoreDoc,
  getFirestoreMultipleDocs,
  searchFirestoreMultiDocs,
  deleteFirestoreDoc,
  updateFirestoreDoc,
} from "../../../assets/js/firebase";
import {
  ERROR_ADDING_DOCUMENT,
  ERROR_DELETING_DOCUMENT,
  ERROR_GETTING_DOCUMENT,
  ERROR_UPDATING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
} from "../../../utils/constants";
import globalConfig from "../../../config/index.json";

// Axios  Helpers

export const deleteHospital = async (hospitalId) => {
  try {
    const deleteHospitalRes = await deleteFirestoreDoc(
      config.collection,
      hospitalId
    );
    if (deleteHospitalRes === ERROR_DELETING_DOCUMENT) {
      return { status: false, message: ERROR_DELETING_DOCUMENT };
    }
    return { status: true };
  } catch (error) {
    console.log("deleteHospital ~ error", error);
    return { status: false, message: "Something went wrong" };
  }
};

export const getAllHospital = async ({
  searchField,
  searchValue,
  startAt = null,
} = {}) => {
  const hospitals = [];
  try {
    let condition = [];
    if (searchField && searchValue) {
      condition = [...condition, [searchField, "==", searchValue]];
    }

    const getHospitalRes = await getFirestoreMultipleDocs(
      config.collection,
      condition,
      "name",
      startAt,
      globalConfig.paginationLimit + 1
    );

    if (
      getHospitalRes === ERROR_GETTING_DOCUMENT ||
      getHospitalRes === NO_MATCHING_DOCUMENTS
    ) {
      return {
        status: getHospitalRes === NO_MATCHING_DOCUMENTS,
        data: hospitals,
        message: ERROR_GETTING_DOCUMENT,
      };
    }

    return { status: true, data: getHospitalRes };
  } catch (error) {
    console.log("getAllHospital ~ error", error);
    return { status: false, data: hospitals, message: "Something went wrong" };
  }
};

export const searchItems = async function searchItems(key, value) {
  return searchFirestoreMultiDocs(config.collection, key, value)
    .then((data) => {
      return data;
    })
    .catch(function (error) {
      return error;
    });
};

export const createItem = async function createItem(dataObj) {
  return createFirestoreDoc(config.collection, dataObj)
    .then((data) => {
      if (data === ERROR_ADDING_DOCUMENT) {
        return { status: false, message: ERROR_ADDING_DOCUMENT };
      }
      return { status: true };
    })
    .catch(function (error) {
      return { status: false, message: error.message };
    });
};

export const updateItem = async function updateItem(itemId, dataObj) {
  const updateLocationRes = await updateLocation({
    locationId: itemId,
    ...dataObj,
  });

  if (updateLocationRes.message === "OK") {
    return { status: true };
  } else {
    return { status: false, message: updateLocationRes.message };
  }
};

// End of Axios Helpers

// Object Helpers
export const getCreateReqObj = function createObject(values) {
  let keys = config.tableFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

export const getUpdateReqObj = function updateObject(values) {
  const keys = config.tableFieldList;
  let result = {};
  keys.forEach((key, i) => (result[key] = values[i]));
  return result;
};

//object data handling method for item list component, results object values should modify acording to source object
// Do not change results key values, if do it need to modify on item list component /.ItemListItem
export const getItemListObj = function getItemListObj(sourceObj) {
  let result = {
    id: sourceObj.id,
    avatarName: sourceObj.name[0],
    title: sourceObj.name,
    subTitle: sourceObj.address,
    col1Data: sourceObj.status,
    col2Data: sourceObj.state,
    col3Data: sourceObj.phone,
    sourceObj: sourceObj,
  };
  return result;
};

// End of Object Helpers

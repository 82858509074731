let controller = "/logs";
let collection = "logs";
let rootRouteName = "logs";
let navigationRoot = "/logs";
let rootPageTitle = "Logs";

module.exports = {
  controller: controller,
  navigationRoot: navigationRoot,
  collection: collection,

  searchFilter: [
    { value: "notEqualCode", title: "Code !=" },
    { value: "code", title: "Code" },
    { value: "path", title: "Path" },
    { value: "userId", title: "User ID" },
  ],

  // EndPoints
  deleteEP: controller + "/delete",
  searchEP: controller + "/search",
  getAllEP: controller + "/",

  // Routing values
  rootPageTitle: rootPageTitle,
  rootRouteName: rootRouteName,
};

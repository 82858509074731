import React, { Component } from "react";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";

import config from "./config";

import { getCreateNotificationReqObj, getCreateEmailReqObj } from "./utils";

import {
  sendGeneralNotification,
  sendGeneralEmail,
} from "../../../api/notification";

class AddItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isModified: false,

      type: config.messageType[0],

      // notification
      title: "",
      body: "",

      // email
      subject: "",
      emailBody: "",

      fields: {},
      errors: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleSave = () => {
    if (this.handleValidation()) {
      this.submitItemInfo();
    } else {
      let text = this.state.errors;
      let errorString = "";
      text.reduce((result, item) => {
        errorString = errorString + "\n" + item;
        return `${item}|`;
      }, "");
      swal("Error", errorString, "error");
    }
  };

  handleChange = (event) => {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
  };

  submitNotification = async () => {
    const dataObj = getCreateNotificationReqObj([
      this.state.title,
      this.state.body,
    ]);

    const response = await sendGeneralNotification(dataObj);
    if (response.message === "OK") {
      this.setState({
        loading: false,
        isModified: false,
        title: "",
        body: "",
      });
      swal("success", "Notification was sent sucessfully", "success");
    } else {
      this.setState({ loading: false });
      swal("Error", response.message, "error");
    }
  };

  submitEmail = async () => {
    const dataObj = getCreateEmailReqObj([this.state.title, this.state.body]);

    const response = await sendGeneralEmail(dataObj);
    if (response.message === "OK") {
      this.setState({
        loading: false,
        isModified: false,
        title: "",
        body: "",
      });
      swal("success", "Email was sent sucessfully", "success");
    } else {
      this.setState({ loading: false });
      swal("Error", response.message, "error");
    }
  };

  submitItemInfo = () => {
    this.setState({ loading: true });
    if (this.state.type === "Notification") {
      this.submitNotification();
    } else {
      this.submitEmail();
    }
  };

  handleValidation = () => {
    const { type, title, body } = this.state;
    let errors = [];
    let formIsValid = true;

    if (!title && !body) {
      formIsValid = false;
      errors.push(
        `Please fill in ${
          type === "Notification" ? "Title" : "Subject"
        } and/or body.`
      );
    }

    this.state.errors = errors;
    return formIsValid;
  };

  render() {
    const { loading } = this.state;
    const isNotificationType = this.state.type === "Notification";
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="dimmer active">
                        {loading ? <div className="loader" /> : null}

                        {/* message type */}
                        <div
                          className={`row clearfix ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Type</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="type"
                                value={this.state.type}
                                onChange={this.handleChange}
                              >
                                {config.messageType.map((type, key) => (
                                  <option key={key}>{type}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr />

                        {/* subject */}
                        <div
                          className={`row clearfix ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                {isNotificationType ? "Title" : "Subject"}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`${
                                  isNotificationType ? "Title" : "Subject"
                                }`}
                                name="title"
                                value={this.state.title}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        {/* body */}
                        <div
                          className={`row clearfix ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Body</label>
                              <textarea
                                className="form-control"
                                rows={5}
                                placeholder="Body"
                                name="body"
                                value={this.state.body}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className={`row clearfix ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.handleSave()}
                            >
                              Send to All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddItems;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  sendEmailVerfication,
  createFirestoreUserDoc,
} from "../../assets/js/firebase";
import { validateEmail, validateMobile } from "../../utils/helpers";
import firebase from "firebase/app";
import swal from "sweetalert";

import AuthRightComponent from "./AuthRightComponent";
import Routes from "../../routes";

const { fba_screen_view } = require("../../assets/js/firebase");

export default class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
      role: "admin",
      siginInProgress: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    };
    fba_screen_view("sign up");

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if user is login, redirect to the home dashboard
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this.props.history.push(Routes.dashboard.index.path);
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    // handle submit button click
    event.preventDefault();
    let phone = this.state.phone;
    let email = this.state.email;
    if (!validateEmail(email)) {
      //invalid email address
      swal({
        text: "Email should be valid email address",
        icon: "warning",
        button: "Ok",
      });
    } else if (false === validateMobile(phone)) {
      //invalid phone number
      swal({
        text: "Phone Number should be valid Australian Mobile Number",
        icon: "warning",
        button: "Ok",
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      //password and confirm password do not match
      swal({
        text: "Password and Confirm Password should be same",
        icon: "warning",
        button: "Ok",
      });
    } else {
      // validations are succes -> procedd to firebase function
      this.firebaseAction();
    }
  }

  firebaseAction() {
    //firebase signup function
    let email = this.state.email;
    let password = this.state.password;

    this.setState({ siginInProgress: true });

    // create firebase user using email & passwword
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        console.log("userCredential: ", userCredential.uid);
        // Signed in
        var user = userCredential.user;
        // update phone number
        user
          .updateProfile({
            phoneNumber: this.state.phone,
          })
          .then(function () {
            // Update successful.
          })
          .catch(function (error) {
            // An error happened.
          });
        // user created succcessfully, proceed to send email verification
        sendEmailVerfication();
        // get user token

        const data = {
          email: this.state.email,
          id: user.uid,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phone: this.state.phone,
          role: this.state.role,
          // status: this.state.status,
          status: true,
        };
        createFirestoreUserDoc(data, user.uid);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if ("auth/email-already-in-use" === errorCode) {
          // email is already used on the firebase account
          swal(
            "Error",
            "The email address is already in use by another account.",
            "error"
          );
        } else if (errorMessage) {
          swal("Error", errorMessage, "error");
        } else {
          swal("Error", "Something went wrong. Please try again!", "error");
        }
        this.setState({ siginInProgress: false });
      });
  }

  clearfields() {}

  validateForm() {
    //basic form validation
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0
    );
  }

  render() {
    return (
      <div className="auth">
        <div className="auth_left">
          <div className="card">
            <div className="text-center mb-5">
              <Link className="header-brand" to="/">
                <i className="fe fe-command brand-logo" />
              </Link>
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="card-title">Create new account</div>
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter mobile"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                  />
                </div>
                {/* <div className="form-group">
									<label className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" />
										<span className="custom-control-label">
											Agree the <a href="/#">terms and policy</a>
										</span>
									</label>
								</div> */}
                <div className="form-footer">
                  {/* <Link className="btn btn-primary btn-block" to="/login">
										Create account
									</Link> */}
                  <input
                    className="btn btn-primary btn-block"
                    id="sign-up-button"
                    type="submit"
                    value="Create account"
                    disabled={
                      !this.validateForm() || this.state.siginInProgress
                    }
                  />
                </div>
              </form>
            </div>
            <div className="text-center text-muted">
              Already have account? <Link to="/login">Sign In</Link>
            </div>
          </div>
        </div>
        <AuthRightComponent />
      </div>
    );
  }
}

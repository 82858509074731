// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from "firebase";
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import "firebase/storage";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

import swal from "sweetalert";
import config from "../../config/index.json";

import { phoneNumberHelper } from "../../utils/helpers";
import {
  NO_MATCHING_DOCUMENTS,
  ERROR_GETTING_DOCUMENT,
  ERROR_DELETING_DOCUMENT,
  ERROR_UPDATING_DOCUMENT,
  ERROR_ADDING_DOCUMENT,
  ERROR_SETTING_DOCUMENT,
} from "../../utils/constants";

import { sendVerifyEmailAP } from "../../api/user";

export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";
export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";
export const SIGNIN_ERROR = "ERROR";
export const MULTI_FACTOR_REQUIRED = "auth/multi-factor-auth-required";
export const VERIFICATION_CODE_REQUIRED = "VERIFICATION_CODE_REQUIRED";
export const INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER";
export const SMS_FAIL = "SMS_FAIL";

// TODO: Replace the config info with secure mechanism
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
  databaseURL: config.databaseUrl,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

firebase.firestore.setLogLevel("debug");
firebase.firestore.setLogLevel("error");

firebase.firestore().settings({ experimentalForceLongPolling: true });

export { firebase, firebaseConfig };

export const fba_screen_view = (screen) => {
  analytics.setCurrentScreen(screen);
  analytics.logEvent("screen_view");
};

export const fba_action_event = (type, name) => {
  analytics.logEvent("action", {
    content_type: type,
    event_name: name,
  });
};

export const deleteUser = () => {
  var user = firebase.auth().currentUser;
  user
    .delete()
    .then(function () {
      // User deleted.
      swal("Error", "Something went wrong. Please try again!", "error");
    })
    .catch(function (error) {
      // An error happened.
      swal("Error", "Something went wrong. Please try again!", "error");
    });
};

export const userSignOut = () => {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

export const sendEmailVerfication = async (notify = false) => {
  // var actionCodeSettings = {
  //   // URL you want to redirect back to. The domain (www.example.com) for this
  //   // URL must be in the authorized domains list in the Firebase Console.
  //   url: config.baseURL,
  //   // This must be true.
  //   handleCodeInApp: true,
  // };
  try {
    var user = firebase.auth().currentUser;

    const sendEmailRes = await sendVerifyEmailAP(user.email);

    if (sendEmailRes.message !== "OK") {
      return { status: false, message: sendEmailRes.message };
    }

    // Email sent.
    if (notify) {
      await swal(
        "Email Sent",
        "Please verify your email to activate the account",
        "info"
      );
    }

    return { status: true };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const addMultifactor = async function addMultifactor(
  recaptchaVerifier,
  phoneNumber
) {
  try {
    var user = firebase.auth().currentUser;
    var mfaDisplayName = "personal";
    // add country code to phone number if there's none
    var formattedPhoneNumber = phoneNumberHelper(phoneNumber);

    const multiFactorSession = await user.multiFactor.getSession();
    // Specify the phone number and pass the MFA session.
    var phoneInfoOptions = {
      phoneNumber: formattedPhoneNumber,
      session: multiFactorSession,
    };
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    // Send SMS verification code.
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );

    let verificationCode;
    let numTries = 0;
    while (numTries < 3) {
      // Ask user for the verification code.
      verificationCode = await swal({
        text: "Please enter your one time code below.",
        content: "input",
        buttons: ["Cancel", { text: "Continue", closeModal: false }],
        closeOnClickOutside: false,
      });

      if (verificationCode === "") {
        await swal("Error", "The one time code cannot be empty.", "error");
        continue;
      } else if (verificationCode === null) {
        // if cancel
        return { status: false, showAlert: false };
      }

      var cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      var multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      try {
        // Complete enrollment.
        await user.multiFactor.enroll(multiFactorAssertion, mfaDisplayName);
        swal.stopLoading();
        swal.close();
        break;
      } catch (error) {
        swal.stopLoading();
        numTries += 1;

        let message = "Something went wrong, try again";
        if (numTries >= 3) {
          swal.close();
          return { status: false, message: "You have reached maximum tries" };
        } else if (error.code === "auth/invalid-verification-code") {
          message = "Invalid verification code. Please check and try again.";
        }
        await swal("Error", message, "error");
      }
    }

    return { status: true };
  } catch (error) {
    console.log("addMultifactor", error);
    return { status: false, message: "Something went wrong", showAlert: true };
  }
};

export const sendVerification = async function sendVerification(
  resolver,
  appVerifier
) {
  // add country code to phone number if there's none
  var phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  };

  var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
  // Send SMS verification code
  return phoneAuthProvider
    .verifyPhoneNumber(phoneInfoOptions, appVerifier)
    .then(function (verificationId) {
      // Ask user for the SMS verification code.
      return swal({
        text: "Please enter your one time code below.",
        content: "input",
        button: "Continue",
        closeOnClickOutside: false,
      }).then((verificationCode) => {
        var cred = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        var multiFactorAssertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        return resolver.resolveSignIn(multiFactorAssertion);
      });
    })
    .then(function (userCredential) {
      // User successfully signed in with the second factor phone number.
      return { status: SIGN_IN_SUCCESS, data: userCredential };
    })
    .catch((error) => {
      // Error; SMS not sent
      if ("auth/invalid-phone-number" === error.code) {
        return { status: INVALID_PHONE_NUMBER };
      } else {
        return { status: SMS_FAIL };
      }
    });
};

export const enterVerification = async function enterVerification(
  confirmationResult,
  code
) {
  return confirmationResult
    .confirm(code)
    .then((result) => {
      // User signed in successfully.
      var credential = firebase.auth.PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        code
      );
      firebase.auth().signInWithCredential(credential);
      return { status: SIGN_IN_SUCCESS, data: result.user };
    })
    .catch((error) => {
      return { status: SIGN_IN_FAIL, data: error };
    });
};

export const getUserToken = async function getUserToken() {
  const user = firebase.auth().currentUser;
  if (!user) {
    // user is signed in, show user data
    return { status: false, data: "user not found" };
  }
  return firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      return { status: true, data: idToken };
    })
    .catch(function (error) {
      // Handle error
      return { status: false, data: error };
    });
};

export const getCurrentUserInfo = async function getCurrentUserInfo() {
  const user = firebase.auth().currentUser;
  if (!user) {
    // user is signed in, show user data
    return false;
  }

  var db = firebase.firestore();
  var docRef = db.collection("users").doc(user.uid);
  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        // doc.data() will be undefined in this case
        return false;
      }
    })
    .catch((error) => {
      console.log("getCurrentUserInfo ~ error", error);
      return false;
    });
};

export const getFirestoreDoc = async function getFirestoreDoc(
  collectionName,
  docId
) {
  var db = firebase.firestore();
  var docRef = db.collection(collectionName).doc(docId);
  return docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { id: doc.id, ...doc.data() };
      } else {
        // doc.data() will be undefined in this case
        return NO_MATCHING_DOCUMENTS;
      }
    })
    .catch((error) => {
      console.log("getFirestoreDoc ~ error", error);
      return ERROR_GETTING_DOCUMENT;
    });
};

export const deleteFirestoreDoc = async function deleteFirestoreDoc(
  collectionName,
  docId
) {
  var db = firebase.firestore();
  var docRef = db.collection(collectionName).doc(docId);
  return docRef
    .delete()
    .then((res) => res)
    .catch((error) => {
      console.log("deleteFirestoreDoc ~ error", error);
      return ERROR_DELETING_DOCUMENT;
    });
};

export const updateFirestoreDoc = async function updateFirestoreDoc(
  collectionName,
  docId,
  data
) {
  delete data.createdAt; // remove createdAt field
  var db = firebase.firestore();
  var docRef = db.collection(collectionName).doc(docId);
  return docRef
    .update({ ...data, updatedAt: firebase.firestore.Timestamp.now() })
    .then((res) => res)
    .catch((error) => {
      console.log("updateFirestoreDoc ~ error", error);
      return ERROR_UPDATING_DOCUMENT;
    });
};

export const createFirestoreDoc = async function createFirestoreDoc(
  collectionName,
  dataObj
) {
  var db = firebase.firestore();
  return db
    .collection(collectionName)
    .add({
      ...dataObj,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
    })
    .then((res) => res)
    .catch((error) => {
      console.log("createFirestoreDoc ~ error", error);
      return ERROR_ADDING_DOCUMENT;
    });
};

export const setFirestoreDoc = async function setFirestoreDoc(
  collectionName,
  docId,
  data
) {
  // add updatedAt fields
  const date = firebase.firestore.Timestamp.now();
  data.updatedAt = date;

  // check if doc already contain createdAt field
  const getRes = await getFirestoreDoc(collectionName, docId);

  if (getRes === ERROR_GETTING_DOCUMENT) {
    return ERROR_SETTING_DOCUMENT;
  }

  if (getRes === NO_MATCHING_DOCUMENTS) {
    // add createdAt field
    data.createdAt = date;
  } else {
    if (getRes.createdAt) {
      // remove createdAt field if any
      delete data.createdAt;
    } else {
      // add createdAt field
      data.createdAt = date;
    }
  }

  var db = firebase.firestore();
  var docRef = db.collection(collectionName).doc(docId);
  return docRef
    .set(data, { merge: true })
    .then((res) => res)
    .catch((error) => {
      console.log("createFirestoreDoc ~ error", error);
      return ERROR_SETTING_DOCUMENT;
    });
};

export const createFirestoreUserDoc = async function createFirestoreUserDoc(
  dataObj,
  id
) {
  var db = firebase.firestore();
  return db
    .collection("users")
    .doc(id)
    .set(dataObj)
    .then((docRef) => {})
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getFirestoreMultipleDocs = async function getFirestoreMultipleDocs(
  collectionName,
  condition = [],
  orderBy,
  startAfter,
  limit
) {
  let rows = [];
  var db = firebase.firestore();
  var docRef = db.collection(collectionName);

  if (condition.length) {
    condition.forEach((con) => {
      docRef = docRef.where(con[0], con[1], con[2]);
    });
  }

  // if (orderBy) {
  //   docRef = docRef.orderBy(orderBy);
  // }

  if (startAfter) {
    docRef = docRef.startAfter(startAfter);
  }

  return docRef
    .limit(limit)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        return NO_MATCHING_DOCUMENTS;
      }
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        rows.push({ id: doc.id, ...doc.data(), doc });
      });
      return rows;
    })
    .catch((error) => {
      console.log("getFirestoreMultipleDocs ~ error", error);
      return ERROR_GETTING_DOCUMENT;
    });
};

export const getFirestoreMultipleDocsByPagination = async (
  collection,
  condition = [],
  {
    nextDoc = null,
    orderBy = ["createdAt asc"],
    limit = config.paginationLimit,
  }
) => {
  try {
    let rows = [];
    let nextResultDoc = null;
    let docRef = firebase.firestore().collection(collection);

    // if has condition, add to docRef
    if (condition.length) {
      condition.forEach((con) => {
        docRef = docRef.where(con[0], con[1], con[2]);
      });
    }

    // if has condition, add to docRef
    if (orderBy.length) {
      orderBy.forEach((order) => {
        const [fieldname = "createdAt", direction = "asc"] = order.split(" ");
        docRef = docRef.orderBy(fieldname, direction);
      });
    }

    if (limit < 1) {
      // invalid limit, make to default limit number
      limit = config.paginationLimit;
    }

    if (nextDoc) {
      docRef = docRef.startAt(nextDoc);
    }

    // obtain snapshot of collection no filters
    const snapshot = await docRef.limit(limit + 1).get();

    if (snapshot.empty) {
      return NO_MATCHING_DOCUMENTS;
    } else {
      // loop each document within collection
      snapshot.docs.forEach((doc, index) => {
        if (index === limit) {
          nextResultDoc = doc;
        } else {
          rows.push({ ...doc.data(), id: doc.id });
        }
      });

      //retrun all documents
      return { documents: rows, nextDoc: nextResultDoc };
    }
  } catch (error) {
    console.error("Error getting documents: ", error);
    return ERROR_GETTING_DOCUMENT;
  }
};

export const searchFirestoreMultiDocs = async function searchFirestoreMultiDocs(
  collectionName,
  key,
  value
) {
  console.log("key: ", key);
  console.log("value: ", value);
  let rows = [];
  var db = firebase.firestore();
  var docRef = db.collection(collectionName);
  return docRef
    .where(key, ">=", value)
    .get()
    .then((querySnapshot) => {
      // return docRef.where(key, '==', value).get().then((querySnapshot) => {
      console.log("querySnapshot: ", querySnapshot);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // rows.push(doc.data())
        rows.push({ document: doc.data(), id: doc.id });
      });
      return rows;
    })
    .catch((error) => {
      return rows;
    });
};

export const signIn = async function signIn(email, password, isRememberMe) {
  return firebase
    .auth()
    .setPersistence(
      isRememberMe
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION
    )
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function (userCredential) {
          if (firebase.auth().currentUser.emailVerified) {
            return { status: EMAIL_VERIFIED, data: userCredential };
          } else {
            return { status: EMAIL_NOT_VERIFIED, data: userCredential };
          }
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;

          if ("auth/wrong-password" === errorCode) {
            swal(
              "Error",
              "The password is invalid or the user does not have a password.",
              "error"
            );
            return { status: SIGNIN_ERROR, data: error };
          } else if ("auth/multi-factor-auth-required" === errorCode) {
            var resolver = error.resolver;
            return { status: MULTI_FACTOR_REQUIRED, data: resolver };
          } else if ("auth/user-not-found" === errorCode) {
            swal(
              "Error",
              "There is no account for this credentials, Please check and try again.",
              "error"
            );
            return { status: SIGNIN_ERROR, data: error };
          } else if (errorMessage) {
            swal("Error", errorMessage, "error");
            return { status: SIGNIN_ERROR, data: error };
          } else {
            swal("Error", "Something went wrong. Please try again!", "error");
            return { status: SIGNIN_ERROR, data: error };
          }
        });
    })
    .catch((error) => {
      // Handle Errors here.
      swal("Error", "Something went wrong. Please try again!", "error");
      return { status: SIGNIN_ERROR, data: error };
    });
};

export const getPhoneMFA = async (userId) => {
  try {
    // get phone number by id
    const getPhoneRes = await getFirestoreDoc(
      config.multifactorInfoCollection,
      userId
    );
    if (getPhoneRes !== null && getPhoneRes && getPhoneRes.id) {
      const { phone } = getPhoneRes || {};
      return { status: true, data: phone };
    } else {
      return {
        status: false,
        error: "Couldn't find connected phone number for your account",
      };
    }
  } catch (error) {
    console.log("getPhoneMFA ~ error", error);
    return { status: false, error: "Something went wrong" };
  }
};

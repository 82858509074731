import React, { PureComponent } from "react";
import { convertToMomentInstance } from "../../../utils/helpers";

export default class EmailListItem extends PureComponent {
  render() {
    const { orderNumber, subject, type, createdAt, status } = this.props.item;
    return (
      <tr>
        {/* number */}
        <td>
          <span>{orderNumber}</span>
        </td>

        {/* subject */}
        <td>
          <span>{subject}</span>
        </td>

        {/* type */}
        <td>
          <span>{type}</span>
        </td>

        {/* created at */}
        <td>
          <span>
            {createdAt
              ? convertToMomentInstance(createdAt).format("D MMMM yyyy")
              : null}
          </span>
          <p className="text-center mb-0">
            {createdAt
              ? convertToMomentInstance(createdAt).format("h:mm a")
              : null}
          </p>
        </td>

        {/* status */}
        <td>
          <span className={`tag ${status ? "tag-primary" : "tag-warning"}`}>
            {status ? "Sent" : "Fail"}
          </span>
        </td>
      </tr>
    );
  }
}

import React, { Component } from "react";

class Pagination extends Component {
  render() {
    const { hasPrevious, hasNext, onPreviousClick, onNextClick } = this.props;
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination mb-0 justify-content-end">
          <li className={`page-item ${hasPrevious || "disabled"}`}>
            <button className="page-link" onClick={onPreviousClick}>
              Previous
            </button>
          </li>
          <li className={`page-item ${hasNext || "disabled"}`}>
            <button className="page-link" onClick={onNextClick}>
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

Pagination.defaultProps = {
  hasPrevious: true,
  hasNext: true,
  onPreviousClick: () => {},
  onNextClick: () => {},
};

export default Pagination;

import _ from "lodash";
import axios from "axios";
import firebase from "firebase/app";

import config from "../config/index.json";

let timeout = 30000; // 30s
let baseURL = config.baseUrl;

export const preAuthAxios = async () => {
  // const token = await firebase.auth().currentUser.getIdToken()
  return axios.create({
    baseURL,
    timeout,
    // headers: {
    //   authorization: `Basic ${token}`
    // }
  });
};

const axiosHelper = async () => {
  const token = await firebase.auth().currentUser.getIdToken();
  return axios.create({
    baseURL,
    timeout,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const errorHandler = (error) => {
  if (error.message === "Network Error") {
    return error;
  } else if (error.code === "ECONNABORTED") {
    return { message: "Exceeded Timeout. Please try again" };
  } else if (
    error?.response?.data?.code === 422 &&
    _.isArray(error?.response?.data?.errors)
  ) {
    let message = "";

    _.forEach(error?.response?.data?.errors, (error) => {
      message += `${_.startCase(error.param)}: ${error.msg}\n`;
    });

    error.response.data.message = message;
  }
  return error?.response?.data;
};

export default axiosHelper;

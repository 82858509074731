import React, { Component } from "react";
import swal from "sweetalert";

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: this.props.filterList[0].value,
      searchValue: "",
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  handleChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.value });
    };
  }

  handleSearch(event) {
    console.log(this.state.searchValue);
    if (event) {
      const { selectedFilter, searchValue } = this.state;
      if (searchValue !== "") {
        // call the search callback func from parent
        this.props.handleSearch(selectedFilter, searchValue);
      } else {
        swal("Error!", "Search text can not be empty!", "warning");
      }
      event.preventDefault();
    }
  }

  handleRefresh() {
    // reset to inital state and call refresh callback func
    this.setState(
      {
        searchValue: "",
      },
      this.props.handleRefresh
    );
  }

  render() {
    return (
      <div className="card-options">
        <form onSubmit={this.handleSearch}>
          <div className="input-group">
            {/* render list of filter in dropdown */}
            <select
              className="custom-select"
              id="searchKeyInput"
              value={this.state.selectedFilter}
              onChange={this.handleChange("selectedFilter")}
            >
              {this.props.filterList.map((filter, idx) => {
                return (
                  <option key={idx} value={filter.value}>
                    {filter.title}
                  </option>
                );
              })}
            </select>

            {/* search box */}
            <input
              type="text"
              className="form-control form-control-sm ml-2"
              placeholder="Search..."
              value={this.state.searchValue}
              onChange={this.handleChange("searchValue")}
              name="search"
            />

            {/* search button */}
            <span className="input-group-btn ml-2">
              <button
                className="btn btn-sm btn-default"
                type="submit"
                onClick={this.handleSearch}
              >
                <span className="fe fe-search" />
              </button>
            </span>

            {/* refresh button */}
            <span className="input-group-btn ml-2">
              <button
                className="btn btn-sm btn-default"
                type="button"
                onClick={this.handleRefresh}
              >
                Reset
              </button>
            </span>
          </div>
        </form>
      </div>
    );
  }
}

SearchBox.defaultProps = {
  filterList: [{}], // array of object contains value and title
  handleSearch: (selectedFilter, searchValue) => {},
  handleRefresh: () => {},
};

export default SearchBox;

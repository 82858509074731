import axios, { errorHandler } from "./index";

export const sendGeneralNotification = async (data) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      "/notification/sendGeneralNotification",
      {
        title: data.title,
        body: data.body,
      }
    );
    return response?.data;
  } catch (error) {
    console.log("sendGeneralNotification ~ error:", error);
    return errorHandler(error);
  }
};

export const sendGeneralEmail = async (data) => {
  try {
    const instance = await axios();
    const response = await instance.post("/email/sendGeneralEmail", {
      subject: data.subject,
      body: data.body,
    });
    return response?.data;
  } catch (error) {
    console.log("sendGeneralEmail ~ error:", error);
    return errorHandler(error);
  }
};

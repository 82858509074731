import React, { Component } from "react";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";

import config from "./config";
import { createItem } from "./utils";
import { getFirestoreDoc } from "../../../assets/js/firebase";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
} from "../../../utils/constants";
import { capitalizeEachWord } from "../../../utils/helpers";

class AddItems extends Component {
  state = {
    loading: false,
    isModified: false,

    cmbsCode: "",
    category1: "",
    category2: "",

    errors: [],
  };

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleSave = () => {
    if (this.handleValidation()) {
      this.submitItemInfo();
    } else {
      let text = this.state.errors;
      let errorString = "";
      text.reduce((result, item) => {
        errorString = errorString + "\n" + item;
        return `${item}|`;
      }, "");
      swal("Error", errorString, "error");
    }
  };

  handleChange = (event) => {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
  };

  replaceCMBSPrompt = async (existedCMBS) => {
    try {
      // prompt replace alert to user
      this.setState({ loading: false }, async () => {
        const willReplace = await swal({
          title: `Code ${this.state.cmbsCode} already existed.`,
          text: `Do you want to replace the below existed CMBS content?\n\nExisted CMBS Code: ${
            existedCMBS.id
          }\nExisted Category1: ${capitalizeEachWord(
            existedCMBS.goShareCategory
          )}\nExisted Category2: ${capitalizeEachWord(
            existedCMBS.goShareCategory2
          )}`,
          icon: "warning",
          buttons: ["Cancel", "Replace"],
        });
        if (willReplace) {
          // force replace the cmbs code
          this.submitItemInfo(true);
        }
        return;
      });
    } catch (error) {
      this.setState({ loading: false });
      swal("Error", "Something Went Wrong", "error");
    }
  };

  submitItemInfo = (forceReplacing) => {
    this.setState({ loading: true }, async () => {
      try {
        const { cmbsCode, category1, category2 } = this.state;

        if (!forceReplacing) {
          // check if cmbs code existed
          const getCMBSRes = await getFirestoreDoc(config.collection, cmbsCode);

          if (getCMBSRes === ERROR_GETTING_DOCUMENT) {
            throw { message: "Error while getting CMBS code." };
          }

          if (getCMBSRes !== NO_MATCHING_DOCUMENTS) {
            // prompt user for replacing
            this.replaceCMBSPrompt(getCMBSRes);
            return;
          }
        }

        const result = await createItem({
          cmbs: cmbsCode,
          category1,
          category2,
        });
        if (result.status) {
          this.setState({
            loading: false,
            isModified: false,
            cmbsCode: "",
            category1: "",
            category2: "",
          });
          swal("success", "Item created sucessfully", "success");
        } else {
          throw { message: result.message };
        }
      } catch (error) {
        console.log("AddItems ~ error", error);
        this.setState({ loading: false });
        swal("Error", error.message || "Something Went Wrong", "error");
      }
    });
  };

  handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    // cmbs code
    if (!this.state.cmbsCode) {
      formIsValid = false;
      errors.push("CMBS code cannot be empty");
    }

    // category1
    if (!this.state.category1) {
      formIsValid = false;
      errors.push("Category1 cannot be empty");
    }

    this.state.errors = errors;
    return formIsValid;
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    <div className="dimmer active">
                      {loading ? <div className="loader" /> : null}

                      <div
                        className={`card-body ${
                          loading ? "dimmer-content" : null
                        }`}
                      >
                        <div className="row clearfix">
                          {/* cmbs code */}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">CMBS Code</label>
                              <input
                                type="text"
                                disabled={loading}
                                className="form-control"
                                placeholder="CMBS *"
                                name="cmbsCode"
                                value={this.state.cmbsCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* category1 */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Category1</label>
                              <input
                                type="text"
                                disabled={loading}
                                className="form-control"
                                placeholder="Category1 *"
                                name="category1"
                                value={this.state.category1}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* category2 */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Category2</label>
                              <input
                                type="text"
                                disabled={loading}
                                className="form-control"
                                placeholder="Category2"
                                name="category2"
                                value={this.state.category2}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        {/* create button */}
                        <button
                          type="button"
                          disabled={loading}
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddItems;

import axios, { errorHandler } from "./index";

export const createLearnFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post("/documents/createLearnFolder", data);
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("createLearnFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const createGlobalLearnFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      "/documents/createGeneralLearnFolder",
      data
    );
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("createGlobalLearnFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const createGlobalMyHealthFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      "/documents/createGeneralMyHealthFolder",
      data,
      {
        timeout: 0,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          // update loading percentage
          callback((loadedSizeInMB / totalSizeInMB) * 95);
        },
        encType: "multipart/form-data",
      }
    );
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("createGlobalMyHealthFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const createSiteSpecificLearnFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      "/documents/createSiteSpecificLearnFolder",
      data
    );
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("createSiteSpecificLearnFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const updateLearnFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post("/documents/updateLearnFolder", data);
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("updateLearnFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const updateMyHealthFolderAPI = async (data, callback) => {
  try {
    const instance = await axios();
    const response = await instance.post(
      "/documents/updateMyHealthFolder",
      data,
      {
        timeout: 0,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          // update loading percentage
          callback((loadedSizeInMB / totalSizeInMB) * 95);
        },
        encType: "multipart/form-data",
      }
    );
    callback(100);
    return response?.data;
  } catch (error) {
    console.log("updateMyHealthFolderAPI ~ error", error);
    return errorHandler(error);
  }
};

export const deleteLearnFolder = async ({ type, documentId, folderId }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/documents/deleteLearnFolder", {
      type,
      documentId,
      folderId,
    });
    return response?.data;
  } catch (error) {
    console.log("deleteLearnFolder ~ error", error);
    return errorHandler(error);
  }
};

export const deleteMyHealthFolder = async ({ type, documentId, folderId }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/documents/deleteMyHealthFolder", {
      type,
      documentId,
      folderId,
    });
    return response?.data;
  } catch (error) {
    console.log("deleteMyHealthFolder ~ error", error);
    return errorHandler(error);
  }
};

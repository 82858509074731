// component to handle session expired while inactive for period of time
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import firebase from "firebase/app";
import moment from "moment";
import swal from "sweetalert";
import { useSelector } from "react-redux";

import config from "../../config";
import Routes from "../../routes";
import { getFirestoreDoc, userSignOut } from "../../assets/js/firebase";
import { logout } from "../../utils";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
} from "../../utils/constants";

const defaultSessionTimeout = 1000 * 60 * config.inactiveInterval;
const defaultPromptTimeout = 1000 * 60 * config.inactivePromptInterval;
const defaultEvents = [
  "keydown",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
];

const IdleSession = (props) => {
  // hooks
  const location = useLocation();
  const history = useHistory();
  const isIdlerPaused = useSelector(
    (state) => state?.appReducer?.isIdlerPaused
  );

  // states
  const [state, setState] = useState({});

  // refs
  const lastActiveDateRef = useRef();

  // variables
  const isStateEmpty = _.isEmpty(state);

  const initializeIdler = async () => {
    // stop the timer first
    idleTimer.pause();

    // assign last active date time
    lastActiveDateRef.current = moment();

    // check if there valid token, start the session idle
    const currentUser = firebase.auth().currentUser;
    if (currentUser && !isIdlerPaused) {
      if (isStateEmpty) {
        // get timeout from config
        const getTimeoutConfigRes = await getFirestoreDoc(
          config.configAdminPortalCollection,
          config.idleSessionTimeoutDocId
        );

        if (
          getTimeoutConfigRes !== ERROR_GETTING_DOCUMENT &&
          getTimeoutConfigRes !== NO_MATCHING_DOCUMENTS
        ) {
          setState({
            sessionTimeout: 1000 * 60 * getTimeoutConfigRes.sessionTimeout,
            promptTimeout: 1000 * 60 * getTimeoutConfigRes.promptTimeout,
            events: getTimeoutConfigRes.events,
          });
        }
      }

      console.log("Start Idle Timer");
      idleTimer.start();
    } else {
      // reset state
      if (!_.isEmpty(state)) setState({});
    }
  };

  const onPrompt = async () => {
    console.log("show session expired prompt");

    // prompt with session expired message
    const willContinue = await swal({
      title: `Your session is about to expire.`,
      text: `Due to inactivity, you will be automatically signed out in one minute.`,
      icon: "warning",
      buttons: {
        cancel: "Logout",
        confirm: "Continue Session",
      },
      closeOnClickOutside: false,
      timer: isStateEmpty ? defaultPromptTimeout : state.promptTimeout,
    });

    if (willContinue) {
      // restart the timer
      initializeIdler();
    } else {
      // sign out user
      userSignOut();
      logout();
    }
  };

  const onIdle = async () => {
    // if user is not login, do nothing
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      // reset state
      setState({});

      idleTimer.pause();
      return;
    }

    // get the active duration
    const now = moment();
    console.log(
      "Result",
      moment.duration(now.diff(lastActiveDateRef.current)).asSeconds()
    );

    // sign out user
    userSignOut();
    logout();

    // prompt with session expired message
    await swal({
      title: `You have been signed out.`,
      text: "Automatic sign-out is enforced after a period of inactivity.",
      icon: "warning",
      button: "OK",
      closeOnClickOutside: false,
    });

    // redirect to login page
    history.push(Routes.auths.login.path);
  };

  const onActive = (event) => {
    console.log("Start Idle Timer");
    lastActiveDateRef.current = moment();
  };

  const idleTimer = useIdleTimer({
    startOnMount: false,
    startManually: true,
    timeout: isStateEmpty ? defaultSessionTimeout : state.sessionTimeout,
    promptTimeout: isStateEmpty ? defaultPromptTimeout : state.promptTimeout,
    onIdle,
    onActive,
    onPrompt,
    events: isStateEmpty ? defaultEvents : state.events,
  });

  useEffect(() => {
    if (isIdlerPaused) {
      // stop the timer
      idleTimer.pause();
    } else {
      // start the timer
      initializeIdler();
    }

    return () => {
      // stop the timer
      idleTimer.pause();
    };
  }, [location?.pathname, state, isIdlerPaused]);

  return <div />;
};

export default IdleSession;

import _ from "lodash";
import React, { Component } from "react";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";
import Switch from "react-switch";

import { createUser } from "../../../api/user";
import {
  userInfoHelper,
  validateName,
  validateEmail,
} from "../../../utils/helpers";
import {
  ADMIN,
  CLINICAL,
  STATES_LIST,
  SUPER_ADMIN,
  USER,
  USER_ROLES,
} from "../../../utils/constants";
import { fba_screen_view } from "../../../assets/js/firebase";

class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isModified: false,

      email: "",
      password: "",
      // userName: '',
      confirmPassword: "",
      firstName: "",
      lastName: "",
      dob: "",
      state: "",
      role: "",
      status: true,
      phone: "",
      currentUserRole: null,
      fields: {},
      errors: [],
    };

    fba_screen_view("add users");

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  async componentDidMount() {
    //load list data for the component
    const userInfo = await userInfoHelper(window);
    this.setState({ currentUserRole: userInfo.role });
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  // componentDidMount() {
  // 	// prompt user when reload the page without saving
  // 	window.onbeforeunload = (event) => {
  // 		// Show prompt based on state
  // 		if (this.state.isModified) {
  // 			const e = event || window.event;
  // 			e.preventDefault();
  // 			if (e) {
  // 				e.returnValue = ''
  // 			}
  // 			return '';
  // 		}
  // 	}
  // }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleSave() {
    if (this.handleValidation()) {
      this.submitUserInfo();
    } else {
      let errorString = this.state.errors.join("\n");
      swal("Error", errorString, "error");
    }
  }

  handleDOBChange = (event) => {
    this.state.isModified = true;
    let dob = event.target.value;

    if (dob.length <= 10) {
      this.setState({ dob });
    }
  };

  handleChange(event) {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({ errors: fields });
  }

  handleStatusChange(event) {
    let updatedStatus = !this.state.status;
    this.setState({ status: updatedStatus });
    let fields = this.state.fields;
    fields["status"] = updatedStatus;
    this.setState({ errors: fields, status: updatedStatus });
  }

  submitUserInfo() {
    // firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
    // 	.then((userCredential) => {
    // 		console.log('userCredential: ', userCredential.uid)
    // 		// Signed in
    // 		var user = userCredential.user;
    // 		// update phone number
    // 		user.updateProfile({
    // 			phoneNumber: this.state.phone,
    // 		  }).then(function() {
    // 			// Update successful.
    // 		  }).catch(function(error) {
    // 			// An error happened.
    // 		  });
    // 		  // user created succcessfully, proceed to send email verification
    // 		sendEmailVerfication(false);
    // 		// get user token

    // 		const data = {
    // 			email: this.state.email,
    // 			id: user.uid,
    // 			firstName: this.state.firstName,
    // 			lastName: this.state.lastName,
    // 			phone: this.state.phone,
    // 			role: this.state.role,
    // 			status: this.state.status,
    // 		}
    // 		createFirestoreUserDoc(data, user.uid )
    // 	})
    // 	.catch((error) => {
    // 		var errorCode = error.code;
    // 		var errorMessage = error.message;
    // 		if("auth/email-already-in-use" == errorCode){
    // 			// email is already used on the firebase account
    // 			swal("Error", "The email address is already in use by another account.", "error");
    // 		} else if (errorMessage) {
    // 			swal("Error", errorMessage, "error");
    // 		} else {
    // 			swal("Error", "Something went wrong. Please try again!", "error");
    // 		}
    // 		this.setState({siginInProgress: false});
    // 	}
    // );
    this.setState({ loading: true }, () => {
      createUser(
        this.state.email,
        this.state.password,
        this.state.firstName,
        this.state.lastName,
        this.state.phone,
        this.state.role,
        this.state.dob,
        this.state.state,
        this.state.status
      ).then((results) => {
        if (results.message === "OK") {
          this.setState({
            loading: false,
            isModified: false,
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            role: "",
            dob: "",
            state: "",
            status: true,
            phone: "",
          });
          swal("success", "User created sucessfully", "success");
        } else {
          this.setState({ loading: false });
          swal("Error", results.message || "Something Went Wrong", "error");
        }
      });
    });
  }

  handleValidation() {
    let errors = [];
    let formIsValid = true;

    //First Name
    if (!this.state.role) {
      formIsValid = false;
      errors.push("Role can not be empty");
      //    errors["firstName"] = "First name cannot be empty";
    }

    //First Name
    if (!this.state.firstName) {
      formIsValid = false;
      errors.push("First name cannot be empty");
      //    errors["firstName"] = "First name cannot be empty";
    }

    if (typeof this.state.firstName !== "undefined") {
      if (!validateName(this.state.firstName)) {
        formIsValid = false;
        errors.push("First name must no contain any number or symbol");
      }
    }

    //Last Name
    if (!this.state.lastName) {
      formIsValid = false;
      errors.push("Last name cannot be empty");
    }

    if (typeof this.state.lastName !== "undefined") {
      if (!validateName(this.state.lastName)) {
        formIsValid = false;
        errors.push("Last name must no contain any number or symbol");
      }
    }

    // Date of Birth
    if (!this.state.dob) {
      formIsValid = false;
      errors.push("Date of birth cannot be empty");
    } else if (
      this.state.dob?.length !== 10 ||
      this.state.dob[4] !== "/" ||
      this.state.dob[7] !== "/"
    ) {
      formIsValid = false;
      errors.push("Date of birth must follow the format of YYYY/MM/DD");
    }

    // State
    if (!this.state.state) {
      formIsValid = false;
      errors.push("State cannot be empty");
    }

    //Email
    if (!this.state.email) {
      formIsValid = false;
      errors.push("Email cannot be empty");
    }

    if (typeof this.state.email !== "undefined") {
      if (!validateEmail(this.state.email)) {
        formIsValid = false;
        errors.push("Email is not valid");
      }
    }

    //Mobile No
    if (!this.state.phone) {
      formIsValid = false;
      errors.push("Mobile number cannot be empty");
    }

    if (typeof this.state.phone !== "undefined") {
      if (!this.state.phone.match(/^\d{10}$/)) {
        formIsValid = false;
        errors.push("Mobile number is not valid");
      }
    }

    //User Name
    // if(!fields["userName"]){
    // 	formIsValid = false;
    // 	errors.push("User name cannot be empty");
    //  }

    //  if(typeof fields["userName"] !== "undefined"){
    // 	let lastAtPos = fields["userName"].lastIndexOf('@');
    // 	let lastDotPos = fields["userName"].lastIndexOf('.');

    // 	if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["userName"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["userName"].length - lastDotPos) > 2)) {
    // 	   formIsValid = false;
    // 	   errors.push("User name is not valid");
    // 	 }
    //  }

    //Password
    if (!this.state.password) {
      formIsValid = false;
      errors.push("Password cannot be empty");
    }

    if (typeof this.state.password !== "undefined") {
      if (
        !this.state.password.match(
          // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$@!%&*?]{10,}$/
        )
      ) {
        formIsValid = false;
        errors.push(
          "Password to be a minimum of 10 characters using a mixture of lower case, capital letters and numbers."
        );
      }
    }

    // Confirm password
    if (!this.state.password) {
      formIsValid = false;
      errors.push("Confirm password cannot be empty");
    }

    if (this.state.password !== this.state.confirmPassword) {
      formIsValid = false;
      errors.push("Password and confirm password is not match");
    }

    this.state.errors = errors;
    return formIsValid;
  }

  render() {
    const { loading, currentUserRole } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane active" id="user-add" role="tabpanel">
                <div className="card">
                  <div className="dimmer active">
                    {loading ? <div className="loader" /> : null}

                    <div
                      className={`card-body ${
                        loading ? "dimmer-content" : null
                      }`}
                    >
                      <form autoComplete="off">
                        <div className="row clearfix">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Role Type</label>
                              {"superadmin" === currentUserRole ? (
                                <select
                                  className="form-control custom-select show-tick"
                                  name="role"
                                  value={this.state.role}
                                  onChange={this.handleChange}
                                >
                                  <option>Select Role Type</option>
                                  <option value={SUPER_ADMIN}>
                                    {USER_ROLES[SUPER_ADMIN]}
                                  </option>
                                  <option value={ADMIN}>
                                    {USER_ROLES[ADMIN]}
                                  </option>
                                  <option value={CLINICAL}>
                                    {USER_ROLES[CLINICAL]}
                                  </option>
                                  <option value={USER}>
                                    {USER_ROLES[USER]}
                                  </option>
                                </select>
                              ) : (
                                <select
                                  className="form-control custom-select show-tick"
                                  name="role"
                                  value={this.state.role}
                                  onChange={this.handleChange}
                                >
                                  <option>Select Role Type</option>
                                  <option value={ADMIN}>
                                    {USER_ROLES[ADMIN]}
                                  </option>
                                  <option value={CLINICAL}>
                                    {USER_ROLES[CLINICAL]}
                                  </option>
                                  <option value={USER}>
                                    {USER_ROLES[USER]}
                                  </option>
                                </select>
                              )}
                              {/* <select
                                className="form-control custom-select show-tick"
                                name="role"
                                value={this.state.role}
                                onChange={this.handleChange}
                              >
                                <option>Select Role Type</option>
                                <option>Super Admin</option>
                                <option>Admin</option>
                                <option>User</option>
                              </select> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row clearfix">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">First Name *</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="First Name"
                                name="firstName"
                                autoComplete="none"
                                value={this.state.firstName}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Last Name *</label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Last Name"
                                name="lastName"
                                autoComplete="none"
                                value={this.state.lastName}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Date of Birth *
                              </label>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="YYYY/MM/DD"
                                name="dob"
                                value={this.state.dob}
                                onChange={this.handleDOBChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">State *</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="state"
                                value={this.state.state}
                                onChange={this.handleChange}
                              >
                                <option>Select State</option>
                                {_.map(STATES_LIST, (state, idx) => (
                                  <option key={idx} value={state.value}>
                                    {state.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email Address *
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                name="email"
                                autoComplete="new-email"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Mobile Phone *
                              </label>
                              <input
                                type="phone"
                                className="form-control"
                                placeholder="Mobile Phone"
                                name="phone"
                                autoComplete="none"
                                value={this.state.phone}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row clearfix">
                          {/* <div className="col-md-4 col-sm-12">
														<div className="form-group">
															<label className="form-label">Username</label>
															<input
																type="text"
																className="form-control"
																placeholder="Username *"
																name="userName"
																value={this.state.userName} 
																onChange={this.handleChange}
															/>
														</div>
													</div> */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Password *</label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                name="password"
                                autoComplete="new-password"
                                value={this.state.password}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Confirm Password *
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                autoComplete="new-password"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">User Status</label>
                              <Switch
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor="#1f3886"
                                height={20}
                                width={40}
                                onChange={this.handleStatusChange}
                                checked={this.state.status}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.handleSave()}
                            >
                              Add
                            </button>
                            {/* <button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
													>
																					CLOSE
													</button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddUsers;

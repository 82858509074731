import _ from "lodash";
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import swal from "sweetalert";
import { v4 as uuidv4 } from "uuid";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Loader from "../../Shared/Loader";
import FileUpload from "../../Shared/FileUpload";

import { updateLearnFolder } from "./utils";
import {
  firebase,
  firebaseConfig,
  getFirestoreDoc,
} from "../../../assets/js/firebase";
import {
  EDITOR_BLOCK_TYPE,
  EDITOR_INLINE,
  EDITOR_LABELS,
  EDITOR_STYLE,
  EDITOR_TOOLBAR_OPTIONS,
  ERROR_GETTING_DOCUMENT,
  GLOBAL_TYPE,
  NO_MATCHING_DOCUMENTS,
  PDF,
  SITE_SPECIFIC_TYPE,
  USER_TYPE,
  VIDEO,
} from "../../../utils/constants";
import config from "./config";
import Routes from "../../../routes";
import { capitalizeEachWord, getFileName } from "../../../utils/helpers";

const storage = firebase.app().storage(firebaseConfig.storageBucket);

class EducationUpdate extends Component {
  constructor(props) {
    super(props);

    const { id, type, user, admission, folder } =
      this.props.location?.state || {};

    if (!(id && type)) {
      // no document id, go back to manage education page
      this.props.history.push(Routes.documents.education.path);
    }

    this.state = {
      loading: false,
      percentage: 0,
      isModified: false,

      fileTypes: [PDF, VIDEO],

      id,
      type,
      user,
      admission,
      folderId: folder?.id,
      folderName: folder?.name,
      folderDescription: folder?.description,
      documents: folder?.documents,
      deletedIds: [],
    };
  }

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

    // get folder detail
    this.getDocumentFolder();
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getDocumentFolder = () => {
    try {
      this.setState({ loading: true }, async () => {
        // determine collection
        let collection = "";
        if (this.state.type === GLOBAL_TYPE) {
          collection = config.generalDocumentCollection;
        } else if (this.state.type === USER_TYPE) {
          collection = config.otherLearnCollection;
        } else if (this.state.type === SITE_SPECIFIC_TYPE) {
          collection = config.siteSpecificDocumentCollection;
        } else {
          throw { message: "Something went wrong." };
        }

        // get document folder item
        const getLearnRes = await getFirestoreDoc(collection, this.state.id);

        if (getLearnRes === ERROR_GETTING_DOCUMENT) {
          throw {
            message:
              "Something went wrong while getting document folder details.",
          };
        }

        if (getLearnRes === NO_MATCHING_DOCUMENTS) {
          throw { message: "Document folder not found." };
        }

        // get the folder by id
        const folder = _.find(getLearnRes.folders, (item) => {
          return item.id === this.state.folderId;
        });

        if (_.isEmpty(folder)) {
          throw { message: "Folder not found." };
        }

        let documents = [];
        if (_.isArray(folder?.documents)) {
          for (const document of folder.documents) {
            // handle file existing file url
            if (document.uri) {
              document.fileUrl = await storage
                .refFromURL(document.uri)
                .getDownloadURL();
            }

            // handle text editorState content
            if (document.fileType === VIDEO) {
              const blocksFromHtml = htmlToDraft(document.content);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              );
              const editorState = EditorState.createWithContent(contentState);
              document.editorState = editorState;
            }
            documents.push(document);
          }
        }

        this.setState({
          loading: false,
          folderId: folder?.id,
          folderName: folder?.name,
          folderDescription: folder?.description,
          documents,
          deletedIds: [],
        });
      });
    } catch (error) {
      console.log("getDocumentFolder ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  onEditorStateChange = (idx) => (editorState) => {
    let { documents } = this.state;

    if (documents[idx].fileType === VIDEO) {
      documents[idx].editorState = editorState;
      this.setState({ documents });
    }
  };

  handleFileTypeChange = (idx) => (event) => {
    let { documents } = this.state;
    documents[idx].fileType = event.target.value;
    documents[idx].file = "";

    this.setState({ documents });
  };

  onDocumentChange = (idx) => (event) => {
    let { documents } = this.state;
    documents[idx][event.target.name] = event.target.value;
    this.setState({ documents });
  };

  onFileChange = (idx) => (file) => {
    let { documents } = this.state;
    const fileName = file.name;
    const extension = fileName
      .substr(fileName.lastIndexOf("\\") + 1)
      .split(".")[1];
    documents[idx].file = file;
    documents[idx].extension = extension;
    this.setState({ documents });
  };

  onFileDelete = (idx) => () => {
    let { documents } = this.state;
    documents[idx].file = "";
    this.setState({ documents });
  };

  handleChange = (event) => {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
  };

  onAddDocumentClick = () => {
    let { documents } = this.state;

    let errorMessage = "";
    // validate if the previous document are all filled up
    documents.forEach(({ isNew, name, description, file }) => {
      if (!name || !description || !(!isNew || file)) {
        errorMessage =
          "To add new document, please filled in previous documents information first";
      }
    });

    if (errorMessage) {
      swal("Warning", errorMessage, "warning");
      return;
    }

    // process to add new document
    const documentInfo = {
      id: uuidv4(),
      name: "",
      description: "",
      isNew: true,
      file: "",
      fileType: "",
      videoTitle: "",
      extension: "",
      editorState: EditorState.createEmpty(),
    };
    this.setState({ documents: [...documents, documentInfo] });
  };

  onDeletClick = (name, idx) => async () => {
    const willDelete = await swal({
      title: `Are you sure you want to remove ${
        name || `document number ${idx + 1}`
      }?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      let { documents, deletedIds } = this.state;
      let newState = {};
      let newDocuments = [];
      documents.forEach((doc, docIdx) => {
        if (idx === docIdx) {
          if (!doc.isNew) {
            newState.deletedIds = [...deletedIds, doc.id];
          }
        } else {
          newDocuments.push(doc);
        }
      });
      newState.documents = newDocuments;
      this.setState(newState);
    }
  };

  handleValidation = () => {
    const { folderName, folderDescription, documents } = this.state;
    let errorMessage = [];
    if (!folderName) {
      errorMessage.push("Folder name cannot be empty");
    }
    if (!folderDescription) {
      errorMessage.push("Folder Description cannot be empty");
    }

    if (documents.length > 0) {
      documents.forEach(({ isNew, name, description, file }) => {
        if (!name || !description || !(!isNew || file)) {
          errorMessage.push("One of more document fields are empty");
        }
      });
    } else {
      errorMessage.push(
        "The folder cannot be empty. Please create at least one document"
      );
    }

    if (errorMessage.length) {
      swal("Error", errorMessage.join("\n"), "error");
      return false;
    }
    return true;
  };

  setLoaderPercentage = (percentage) => {
    this.setState({ percentage: Math.round(percentage) });
  };

  onSaveClick = async () => {
    if (this.handleValidation()) {
      const {
        type,
        id,
        folderId,
        folderName,
        folderDescription,
        documents,
        deletedIds,
        user,
      } = this.state;

      this.setState({ loading: true });
      const response = await updateLearnFolder(
        {
          type,
          id,
          folderId,
          folderName,
          folderDescription,
          documents,
          deletedIds,
          userId: user?.id,
        },
        this.setLoaderPercentage
      );

      const newState = { loading: false, percentage: 0 };

      if (response.status) {
        newState.isModified = false;
        this.setState(newState, this.getDocumentFolder);
        swal("Success", "The folder has been updated", "success");
      } else {
        this.setState(newState);
        swal("Error", response.message, "error");
      }
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div className="tab-pane active" id="user-add" role="tabpanel">
                <div className="card">
                  {loading ? (
                    <Loader percentage={this.state.percentage} />
                  ) : null}

                  <div className="card-header">
                    <h3 className="card-title">Education</h3>
                  </div>

                  <div className="card-body">
                    <div className="row clearfix">
                      {/* type */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">Type</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={this.state.type}
                          />
                        </div>
                      </div>

                      {/* user */}
                      {this.state.user ? (
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">User</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.user?.email}
                            />
                          </div>
                        </div>
                      ) : null}

                      {/* admission */}
                      {this.state.admission ? (
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Admission</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.admission?.specialty}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <hr />

                    {/* folder section */}
                    <div className="card-header">
                      <h3 className="card-title">Folder Information</h3>
                    </div>

                    <div className="row clearfix mt-20">
                      {/* folder name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">Folder Name *</label>
                          <input
                            disabled={loading}
                            type="text"
                            className="form-control"
                            name="folderName"
                            placeholder="Name here..."
                            onChange={this.handleChange}
                            value={this.state.folderName}
                          />
                        </div>
                      </div>
                      {/* folder description */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="form-label">
                            Folder Description *
                          </label>
                          <input
                            disabled={loading}
                            type="text"
                            className="form-control"
                            name="folderDescription"
                            placeholder="Descrption here..."
                            onChange={this.handleChange}
                            value={this.state.folderDescription}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div
                        className={`table-responsive ${
                          loading ? "dimmer-content" : null
                        }`}
                      >
                        {/* documents inside the folder */}
                        <div className="col-sm-12 border-style">
                          <div className="card">
                            <div className="card-status card-status-left bg-blue"></div>
                            {this.state.documents.map((document, idx) => (
                              <div
                                className="card-body mb-10"
                                key={document.id}
                              >
                                <div className="row clearfix">
                                  {/* document order number */}
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                      <h2 className="card-title">
                                        <b>
                                          {document?.file?.name ||
                                            `Document number ${idx + 1}`}
                                        </b>
                                      </h2>
                                    </div>
                                  </div>

                                  {/* delete button */}
                                  {idx > 0 ||
                                  this.state.documents.length > 1 ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group  float-right">
                                        <button
                                          type="button"
                                          className="btn btn-danger text-white"
                                          onClick={this.onDeletClick(
                                            document?.file?.name,
                                            idx
                                          )}
                                        >
                                          <i className="fe fe-trash mr-2"></i>
                                          DELETE
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>

                                <div className="row clearfix">
                                  {/* document name */}
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Document Name *
                                      </label>
                                      <input
                                        disabled={loading}
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        onChange={this.onDocumentChange(idx)}
                                        placeholder="Document name here..."
                                        value={document.name}
                                      />
                                    </div>
                                  </div>
                                  {/* document upload */}
                                  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                      <label className="form-label">
                                        Document Description *
                                      </label>
                                      <input
                                        disabled={loading}
                                        type="text"
                                        className="form-control"
                                        name="description"
                                        placeholder="Document description here..."
                                        onChange={this.onDocumentChange(idx)}
                                        value={document.description}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row clearfix">
                                  {/* document file */}
                                  <div className="col-lg-6 col-md-6 col-sm-12 mb-20 mt-30">
                                    {document.isNew ? (
                                      <FileUpload
                                        header={
                                          <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <label className="form-label">
                                                Document File *
                                              </label>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="form-group">
                                                <select
                                                  disabled={loading}
                                                  className="form-control custom-select show-tick"
                                                  name="selectedFileType"
                                                  onChange={this.handleFileTypeChange(
                                                    idx
                                                  )}
                                                  value={document.fileType}
                                                >
                                                  <option disabled value="">
                                                    Select Document Type
                                                  </option>
                                                  {this.state.fileTypes.map(
                                                    (type) => (
                                                      <option
                                                        key={type}
                                                        value={type}
                                                      >
                                                        {capitalizeEachWord(
                                                          type
                                                        )}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        disabled={
                                          loading ||
                                          !this.state.documents[idx]?.fileType
                                        }
                                        name={document?.file?.name}
                                        file={this.state.documents[idx]?.file}
                                        fileType={
                                          this.state.documents[idx]?.fileType
                                        }
                                        acceptTypes={
                                          this.state.documents[idx]
                                            ?.fileType === PDF
                                            ? ".pdf"
                                            : ".mp4,.mov"
                                        }
                                        onChange={this.onFileChange(idx)}
                                        onDelete={this.onFileDelete(idx)}
                                      />
                                    ) : (
                                      <div className="form-group">
                                        <label className="form-label">
                                          Document File
                                        </label>
                                        <a
                                          href={document.fileUrl}
                                          target="_blank"
                                          className="link"
                                        >
                                          {getFileName(document.uri)}
                                        </a>
                                      </div>
                                    )}
                                  </div>

                                  {/* text editor */}
                                  {this.state.documents[idx]?.fileType ===
                                  VIDEO ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                      <div className="form-group">
                                        <label className="form-label">
                                          Video Title
                                        </label>
                                        <input
                                          disabled={loading}
                                          type="text"
                                          className="form-control"
                                          name="videoTitle"
                                          onChange={this.onDocumentChange(idx)}
                                          placeholder="Video title here..."
                                          value={document.videoTitle}
                                        />
                                      </div>

                                      <div className="form-group border">
                                        <Editor
                                          editorStyle={EDITOR_STYLE}
                                          handlePastedText={() => false}
                                          localization={{
                                            locale: "en",
                                            translations: EDITOR_LABELS,
                                          }}
                                          editorState={
                                            this.state.documents[idx]
                                              ?.editorState
                                          }
                                          toolbarClassName="toolbarClassName"
                                          wrapperClassName="wrapperClassName"
                                          editorClassName="editorClassName"
                                          onEditorStateChange={this.onEditorStateChange(
                                            idx
                                          )}
                                          toolbar={{
                                            options: EDITOR_TOOLBAR_OPTIONS,
                                            inline: EDITOR_INLINE,
                                            blockType: EDITOR_BLOCK_TYPE,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={loading}
                      type="button"
                      className="btn btn-primary"
                      onClick={this.onAddDocumentClick}
                    >
                      <i className="fe fe-plus mr-2"></i>
                      Add Document
                    </button>

                    <div className="text-right">
                      <button
                        disabled={loading}
                        type="button"
                        className="btn btn-primary"
                        onClick={this.onSaveClick}
                      >
                        <i className="fe fe-save mr-2"></i>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EducationUpdate;

import React, { Component } from "react";
import swal from "sweetalert";
import moment from "moment";
import firebase from "firebase/app";
import { Prompt } from "react-router-dom";

import { getEducation, createItem, updateItem, getCreateReqObj } from "./utils";

import { sendCart, getUserChart } from "../../../api/learn";

import config from "./config";

class EducationInputGroup extends Component {
  render() {
    const { idx, item, onDelete, onChange } = this.props;
    return (
      <>
        <div className="col-sm-12 border-style">
          <div className="card">
            <div className="card-status card-status-left bg-blue"></div>
            <div className="card-body">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Name Override *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nameOverride"
                      value={item.nameOverride}
                      onChange={onChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Bundle ID *</label>
                    <input
                      disabled={!item.isNew}
                      type="number"
                      className="form-control"
                      name="bundleId"
                      value={item.bundleId}
                      onChange={onChange(idx)}
                    />
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      maxLength="30"
                      value={item.description || ""}
                      onChange={onChange(idx)}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">CMBS *</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cmbs"
                      value={item.cmbs}
                      onChange={onChange(idx)}
                    />
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Status Match *</label>
                    <select
                      className="form-control custom-select show-tick"
                      name="statusMatch"
                      value={item.statusMatch}
                      onChange={onChange(idx)}
                    >
                      <option>--</option>
                      {config.educationStatus.map((status, idx) => (
                        <option key={idx}>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <div className="btn-list text-right mt-4">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={onDelete(item, idx)}
                      >
                        <i className="fe fe-trash mr-2"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class Update extends Component {
  constructor(props) {
    super(props);
    let propItem =
      this.props.location.state != null ? this.props.location.state.item : null;
    let propItemId =
      this.props.location.state != null ? this.props.location.state.id : null;

    //assign props values to state values
    this.state = {
      loading: true,
      isModified: false,

      admissionId: propItemId != null ? propItemId : "",
      admissionName: propItem != null ? propItem.name : "",
      admissionStatus: propItem != null ? propItem.status : "",
      goShareId: propItem != null ? propItem.goShareId : "",
      username: propItem != null ? propItem.username : "",
      userId: propItem != null ? propItem.userId : "",
      locationId: propItem != null ? propItem.locationId : "",
      locationName: propItem != null ? propItem.locationName : "",
      originalEducationData: {}, // to trace the change of each field
      education: {},
      errors: [],
    };

    this.getEducationCallback = this.getEducationCallback.bind(this);
    this.handleAddEducationBtn = this.handleAddEducationBtn.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    // fetch education data by admissionId
    getEducation(this.state.admissionId, this.getEducationCallback);

    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getEducationCallback(noError, data, error) {
    this.state.loading = false;
    if (noError) {
      let education = {};
      let originalEducationData = {};
      if (data === null) {
        // if no education docment, create initial doc
        education = {
          admissionId: this.state.admissionId,
          userId: this.state.userId,
          resource: [],
        };
        // duplicate education data to trace the change
        originalEducationData = {
          admissionId: this.state.admissionId,
          userId: this.state.userId,
          resource: [],
        };
      } else {
        education = data;
        // duplicate education data to trace the change
        originalEducationData = {
          ...education,
          resource: [...education.resource],
        };
      }
      this.setState({
        education,
        originalEducationData,
      });
    } else {
      swal("Error!", "Something went wrong!", "error");
    }
  }

  handleEducationChange = (index) => (event) => {
    this.state.isModified = true;

    const { name, value } = event.target;
    const { education } = this.state;
    const change = education["resource"][index];
    // use spread object operator to avoid object referencing which also affect the originalEducationData
    education["resource"][index] = { ...change, [name]: value, isChange: true };
    this.setState({ education });
  };

  handleAddEducationBtn() {
    const { education } = this.state;
    this.setState({
      isModified: true,
      education: {
        ...education,
        resource: [
          ...education.resource,
          {
            isNew: true,
            bundleId: "",
            cmbs: "",
            nameOverride: "",
            statusMatch: "",
          },
        ],
      },
    });
  }

  handleDeleteEducationBtn = (item, index) => () => {
    swal({
      title: "Are you sure you want to remove " + item.nameOverride + " ?",
      text: `Once you press OK, ${item.nameOverride} won't be deleted in the database yet until you click on Update button below.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const { education } = this.state;
        const newResource = education.resource.filter(function (item, idx) {
          return index !== idx;
        });
        this.setState({
          isModified: true,
          education: {
            ...education,
            resource: newResource,
          },
        });
      }
    });
  };

  submitData() {
    this.setState({ loading: true });

    let callGoShareAPI = false;
    let modifiedResource = this.state.education.resource;

    // performance send cart to server
    Promise.all(
      modifiedResource.map((item, idx) => {
        if (item.isNew) {
          callGoShareAPI = true;
          return sendCart(this.state.education.userId, item.bundleId);
        } else {
          return { message: "OK" };
        }
      })
    )
      .then(async (results) => {
        results.forEach((result, idx) => {
          let isSent;
          if (result && result.message === "OK") {
            isSent = true;
          } else {
            // sendCart not success
            isSent = false;
          }
          modifiedResource[idx].isSent = isSent;
        });

        // performance update request
        const educationArrayObj = modifiedResource.map((resource) => {
          return getCreateReqObj([
            resource.accessToken || "",
            parseInt(resource.bundleId) || null,
            resource.bundleName || "",
            resource.cmbs || "",
            resource.nameOverride || "",
            resource.description || "",
            resource.statusMatch || "",
            resource.url || "",
            resource.isSent || false,
            resource.isNew
              ? firebase.firestore.Timestamp.now()
              : resource.createdAt,
            firebase.firestore.Timestamp.now(),
          ]);
        });

        const educationId = this.state.education.id;
        let res = null;

        if (educationId) {
          // update education doc to collection
          res = await updateItem(educationId, { resource: educationArrayObj });
        } else {
          // create education doc to collection
          res = await createItem(this.state.education);
        }

        if (res === true) {
          this.state.isModified = false;

          if (callGoShareAPI) {
            // call get user cart to server
            await getUserChart(this.state.goShareId);
          }

          // refetch the data
          getEducation(this.state.admissionId, this.getEducationCallback);
          swal("success", "Saved sucessfully", "success");
        } else {
          this.setState({ loading: false });
          swal("Error", "Something went wrong", "error");
        }
      })
      .catch((error) => {
        console.log("sendCart ~ error", error);
      });
  }

  handleValidation() {
    let errors = [];
    const { resource } = this.state.education;
    let formIsValid = true;

    // check each resource of the education
    resource.forEach((item, idx) => {
      //bundleId
      if (!item.bundleId) {
        formIsValid = false;
        errors.push(
          `[${item.bundleName || idx + 1}]: Bundle ID cannot be empty`
        );
      }

      //cmbs
      if (!item.cmbs) {
        formIsValid = false;
        errors.push(`[${item.bundleName || idx + 1}]: CMBS cannot be empty`);
      }

      //nameOverride
      if (!item.nameOverride) {
        formIsValid = false;
        errors.push(
          `[${item.bundleName || idx + 1}]: Name Override cannot be empty`
        );
      }

      //status
      if (!config.educationStatus.includes(item.statusMatch)) {
        formIsValid = false;
        errors.push(`[${item.bundleName || idx + 1}]: Invalid status`);
      }
    });

    this.state.errors = errors;
    return formIsValid;
  }

  handleSave() {
    if (this.state.isModified) {
      if (this.handleValidation()) {
        this.submitData();
      } else {
        let text = this.state.errors;
        let errorString = "";
        text.reduce((result, item) => {
          errorString = errorString + "\n" + item;
          return `${item}|`;
        }, "");

        swal("Error", errorString, "error");
      }
    }
  }

  render() {
    const { education, originalEducationData, loading } = this.state;

    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    {/* admission section */}
                    <div className="card-header">
                      <h3 className="card-title">Admission</h3>
                    </div>

                    <div className="card-body">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Patient Name</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.username}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Hospital</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.locationName}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Admission ID</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.admissionId}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Admission Name</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={this.state.admissionName}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Date</label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={moment(this.state.date).format("L LT")}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Status</label>
                            <select
                              disabled
                              className="form-control custom-select show-tick"
                              value={this.state.admissionStatus}
                            >
                              {config.admissionStatus.map((status, idx) => (
                                <option key={idx}>{status}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>

                    {/* education section */}
                    <div className="card-header">
                      <h3 className="card-title">Education</h3>
                    </div>

                    <div className="card-body">
                      <div className="dimmer active">
                        {loading ? <div className="loader" /> : null}
                        <div
                          className={`table-responsive ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          {!(
                            education.resource && education.resource.length
                          ) ? (
                            <div>No document found</div>
                          ) : (
                            education.resource.map((item, idx) => {
                              return (
                                <div key={idx}>
                                  <EducationInputGroup
                                    item={item}
                                    idx={idx}
                                    onChange={this.handleEducationChange}
                                    onDelete={this.handleDeleteEducationBtn}
                                  />
                                  <hr />
                                </div>
                              );
                            })
                          )}

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.handleAddEducationBtn}
                          >
                            <i className="fe fe-plus mr-2"></i>
                            Add
                          </button>

                          {(originalEducationData.resource &&
                            originalEducationData.resource.length) ||
                          (education.resource && education.resource.length) ? (
                            <div className="btn-list text-right">
                              <button
                                disabled={!this.state.isModified}
                                type="button"
                                className={`btn btn-${
                                  !this.state.isModified
                                    ? "secondary"
                                    : "primary"
                                }`}
                                onClick={this.handleSave}
                              >
                                <i className="fe fe-save mr-2"></i>
                                Update
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Update;

import _ from "lodash";
import React, { PureComponent } from "react";
import swal from "sweetalert";
import { Link, Prompt } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Switch from "react-switch";
import firebase from "firebase/app";

import {
  checkEmailStatusAP,
  getEmailVerificationUrlAP,
  getResetPasswordUrlAP,
  updateUser,
  updateEmailAddressAP,
  deleteUser,
} from "../../../api/user";
import {
  validateName,
  validateEmail,
  roleFormatter,
  capitalizeEachWord,
  convertToMomentInstance,
  isSameString,
} from "../../../utils/helpers";
import { fba_screen_view, getFirestoreDoc } from "../../../assets/js/firebase";
import {
  USER_ROLES,
  SUPER_ADMIN,
  CLINICAL,
  ADMIN,
  USER,
  NO_MATCHING_DOCUMENTS,
  EMAIL_VERIFICATION,
  RESET_PASSWORD,
  ERROR_GETTING_DOCUMENT,
  USER_TYPE,
} from "../../../utils/constants";
import Routes from "../../../routes/index";
import { clearUserTokens } from "../../../api/auth";
import Table from "../../common/table";
import Modal from "../../common/Modal";

import EmailListItem from "./EmailListItem";
import { getAllCalvaryProfiles, getAllEmailLogs } from "./utils";
import config from "./config";
import InfoItem from "../../common/InfoItem";

const headerList = [
  { title: "No." },
  { title: "Subject" },
  { title: "Type" },
  { title: "Created At" },
  { title: "Status" },
];
class UpdateUsers extends PureComponent {
  constructor(props) {
    super(props);
    fba_screen_view("update users");
    console.log(props);

    // let propUser = this.props.location?.state?.user;
    let propUserId = this.props.location?.state?.userId;
    let currentUserRole = this.props.location?.state?.currentUserRole;

    if (!propUserId) {
      // no user id, go back to manage user page
      this.props.history.push(Routes.users.index.path);
    }

    //assign props values to state values
    this.state = {
      loading: true,
      isModified: false,

      showUserDocumentAction: false,
      user: null,

      // modal
      showEmailLogModal: false,
      loadingEmailLog: false,
      emailLogs: [],
      emailType: "",
      emailPagination: [],

      // email status
      emailVerified: "",

      // email verification
      showEmailVerificationModal: false,
      loadingGettingVerifiedLink: false,
      copiedVerificationLink: false,
      emailVerificationLink: "",
      getEmailVerificationErrorMessage: "",

      // reset password
      showResetPasswordModal: false,
      loadingGettingResetPasswordLink: false,
      copiedResetPasswordLink: false,
      resetPasswordLink: "",
      getResetPasswordErrorMessage: "",

      // change email address
      showChangeEmailModal: false,
      loadingUpdatingEmail: false,
      newEmailAddress: "",

      // logout out user
      loggingOutUser: false,

      // deleting user account
      deletingUser: false,

      // calvary profiles
      loadingCalvaryProfiles: true,
      calvaryProfiles: [],
      getCalvaryProfileErrorMessage: "",

      // current user
      userId: propUserId != null ? propUserId : "",
      currentUserRole: roleFormatter(currentUserRole),
      email: "",
      firstName: "",
      lastName: "",
      role: "",
      phone: "",
      status: null,

      fields: {},
      errors: [],
    };
  }

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

    firebase
      .auth()
      ?.currentUser?.getIdTokenResult?.()
      .then(({ claims }) => {
        if (claims.superadmin || claims.clinical) {
          this.setState({ showUserDocumentAction: true });
        }
      });

    // get user profile details
    this.getUserProfile();

    // get email verified status
    this.getUserEmailVerifiedStatus();

    // get calvary profiles
    this.getCalvaryProfiles();
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getUserProfile = () => {
    try {
      this.setState({ loading: true }, async () => {
        // get user profile
        const getUserRes = await getFirestoreDoc(
          config.userCollection,
          this.state.userId
        );

        if (getUserRes === ERROR_GETTING_DOCUMENT) {
          throw { message: "Something went wrong while getting user details." };
        }

        if (getUserRes === NO_MATCHING_DOCUMENTS) {
          throw { message: "User profile not found." };
        }

        this.setState({
          loading: false,
          user: getUserRes,
          email: getUserRes.email,
          firstName: capitalizeEachWord(getUserRes.firstName) || "",
          lastName: capitalizeEachWord(getUserRes.lastName) || "",
          role: getUserRes.role || "",
          phone: getUserRes.phone || "",
          status: _.isBoolean(getUserRes.status) ? getUserRes.status : false,
        });
      });
    } catch (error) {
      console.log("getUserProfile ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  getUserEmailVerifiedStatus = async () => {
    const checkEmailRes = await checkEmailStatusAP(this.state.userId);
    if (checkEmailRes.message === "OK") {
      this.setState({ emailVerified: checkEmailRes.results?.emailVerified });
    } else {
      this.setState({ emailVerified: "error" });
      swal("Error", checkEmailRes.message, "error");
    }
  };

  getCalvaryProfiles = async () => {
    const getCalvaryProfileRes = await getAllCalvaryProfiles({
      userId: this.state.userId,
    });

    if (getCalvaryProfileRes.status) {
      this.setState({
        loadingCalvaryProfiles: false,
        calvaryProfiles: getCalvaryProfileRes.data,
        getCalvaryProfileErrorMessage: "",
      });
    } else {
      this.setState({
        loadingCalvaryProfiles: false,
        getCalvaryProfileErrorMessage: getCalvaryProfileRes.message,
      });
    }
  };

  handleSave = () => {
    if (this.handleValidation()) {
      this.submitData();
    } else {
      let errorString = this.state.errors.join("\n");
      swal("Error", errorString, "error");
    }
  };

  handleChange = (event) => {
    this.state.isModified = true;
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({ errors: fields, [event.target.name]: event.target.value });
  };

  handleStatusChange = () => {
    this.state.isModified = true;
    let updatedStatus = !this.state.status;
    this.setState({ status: updatedStatus });
  };

  handleUserLogout = async () => {
    try {
      this.setState({ loggingOutUser: true }, async () => {
        const { userId } = this.state;
        const response = await clearUserTokens(userId);
        if (response.message !== "OK") {
          throw { message: response.message };
        }

        this.setState({ loggingOutUser: false });
        swal(
          "Success",
          "All devices belong to this user have been logout.",
          "success"
        );
      });
    } catch (error) {
      console.log("handleUserLogout= ~ error", error);
      this.setState({ loggingOutUser: false });
      swal("Error", error.message, "error");
    }
  };

  handleEmailTypeChange = (event) => {
    this.setState({ emailType: event.target.value }, () =>
      this.handleEmailLogs({ reset: true })
    );
  };

  handleEmailLogs = async ({ next = true, reset = false }) => {
    try {
      this.setState({ loadingEmailLog: true });

      let { emailPagination } = this.state;
      let nextState = { loadingEmailLog: false };
      let emailPaginationNextDoc;

      if (reset) {
        emailPagination = [];
      } else if (next) {
        emailPaginationNextDoc = emailPagination[emailPagination.length - 1];
      } else {
        emailPaginationNextDoc = emailPagination[emailPagination.length - 3];
        emailPagination = _.slice(
          emailPagination,
          0,
          emailPagination.length - 2
        );
      }

      const emailLogs = await getAllEmailLogs({
        email: this.state.email,
        type: this.state.emailType,
        nextDoc: emailPaginationNextDoc,
        limit: config.emailPagniationLimit,
      });

      if (!emailLogs.status) {
        throw { message: emailLogs.message };
      }

      if (emailLogs.data !== NO_MATCHING_DOCUMENTS) {
        const { documents, nextDoc } = emailLogs.data;
        nextState.emailLogs = documents;
        nextState.emailPagination = [...emailPagination, nextDoc];
      } else {
        nextState.emailLogs = [];
        nextState.emailPagination = [];
      }

      // update state
      this.setState(nextState);
    } catch (error) {
      console.log("handleEmailLogs ~ error", error);
      this.setState({ loadingEmailLog: false });
      swal("Error", error.message, "error");
    }
  };

  showEmailLogs = async () => {
    try {
      this.setState({ showEmailLogModal: true });
      if (_.isEmpty(this.state.emailLogs)) {
        this.handleEmailLogs({ next: true });
      }
    } catch (error) {
      console.log("showEmailLogs ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  renderTableData = () => {
    const startNumber =
      config.emailPagniationLimit * (this.state.emailPagination.length - 1) + 1;
    return this.state.emailLogs.map((item, index) => (
      <EmailListItem
        key={item.id}
        item={{ ...item, orderNumber: startNumber + index }}
      />
    ));
  };

  handleEmailPaginationNextBtn = () => {
    this.handleEmailLogs({ next: true });
  };

  handleEmailPaginationPreviousBtn = () => {
    this.handleEmailLogs({ next: false }); // false is flag to go to previous page
  };

  generateVerificationLink = (force = false) => {
    this.setState(
      {
        showEmailVerificationModal: true,
        loadingGettingVerifiedLink: true,
      },
      async () => {
        if (force === true || !this.state.emailVerificationLink) {
          // generate new verification link
          const getVerifiedLinkRes = await getEmailVerificationUrlAP(
            this.state.userId
          );
          if (getVerifiedLinkRes.message === "OK") {
            this.setState({
              loadingGettingVerifiedLink: false,
              emailVerificationLink: getVerifiedLinkRes.results.url,
              copiedVerificationLink: false,
            });
          } else {
            this.setState({
              loadingGettingVerifiedLink: false,
              emailVerificationLink: "",
              copiedVerificationLink: false,
              getEmailVerificationErrorMessage: getVerifiedLinkRes.message,
            });
          }
        } else {
          // show the existed link
          this.setState({
            loadingGettingVerifiedLink: false,
          });
        }
      }
    );
  };

  copyVerificationLink = () => {
    const { emailVerificationLink } = this.state;
    if (emailVerificationLink) {
    } else {
      this.setState({
        getEmailVerificationErrorMessage:
          "Something went wrong while copying the link. Please try again",
      });
    }
  };

  onCopyVerificationLink = () => {
    this.setState({ copiedVerificationLink: true });
  };

  closeEmailVerificationModal = () => {
    this.setState({
      showEmailVerificationModal: false,
      loadingGettingVerifiedLink: false,
    });
  };

  generateResetPasswordLink = (force = false) => {
    this.setState(
      {
        showResetPasswordModal: true,
        loadingGettingResetPasswordLink: true,
      },
      async () => {
        if (force === true || !this.state.resetPasswordLink) {
          // generate new verification link
          const getResetPasswordLinkRes = await getResetPasswordUrlAP(
            this.state.userId
          );
          if (getResetPasswordLinkRes.message === "OK") {
            this.setState({
              loadingGettingResetPasswordLink: false,
              resetPasswordLink: getResetPasswordLinkRes.results.url,
              copiedResetPasswordLink: false,
            });
          } else {
            this.setState({
              loadingGettingResetPasswordLink: false,
              resetPasswordLink: "",
              copiedResetPasswordLink: false,
              getResetPasswordErrorMessage: getResetPasswordLinkRes.message,
            });
          }
        } else {
          // show the existed link
          this.setState({
            loadingGettingResetPasswordLink: false,
          });
        }
      }
    );
  };

  copyResetPasswordLink = () => {
    const { resetPasswordLink } = this.state;
    if (resetPasswordLink) {
    } else {
      this.setState({
        getResetPasswordErrorMessage:
          "Something went wrong while copying the link. Please try again",
      });
    }
  };

  onCopyResetPasswordLink = () => {
    this.setState({ copiedResetPasswordLink: true });
  };

  closeResetPasswordModal = () => {
    this.setState({
      showResetPasswordModal: false,
      loadingGettingResetPasswordLink: false,
    });
  };

  showChangeEmailModal = () => this.setState({ showChangeEmailModal: true });

  handleChangeEmailAddress = async () => {
    try {
      const { email, newEmailAddress } = this.state;

      if (!newEmailAddress) {
        throw { message: "Please enter new email address." };
      }

      if (!validateEmail(newEmailAddress)) {
        throw {
          message:
            "Invalid email. Please enter email address in correct format.",
        };
      }

      if (isSameString(email, newEmailAddress)) {
        throw {
          message:
            "The new email address is the same as the current email address. Please enter new email address.",
        };
      }

      this.setState({ loadingUpdatingEmail: true });

      // update to new email address
      const response = await updateEmailAddressAP({
        userId: this.state.userId,
        email: newEmailAddress,
      });

      if (response.message !== "OK") {
        throw { message: response.message };
      }

      this.setState(
        {
          isModified: false,
          loadingUpdatingEmail: false,
          showChangeEmailModal: false,
          newEmailAddress: "",
        },
        () => {
          // reload the profile
          this.getUserProfile();

          // get email verified status
          this.getUserEmailVerifiedStatus();

          swal(
            "Email Update Success",
            `The email address has been sucessfully changed to ${newEmailAddress}. ${
              response.results?.isVerificationEmailSent
                ? "The user will receive the verification email in few seconds."
                : "Please generate email verification link to the user."
            }`,
            "success"
          );
        }
      );
    } catch (error) {
      console.log("handleChangeEmailAddress ~ error", error);
      this.setState({ loadingUpdatingEmail: false });
      swal("Error", error.message, "error");
    }
  };

  closeChangeEmailModal = () => {
    if (!this.state.loadingUpdatingEmail) {
      this.setState({ showChangeEmailModal: false });
    }
  };

  submitData = () => {
    this.setState({ loading: true }, async () => {
      const updateUserRes = await updateUser(
        this.state.userId,
        this.state.email,
        this.state.firstName,
        this.state.lastName,
        this.state.phone,
        this.state.role,
        this.state.status
      );
      if (updateUserRes.message === "OK") {
        this.setState({ loading: false, isModified: false }, () => {
          // get latest profile status
          this.getUserProfile();

          // get email verified status
          this.getUserEmailVerifiedStatus();
        });
        swal("success", "User updated sucessfully", "success");
      } else {
        this.setState({ loading: false });
        swal("Error", updateUserRes.message, "error");
      }
    });
  };

  handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    //First Name
    if (!this.state.firstName) {
      formIsValid = false;
      errors.push("First name cannot be empty");
    }

    if (typeof this.state.firstName !== "undefined") {
      if (!validateName(this.state.firstName)) {
        formIsValid = false;
        errors.push("First name must no contain any number or symbol");
      }
    }

    //Last Name
    if (!this.state.lastName) {
      formIsValid = false;
      errors.push("Last name cannot be empty");
    }

    if (typeof this.state.lastName !== "undefined") {
      if (!validateName(this.state.lastName)) {
        formIsValid = false;
        errors.push("Last name must no contain any number or symbol");
      }
    }

    //Email
    if (!this.state.email) {
      formIsValid = false;
      errors.push("Email cannot be empty");
    }

    if (typeof this.state.email !== "undefined") {
      if (!validateEmail(this.state.email)) {
        formIsValid = false;
        errors.push("Email is not valid");
      }
    }

    //Mobile No
    if (!this.state.phone) {
      formIsValid = false;
      errors.push("Mobile number cannot be empty");
    }

    if (typeof this.state.phone !== "undefined") {
      if (!this.state.phone.match(/^\d{10}$/)) {
        formIsValid = false;
        errors.push("Mobile number is not valid");
      }
    }

    this.state.errors = errors;
    return formIsValid;
  };

  deleteUserAccount = async () => {
    try {
      const { userId } = this.state;

      if (!userId) {
        swal(
          "Error",
          "Something went wrong. Please try again after refreshing the page.",
          "error"
        );
        return;
      }

      const willDelete = await swal({
        title: `Are you sure you want to remove ${this.state.firstName} ${this.state.lastName}?`,
        text: "Once deleted, you will not be able to recover this User",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });

      if (willDelete) {
        this.setState({ deletingUser: true }, async () => {
          const deleteUserRes = await deleteUser(userId);
          if (deleteUserRes.message === "OK") {
            this.setState({ deletingUser: false, isModified: false });
            await swal(
              "success",
              `${this.state.email} has been successfully deleted in the system.`,
              "success"
            );
            // delete success, go back to manage user page
            this.props.history.push(Routes.users.index.path);
          } else {
            throw { message: deleteUserRes.message };
          }
        });
      }
    } catch (error) {
      console.log("deleteUserAccount ~ error", error);
      this.setState({ deletingUser: false });
      swal("Error", error.message, "error");
    }
  };

  render() {
    const { loading, currentUserRole, calvaryProfiles } = this.state;
    const hasEmailLog = this.state.emailLogs.length > 0;

    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div className="section-body mt-3">
          <div className="row clearfix">
            {/* left panel */}
            <div className="col-lg-6 col-sm-12">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane active"
                    id="user-add"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="dimmer active">
                        {loading ? <div className="loader" /> : null}

                        <div
                          className={`card-body ${
                            loading ? "dimmer-content" : null
                          }`}
                        >
                          <div className="row clearfix">
                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group">
                                {/* list of user roles */}
                                <label className="form-label">Role Type</label>
                                {"superadmin" === currentUserRole ? (
                                  <select
                                    className="form-control custom-select show-tick"
                                    name="role"
                                    value={roleFormatter(this.state.role)}
                                    onChange={this.handleChange}
                                  >
                                    <option>Select Role Type</option>
                                    <option value={SUPER_ADMIN}>
                                      {USER_ROLES[SUPER_ADMIN]}
                                    </option>
                                    <option value={ADMIN}>
                                      {USER_ROLES[ADMIN]}
                                    </option>
                                    <option value={CLINICAL}>
                                      {USER_ROLES[CLINICAL]}
                                    </option>
                                    <option value={USER}>
                                      {USER_ROLES[USER]}
                                    </option>
                                  </select>
                                ) : (
                                  <select
                                    className="form-control custom-select show-tick"
                                    name="role"
                                    value={roleFormatter(this.state.role)}
                                    onChange={this.handleChange}
                                  >
                                    <option>Select Role Type</option>
                                    <option value={ADMIN}>
                                      {USER_ROLES[ADMIN]}
                                    </option>
                                    <option value={CLINICAL}>
                                      {USER_ROLES[CLINICAL]}
                                    </option>
                                    <option value={USER}>
                                      {USER_ROLES[USER]}
                                    </option>
                                  </select>
                                )}
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="row clearfix">
                            {/* first name */}
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name *"
                                  name="firstName"
                                  value={this.state.firstName || ""}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            {/* last name */}
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={this.state.lastName || ""}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row clearfix">
                            {/* email address */}
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Email Address &nbsp;
                                  {_.isBoolean(this.state.emailVerified) ? (
                                    <span
                                      className={`text-${
                                        this.state.emailVerified
                                          ? "success"
                                          : "warning"
                                      }`}
                                    >
                                      {this.state.emailVerified ? (
                                        <>
                                          Verified&nbsp;
                                          <i className="fa fa-check-circle" />
                                        </>
                                      ) : (
                                        "Unverified"
                                      )}
                                    </span>
                                  ) : this.state.emailVerified !== "error" ? (
                                    <div className="spinner-border spinner-border-sm text-primary" />
                                  ) : null}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email Address *"
                                  name="email"
                                  disabled={true}
                                  value={this.state.email || ""}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            {/* mobile phone */}
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Mobile Phone
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mobile Phone"
                                  name="phone"
                                  value={this.state.phone || ""}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            {/* 
													<div className="col-md-4 col-sm-12">
														<div className="form-group">
															<label className="custom-switch m-0">
																<input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
																<span className="custom-switch-indicator" />
															</label>
														</div>
													</div> */}
                          </div>

                          <hr />

                          <div className="row clearfix">
                            {/* change user status switch */}
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  User Status
                                </label>
                                <Switch
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  onColor="#1f3886"
                                  height={20}
                                  width={40}
                                  onChange={this.handleStatusChange}
                                  checked={this.state.status || false}
                                />
                              </div>
                            </div>

                            {/* update button */}
                            <div className="col-md-6 col-sm-12">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.handleSave()}
                              >
                                <i className="fe fe-save mr-2" />
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end of left panel */}

            {/* middle panel */}
            <div className="col-lg-3 col-sm-12">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div className="card">
                    <div className="card-body">
                      {/* title */}
                      <h6>User Details</h6>
                      <hr />

                      {/* loading */}
                      {this.state.loadingCalvaryProfiles ? (
                        <div className="text-center">
                          <div className="spinner-border text-primary" />
                          <div>Loading profiles...</div>
                        </div>
                      ) : null}

                      {/* error message */}
                      {this.state.getCalvaryProfileErrorMessage ? (
                        <div className="text-red">
                          <i className="fa fa-exclamation-triangle" />
                          &nbsp;
                          {this.state.getCalvaryProfileErrorMessage}
                        </div>
                      ) : null}

                      {/* calvary profiles */}
                      {!this.state.loadingCalvaryProfiles ? (
                        _.isEmpty(calvaryProfiles) ? (
                          <div className="text-center">
                            <i className="fa fa-info-circle" />
                            &nbsp;
                            <span>Profile not found</span>
                          </div>
                        ) : (
                          _.map(calvaryProfiles, (profile, index) => (
                            <div key={profile.id}>
                              {/* full name */}
                              <InfoItem
                                key={profile.id}
                                label="Full Name"
                                value={capitalizeEachWord(
                                  `${profile.firstname}${
                                    profile.middlename &&
                                    profile.middlename !== "null"
                                      ? " " + profile.middlename + " "
                                      : " "
                                  }${profile.surname}`
                                )}
                              />

                              {/* date of birth */}
                              <InfoItem
                                label="Date of Birth"
                                value={profile.dob}
                              />

                              {/* patient ids */}
                              <InfoItem
                                label="Patient IDs"
                                value={profile.patientIds?.join(" | ")}
                              />

                              {/* last sign in time */}
                              <InfoItem
                                label="Last Sign In Time"
                                value={
                                  profile?.lastSignInTime
                                    ? convertToMomentInstance(
                                        profile.lastSignInTime
                                      ).format("D MMMM yyyy, h:mm a")
                                    : null
                                }
                              />

                              {/* notification status */}
                              <InfoItem
                                label="Notification"
                                value={
                                  profile.enableNotification
                                    ? "Enable"
                                    : "Disable"
                                }
                              />

                              {/* deceased status */}
                              {profile.deceased ? (
                                <InfoItem
                                  label="Deceased"
                                  value={
                                    <span>
                                      <b>{profile.deceased ? "Yes" : "-"}</b>
                                    </span>
                                  }
                                />
                              ) : null}

                              {/* profile status */}
                              <InfoItem
                                label="Status"
                                value={
                                  <span
                                    className={`txt-${
                                      profile.status ? "green" : "red"
                                    }`}
                                  >
                                    <b>
                                      {profile.status ? "Active" : "Inactive"}
                                    </b>
                                  </span>
                                }
                              />

                              {/* divider */}
                              {index < calvaryProfiles.length - 1 ? (
                                <hr />
                              ) : null}
                            </div>
                          ))
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end of middle panel */}

            {/* right panel */}
            <div className="col-lg-3 col-sm-12">
              <div className="container-fluid">
                <div className="tab-content mt-3">
                  <div className="card">
                    <div className="card-body">
                      {/* title */}
                      <h6>Control Actions</h6>
                      <hr />

                      {/* view email logs */}
                      <button
                        type="button"
                        className="btn btn-primary mb-10 mr-10"
                        onClick={this.showEmailLogs}
                      >
                        <i className="fa fa-envelope mr-2" />
                        View Email Logs
                      </button>

                      {/* generate email verification link */}
                      {_.isBoolean(this.state.emailVerified) &&
                      this.state.emailVerified === false ? (
                        <button
                          type="button"
                          className="btn btn-primary mb-10 mr-10"
                          onClick={this.generateVerificationLink}
                        >
                          <i className="fa fa-check-circle mr-2" />
                          Generate Verification Link
                        </button>
                      ) : null}

                      {/* generate reset password link */}
                      <button
                        type="button"
                        className="btn btn-primary mb-10 mr-10"
                        onClick={this.generateResetPasswordLink}
                      >
                        <i className="fa fa-lock mr-2" />
                        Generate Reset Password Link
                      </button>

                      {/* change email address */}
                      {_.isBoolean(this.state.emailVerified) &&
                      this.state.emailVerified === false ? (
                        <button
                          type="button"
                          className="btn btn-primary mb-10 mr-10"
                          onClick={this.showChangeEmailModal}
                        >
                          <i className="fa fa-at mr-2" />
                          Change Email Address
                        </button>
                      ) : null}

                      {/* logout user in all devices */}
                      {this.state.currentUserRole === SUPER_ADMIN ? (
                        <button
                          type="button"
                          className="btn btn-primary mb-10 mr-10"
                          onClick={this.handleUserLogout}
                        >
                          {this.state.loggingOutUser ? (
                            <div className="spinner-border spinner-border-sm mr-2" />
                          ) : (
                            <i className="fa fa-sign-out mr-2" />
                          )}
                          Logout User From All Devices
                        </button>
                      ) : null}

                      {/* delete account button */}
                      {this.state.currentUserRole === ADMIN &&
                      this.state.role === SUPER_ADMIN ? null : (
                        <button
                          type="button"
                          className="btn btn-primary mb-10 mr-10"
                          onClick={this.deleteUserAccount}
                        >
                          {this.state.deletingUser ? (
                            <div className="spinner-border spinner-border-sm mr-2" />
                          ) : (
                            <i className="fa fa-trash-o mr-2" />
                          )}
                          Delete Account
                        </button>
                      )}

                      {/* documents section */}
                      {this.state.showUserDocumentAction ? (
                        <>
                          <h6 className="mt-5">User Document Actions</h6>
                          <hr />

                          {/* create document */}
                          <Link
                            className="btn btn-primary mb-10 mr-10"
                            to={{
                              pathname: "/documents-create-new",
                              state: { type: USER_TYPE, user: this.state.user },
                            }}
                          >
                            <i className="icon-plus mr-2" />
                            Create User Document
                          </Link>

                          {/* manage document */}
                          <Link
                            className="btn btn-primary mb-10 mr-10"
                            to={{
                              pathname: "/documents-education",
                              state: { type: USER_TYPE, user: this.state.user },
                            }}
                          >
                            <i className="icon-drawer mr-2" />
                            Manage User Document
                          </Link>
                        </>
                      ) : null}

                      <div />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end of right panel */}
          </div>
        </div>

        {/* email log modal */}
        <Modal
          isOpen={this.state.showEmailLogModal}
          onRequestClose={() => this.setState({ showEmailLogModal: false })}
        >
          <Table
            loading={this.state.loadingEmailLog}
            header={
              <>
                <h3 className="card-title">Email Logs</h3>
                <div className="card-options">
                  <div className="form-group">
                    {/* list of user roles */}
                    <label className="form-label text-white">Email Type</label>
                    <select
                      className="form-control custom-select show-tick"
                      value={this.state.emailType}
                      onChange={this.handleEmailTypeChange}
                    >
                      <option value="">All</option>
                      <option value={EMAIL_VERIFICATION}>
                        {EMAIL_VERIFICATION}
                      </option>
                      <option value={RESET_PASSWORD}>{RESET_PASSWORD}</option>
                    </select>
                  </div>
                </div>
              </>
            }
            verticalScroll={true}
            showBorder={true}
            headerList={headerList}
            hasContent={hasEmailLog}
            hidePagination={false}
            hasPrevious={this.state.emailPagination.length > 1}
            hasNext={
              this.state.emailPagination[this.state.emailPagination.length - 1]
            }
            onNextClick={this.handleEmailPaginationNextBtn}
            onPreviousClick={this.handleEmailPaginationPreviousBtn}
          >
            {hasEmailLog ? this.renderTableData() : null}
          </Table>
        </Modal>

        {/* generate verify link modal */}
        <Modal
          isOpen={this.state.showEmailVerificationModal}
          onRequestClose={this.closeEmailVerificationModal}
        >
          <div className="modal-content">
            {/* header */}
            <div className="modal-header">
              <h5 className="modal-title">Generate Verification Link</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeEmailVerificationModal}
              />
            </div>

            {/* body */}
            <div className="modal-body text-center">
              <div className="card-body">
                {this.state.loadingGettingVerifiedLink ? (
                  <>
                    <div className="spinner-border text-primary" />
                    <div>Generating Link...</div>
                  </>
                ) : null}

                {!this.state.loadingGettingVerifiedLink ? (
                  this.state.emailVerificationLink ? (
                    // success, show the link
                    <CopyToClipboard
                      text={this.state.emailVerificationLink}
                      onCopy={this.onCopyVerificationLink}
                    >
                      <button
                        className="btn-clipboard"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Tooltip on bottom"
                        onClick={this.copyVerificationLink}
                      >
                        <span>
                          {this.state.copiedVerificationLink
                            ? "Copied!"
                            : "Click here to copy verification link"}
                        </span>
                        <i className="fa fa-copy" />
                      </button>
                    </CopyToClipboard>
                  ) : (
                    // error message
                    <p className="text-red">
                      {this.state.getEmailVerificationErrorMessage ? (
                        <b>Error:</b>
                      ) : null}
                      &nbsp;
                      {this.state.getEmailVerificationErrorMessage}
                    </p>
                  )
                ) : null}
              </div>
            </div>

            {/* footer */}
            {!this.state.loadingGettingVerifiedLink ? (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    this.state.emailVerificationLink &&
                    !this.state.copiedVerificationLink
                  }
                  onClick={() => this.generateVerificationLink(true)}
                >
                  {this.state.emailVerificationLink
                    ? "Generate new link"
                    : "Try again"}
                </button>
              </div>
            ) : null}
          </div>
        </Modal>

        {/* generate reset password link modal */}
        <Modal
          isOpen={this.state.showResetPasswordModal}
          onRequestClose={this.closeResetPasswordModal}
        >
          <div className="modal-content">
            {/* header */}
            <div className="modal-header">
              <h5 className="modal-title">Generate Reset Password Link</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeResetPasswordModal}
              />
            </div>

            {/* body */}
            <div className="modal-body text-center">
              <div className="card-body">
                {this.state.loadingGettingResetPasswordLink ? (
                  <>
                    <div className="spinner-border text-primary" />
                    <div>Generating Link...</div>
                  </>
                ) : null}

                {!this.state.loadingGettingResetPasswordLink ? (
                  this.state.resetPasswordLink ? (
                    // success, show the link
                    <CopyToClipboard
                      text={this.state.resetPasswordLink}
                      onCopy={this.onCopyResetPasswordLink}
                    >
                      <button
                        className="btn-clipboard"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Tooltip on bottom"
                        onClick={this.copyResetPasswordLink}
                      >
                        <span>
                          {this.state.copiedResetPasswordLink
                            ? "Copied!"
                            : "Click here to copy reset password link"}
                        </span>
                        <i className="fa fa-copy" />
                      </button>
                    </CopyToClipboard>
                  ) : (
                    // error message
                    <p className="text-red">
                      {this.state.getResetPasswordErrorMessage ? (
                        <b>Error:</b>
                      ) : null}
                      &nbsp;
                      {this.state.getResetPasswordErrorMessage}
                    </p>
                  )
                ) : null}
              </div>
            </div>

            {/* footer */}
            {!this.state.loadingGettingResetPasswordLink ? (
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={
                    this.state.resetPasswordLink &&
                    !this.state.copiedResetPasswordLink
                  }
                  onClick={() => this.generateResetPasswordLink(true)}
                >
                  {this.state.resetPasswordLink
                    ? "Generate new link"
                    : "Try again"}
                </button>
              </div>
            ) : null}
          </div>
        </Modal>

        {/* change email address modal */}
        <Modal
          isOpen={this.state.showChangeEmailModal}
          onRequestClose={this.closeChangeEmailModal}
        >
          <div className="modal-content">
            {/* header */}
            <div className="modal-header">
              <h5 className="modal-title">Change Email Address</h5>

              {this.state.loadingUpdatingEmail ? null : (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeChangeEmailModal}
                />
              )}
            </div>

            {/* body */}
            <div className="modal-body">
              {/* existing email address */}
              <div className="form-group">
                <label className="form-label">Current Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Current Email Address"
                  disabled={true}
                  value={this.state.email}
                />
              </div>

              {/* new email address */}
              <div className="form-group">
                <label className="form-label">New Email Address *</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="New Email Address *"
                  name="newEmailAddress"
                  disabled={this.state.loadingUpdatingEmail}
                  value={this.state.newEmailAddress}
                  onChange={
                    !this.state.loadingUpdatingEmail ? this.handleChange : null
                  }
                />
              </div>
            </div>

            {/* footer */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                disabled={this.state.loadingUpdatingEmail}
                onClick={
                  this.state.loadingUpdatingEmail
                    ? null
                    : this.handleChangeEmailAddress
                }
              >
                {this.state.loadingUpdatingEmail ? (
                  <div className="spinner-border spinner-border-sm" />
                ) : (
                  "Update Email"
                )}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default UpdateUsers;

import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <div className="section-body">
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12"></div>
                <div className="col-md-6 col-sm-12 text-md-right"></div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

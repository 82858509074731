import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

import {
  getUserAdmissions,
  getGeneralLearn,
  getUserLearns,
  getAllCalvaryProfiles,
  getAllHospitals,
  getSiteSpecificLearns,
  getUserByEmail,
} from "./utils";
import { deleteLearnFolder } from "../../../api/documents";

import {
  GLOBAL_TYPE,
  SITE_SPECIFIC_TYPE,
  USER_TYPE,
} from "../../../utils/constants";

import Table from "../../common/table";
import config from "./config";
import { capitalizeEachWord, validateEmail } from "../../../utils/helpers";

const headerList = [
  { title: "Folder Name" },
  { title: "Description" },
  { title: "Files" },
  { title: "Action", className: "w100" },
];

const initialState = {
  loading: false,

  // for dropdown
  types: [GLOBAL_TYPE, SITE_SPECIFIC_TYPE, USER_TYPE],

  // selected values
  selectedType: "",
  selectedHospitalId: "",
  selectedUser: null,
  selectedEmailAddress: "",
  selectedProfile: null,
  selectedAdmission: null,
  documentId: "",

  // list of data
  hospitals: [],
  profiles: [],
  admissions: [],

  // list of folder information
  folders: [],
};

class Education extends Component {
  state = initialState;

  componentDidMount() {
    const location = this.props.history?.location || {};

    // check payload from routes
    if (
      !_.isEmpty(location) &&
      location?.state?.type &&
      location?.state?.user
    ) {
      this.setState(
        {
          selectedType: location?.state?.type,
          selectedEmailAddress: location?.state?.user?.email,
        },
        () => this.handleUserChange(location?.state?.user)
      );
    }
  }

  handleTypeChange = async (event) => {
    this.setState({ loading: true });
    const { value } = event.target;
    let newState = {
      hospitals: [],
      profiles: [],
      admissions: [],
      folders: [],
      selectedType: value,
      selectedHospitalId: "",
      selectedUser: null,
      selectedEmailAddress: "",
      selectedProfile: null,
      selectedAdmission: null,
      documentId: "",
      loading: false,
    };
    if (value === GLOBAL_TYPE) {
      // get the global folder for education
      const getFolderRes = await getGeneralLearn();
      if (getFolderRes.status) {
        newState.folders = getFolderRes.data[0]?.folders || [];
        newState.documentId = getFolderRes.data[0]?.id;
      } else {
        swal("Error", getFolderRes.message, "error");
      }
    } else if (value === SITE_SPECIFIC_TYPE) {
      // get all hospitals
      const getHospitalRes = await getAllHospitals();
      if (getHospitalRes.status) {
        newState.hospitals = getHospitalRes.data;
      } else {
        swal("Error", getHospitalRes.message, "error");
      }
    }

    this.setState(newState);
  };

  handleHospitalChange = async (event) => {
    const hospitalId = event.target.value;
    this.setState({ loading: true, selectedHospitalId: hospitalId });

    let newState = { loading: false };

    // get learn folder by hospital id
    const getLearnRes = await getSiteSpecificLearns({ locationId: hospitalId });
    if (getLearnRes.status) {
      newState.folders = getLearnRes.data[0]?.folders || [];
      newState.documentId = getLearnRes.data[0]?.id;
    } else {
      swal("Error", getLearnRes.message, "error");
    }

    this.setState(newState);
  };

  handleUserChange = async (user) => {
    this.setState({ loading: true, selectedUser: user });

    let newState = {
      loading: false,
      selectedProfile: null,
      selectedAdmission: null,
    };

    // get profile by userId
    const getCalvaryProfileRes = await getAllCalvaryProfiles(user.id);
    if (getCalvaryProfileRes.status) {
      newState.profiles = getCalvaryProfileRes.data;
    } else {
      swal("Error", getCalvaryProfileRes.message, "error");
    }

    this.setState(newState);
  };

  handleProfileChange = async (event) => {
    const profile = JSON.parse(event.target.value);
    this.setState({ loading: true, selectedProfile: profile });

    let newState = { loading: false, selectedAdmission: null };

    // get admission by userId
    const getAdmissionRes = await getUserAdmissions(
      profile.id,
      this.state.selectedUser.id
    );
    if (getAdmissionRes.status) {
      newState.admissions = getAdmissionRes.data;
    } else {
      swal("Error", getAdmissionRes.message, "error");
    }

    this.setState(newState);
  };

  handleAdmissionChange = async (event) => {
    const admission = JSON.parse(event.target.value);
    this.setState({ loading: true, selectedAdmission: admission });

    const { selectedUser: user, selectedProfile: profile } = this.state;
    let newState = { loading: false };

    // get learn folder by admissionId and userId
    const getLearnRes = await getUserLearns({
      admissionId: admission.id,
      userId: user.id,
      calvaryProfileId: profile.id,
    });
    if (getLearnRes.status) {
      newState.folders = getLearnRes.data[0]?.folders || [];
      newState.documentId = getLearnRes.data[0]?.id;
    } else {
      swal("Error", getLearnRes.message, "error");
    }

    this.setState(newState);
  };

  handleEmailKeyPress = (event) => {
    if (!this.state.selectedUser && event.keyCode === 13) {
      // The Enter key (key code 13) was pressed
      this.searchEmailAddress();
    }
  };

  searchEmailAddress = async () => {
    const { selectedEmailAddress } = this.state;

    if (!selectedEmailAddress) {
      swal("Error", "Please enter user email address.", "error");
      return;
    } else if (!validateEmail(selectedEmailAddress)) {
      swal("Error", "Please enter a valid email address", "error");
      return;
    }

    // query by email address
    const getUserRes = await getUserByEmail(selectedEmailAddress);

    if (!getUserRes.status) {
      swal("Error", "Something went wrong.", "error");
      return;
    }

    const { data } = getUserRes;

    if (!data) {
      swal("Error", "User does not exist.", "error");
      return;
    }

    this.handleUserChange(data);
  };

  clearSelectedUser = () => {
    this.setState({
      hospitals: [],
      profiles: [],
      admissions: [],
      folders: [],
      selectedHospitalId: "",
      selectedUser: null,
      selectedEmailAddress: "",
      selectedProfile: null,
      selectedAdmission: null,
      documentId: "",
      loading: false,
    });
  };

  deleteFolder = async (folder) => {
    const willDelete = await swal({
      title: `Are you sure you want to remove "${folder.name}"?`,
      text: "Once deleted, you will not be able to recover this folder",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      this.setState({ loading: true });
      const deleteFolderRes = await deleteLearnFolder({
        type: this.state.selectedType,
        documentId: this.state.documentId,
        folderId: folder.id,
      });
      if (deleteFolderRes.message === "OK") {
        let { folders } = this.state;
        const newFolders = _.reject(folders, ["id", folder.id]);
        this.setState({ loading: false, folders: newFolders });
        swal("success", "Folder deleted sucessfully", "success");
      } else {
        this.setState({ loading: false });
        swal("Error", deleteFolderRes.message, "error");
      }
    }
  };

  renderTableHeader = () => {
    return (
      <>
        {/* type dropdown */}
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="form-group">
            <label className="form-label">Types</label>
            <select
              className="form-control custom-select show-tick"
              name="selectedType"
              value={this.state.selectedType}
              onChange={this.handleTypeChange}
            >
              <option value="">Select Type</option>
              {this.state.types.map((type, idx) => (
                <option key={idx} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>

        {this.state.selectedType === USER_TYPE ? (
          <>
            {/* users dropdown */}
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="form-group">
                <div className="form-group">
                  <label className="form-label">Users</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email address"
                      disabled={!!this.state.selectedUser}
                      onKeyDown={this.handleEmailKeyPress}
                      onChange={(event) =>
                        this.setState({
                          selectedEmailAddress: event.target.value,
                        })
                      }
                      value={this.state.selectedEmailAddress}
                    />
                    <span className="input-group-append">
                      <button
                        className={`btn btn-white`}
                        type="button"
                        onClick={
                          this.state.selectedUser
                            ? this.clearSelectedUser
                            : this.searchEmailAddress
                        }
                      >
                        {this.state.selectedUser ? "Clear" : "Search"}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* profile dropdown */}
            {this.state.selectedUser ? (
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Profiles</label>
                  <select
                    disabled={!this.state.selectedUser}
                    className="form-control custom-select show-tick"
                    onChange={this.handleProfileChange}
                  >
                    <option value="">Select Profile</option>
                    {this.state.profiles.map((profile) => (
                      <option key={profile.id} value={JSON.stringify(profile)}>
                        {capitalizeEachWord(
                          `${profile.firstname} ${profile.surname}`
                        )}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}

            {/* admission dropdown */}
            {this.state.selectedProfile ? (
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group">
                  <label className="form-label">Admissions</label>
                  <select
                    disabled={!this.state.selectedProfile}
                    className="form-control custom-select show-tick"
                    onChange={this.handleAdmissionChange}
                  >
                    <option value="">Select Admission</option>
                    {this.state.admissions.map((admission) => (
                      <option
                        key={admission.id}
                        value={JSON.stringify(admission)}
                      >
                        {admission?.specialty +
                          " (" +
                          admission?.siteCode +
                          ", " +
                          moment(admission?.admitdtm.toDate()).format(
                            "DD/MM/YYYY LT"
                          ) +
                          ")"}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {/* hospitals dropdown */}
        {this.state.selectedType === SITE_SPECIFIC_TYPE ? (
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="form-group">
              <label className="form-label">Hospitals</label>
              <select
                className="form-control custom-select show-tick"
                value={this.state.selectedHospitalId}
                onChange={this.handleHospitalChange}
              >
                <option value="">Select Hospital</option>
                {this.state.hospitals.map((hospital, idx) => (
                  <option key={idx} value={hospital.id}>
                    {hospital?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  renderTableData = () => {
    return this.state.folders.map((folder, idx) => (
      <tr key={idx}>
        <td>{folder.name}</td>
        <td>{folder.description}</td>
        <td>{folder.documents?.length}</td>
        <td>
          <Link
            to={{
              pathname: config.educationUpdatePath,
              state: {
                id: this.state.documentId,
                type: this.state.selectedType,
                user: this.state.selectedUser,
                admission: this.state.selectedAdmission,
                folder: folder,
              },
            }}
            className="btn btn-icon"
          >
            <i className="fa fa-edit" />
          </Link>
          <button
            type="button"
            onClick={() => this.deleteFolder(folder)}
            className="btn btn-icon js-sweetalert"
            title="Delete"
            data-type="confirm"
          >
            <i className="fa fa-trash-o text-danger" />
          </button>
        </td>
      </tr>
    ));
  };

  render() {
    const hasFolder = this.state.folders.length > 0;
    return (
      <Table
        loading={this.state.loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasFolder}
        hidePagination={true}
      >
        {hasFolder ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default Education;

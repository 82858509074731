import _ from "lodash";

import {
  getFirestoreMultipleDocs,
  getFirestoreMultipleDocsByPagination,
} from "../../../assets/js/firebase";

import {
  NO_MATCHING_DOCUMENTS,
  ERROR_GETTING_DOCUMENT,
  EMAIL_VERIFICATION,
  RESET_PASSWORD,
} from "../../../utils/constants";

import config from "../../../config/index.json";

export const getAllUsers = async ({
  currentUserRole,
  searchField,
  searchValue,
  startAt = null,
}) => {
  let users = [];
  try {
    let condition = [];
    if ("admin" === currentUserRole) {
      // query only user that has role as user and admin
      condition = [["role", "in", ["user", "admin", "clinical"]]];
    } else if ("superadmin" === currentUserRole) {
      // query every record from user collection
      condition = [];
    } else {
      return { status: false, data: users, message: "Forbidden Access" };
    }

    if (searchField && searchValue) {
      condition = [
        ...condition,
        [searchField, "==", searchValue.toLowerCase()],
      ];
    }

    const getUserRes = await getFirestoreMultipleDocs(
      config.userCollection,
      condition,
      "firstName",
      startAt,
      config.paginationLimit + 1
    );

    if (
      getUserRes === ERROR_GETTING_DOCUMENT ||
      getUserRes === NO_MATCHING_DOCUMENTS
    ) {
      return {
        status: getUserRes === NO_MATCHING_DOCUMENTS,
        data: users,
        message: getUserRes,
      };
    }

    return { status: true, data: getUserRes };
  } catch (error) {
    console.log("getAllUsers ~ error", error);
    return {
      status: false,
      data: users,
      message: "Something went wrong.",
    };
  }
};

export const getAllEmailLogs = async ({
  email,
  type = "",
  limit,
  nextDoc = null,
}) => {
  try {
    if (!email) {
      throw { message: "Missing email address." };
    }

    let condition = [["email", "==", email.trim().toLowerCase()]];
    if (type) {
      condition.push(["type", "==", type]);
    } else {
      condition.push(["type", "in", [EMAIL_VERIFICATION, RESET_PASSWORD]]);
    }

    const getEmailLogRes = await getFirestoreMultipleDocsByPagination(
      config.emailCollection,
      condition,
      {
        nextDoc,
        orderBy: ["createdAt desc"],
        limit,
      }
    );

    if (getEmailLogRes === ERROR_GETTING_DOCUMENT) {
      return {
        status: false,
        data: null,
        message: "Error while getting email logs.",
      };
    }

    return { status: true, data: getEmailLogRes };
  } catch (error) {
    console.log("getAllEmailLogs ~ error", error);
    return {
      status: false,
      data: null,
      message: "Something went wrong.",
    };
  }
};

export const getAllCalvaryProfiles = async ({ userId }) => {
  try {
    if (!userId) {
      throw { message: "Something went wrong." };
    }

    const getCalvaryProfileRes = await getFirestoreMultipleDocs(
      config.calvaryUserProfileCollection,
      [["userId", "==", userId]]
    );

    if (getCalvaryProfileRes === ERROR_GETTING_DOCUMENT) {
      return {
        status: false,
        data: [],
        message: "Error while getting email logs.",
      };
    }

    if (getCalvaryProfileRes === NO_MATCHING_DOCUMENTS) {
      return {
        status: true,
        data: [],
      };
    }

    return {
      status: true,
      data: _.sortBy(getCalvaryProfileRes, ["firstname"]),
    };
  } catch (error) {
    console.log("getAllEmailLogs ~ error", error);
    return {
      status: false,
      data: null,
      message: "Something went wrong.",
    };
  }
};

import firebase from "firebase/app";
import "firebase/auth";

import { USER_INFO } from "./constants";

const UID_TOKEN_KEY = "uid";
const TOKEN_MANAGER_KEY = "stsTokenManager";
const AUTH_TOKEN_KEY = "authInfo";
const USER_INFO_KEY = "userInfo";
const USER_STORE_KEY = "@user_storage_Key";

export const logout = () => {
  localStorage.removeItem(UID_TOKEN_KEY);
  localStorage.removeItem(TOKEN_MANAGER_KEY);
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USER_INFO_KEY);
  localStorage.removeItem(USER_STORE_KEY);
  sessionStorage.removeItem(USER_INFO);
  // window.location.reload();
};

export const isLogin = async () => {
  return firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      return true;
    } else {
      return false;
    }
  });
};

import React, { Component } from "react";
import swal from "sweetalert";

import { getAllHospital, deleteHospital, getItemListObj } from "./utils";
import config from "./config";
import ItemListItem from "./ItemListItem";

import SearchBox from "../../common/searchBox";
import Table from "../../common/table";

import globalConfig from "../../../config/index.json";

const headerList = [
  { title: "" },
  { title: "Name", className: "w60" },
  { title: "Status" },
  { title: "State" },
  { title: "Phone" },
  { title: "Action", className: "w100" },
];

class Index extends Component {
  state = {
    loading: true,
    itemData: [],
    searchField: "name",
    searchValue: "",

    // state for pagination
    hasNext: false,
    lastHospital: [],
  };

  componentDidMount() {
    //load list data for the component
    this.loadInitailData();
  }

  handleChange = (event) => {
    if (event.target.id === "searchKeyInput") {
      this.setState({ searchField: event.target.value });
    } else if (event.target.id === "searchValueInput") {
      this.setState({ searchValue: event.target.value });
    }
  };

  loadInitailData = async () => {
    try {
      const getHospitalRes = await getAllHospital();
      const { data: hospitals } = getHospitalRes;
      let hasNext = false;
      // handle data for pagination
      if (hospitals.length > globalConfig.paginationLimit) {
        hospitals.pop();
        hasNext = true;
      }

      this.setState({
        loading: false,
        itemData: hospitals,
        hasNext,
        lastHospital: [],
      });

      if (!getHospitalRes.status) {
        swal("Error", getHospitalRes.message, "error");
      }
    } catch (error) {
      this.setState({ loading: false });
      swal("Error!", "Something went wrong!", "error");
    }
  };

  handleHospitalCallback = ({ status, message, data: hospitals }) => {
    if (status) {
      let hasNext = false;
      // handle data for pagination
      if (hospitals.length > globalConfig.paginationLimit) {
        hospitals.pop();
        hasNext = true;
      }
      this.setState({ loading: false, itemData: hospitals, hasNext });
    } else {
      this.setState({ loading: false });
      swal("Error!", message, "error");
    }
  };

  handleRefresh = () => {
    this.setState({ searchValue: "", loading: true }, this.loadInitailData);
  };

  handleSearch = async (selectedFilter, searchValue) => {
    this.setState({
      searchField: selectedFilter,
      searchValue: searchValue,
      lastHospital: [],
    });
    if (searchValue !== "") {
      this.setState({ loading: true });
      const getHospitalRes = await getAllHospital({
        searchField: selectedFilter,
        searchValue,
      });
      this.handleHospitalCallback(getHospitalRes);
    } else {
      swal("Error!", "Search text can not be empty!", "warning");
    }
  };

  handleDelete = async (hospital) => {
    const willDelete = await swal({
      title: "Are you sure you want to remove " + hospital.name + " ?",
      text: "Once deleted, you will not be able to recover this Item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      const deleteHospitalRes = await deleteHospital(hospital.id);
      if (deleteHospitalRes.status) {
        this.loadInitailData();
        swal("success", "Hopsital deleted sucessfully", "success");
      } else {
        swal("Error", deleteHospitalRes.message, "error");
      }
    }
  };

  renderTableHeader = () => {
    return (
      <>
        <h3 className="card-title">Hospitals</h3>
        <SearchBox
          filterList={config.searchFilter}
          handleSearch={this.handleSearch}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  };

  renderTableData = () => {
    return this.state.itemData.map((item, index) => {
      let itemData = getItemListObj(item);
      return (
        <ItemListItem
          key={itemData.id}
          itemData={itemData}
          itemObj={item}
          itemId={itemData.id}
          onPressDelete={() => this.handleDelete(item)}
        />
      );
    });
  };

  handlePreviousBtn = async () => {
    this.setState({ loading: true });

    // pop twice to get the previous cursor
    this.state.lastHospital.pop();
    const lastDoc = this.state.lastHospital[this.state.lastHospital.length - 1];
    const getHospitalRes = await getAllHospital({
      searchField: this.state.searchField,
      searchValue: this.state.searchValue,
      startAt: lastDoc, // startAfter
    });
    this.handleHospitalCallback(getHospitalRes);
  };

  handleNextBtn = async () => {
    this.setState({ loading: true });

    const { itemData } = this.state;
    const lastDoc = itemData[itemData.length - 1].doc;

    // update state this way to previous re-render
    this.state.lastHospital.push(lastDoc);

    const getHospitalRes = await getAllHospital({
      searchField: this.state.searchField,
      searchValue: this.state.searchValue,
      startAt: lastDoc, // startAfter
    });
    this.handleHospitalCallback(getHospitalRes);
  };

  render() {
    const hasHospital = this.state.itemData.length > 0;

    return (
      <Table
        loading={this.state.loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasHospital}
        hidePagination={false}
        hasPrevious={this.state.lastHospital.length > 0}
        hasNext={this.state.hasNext}
        onNextClick={this.handleNextBtn}
        onPreviousClick={this.handlePreviousBtn}
      >
        {hasHospital ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default Index;

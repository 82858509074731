import axios, { errorHandler } from "./index";

export const createCMBSItem = async ({ cmbs, category1, category2 }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/education/createCMBSItemAP", {
      cmbs,
      category1,
      category2,
    });
    return response.data;
  } catch (error) {
    console.log("createCMBSItem ~ error", error);
    return errorHandler(error);
  }
};

export const updateCMBSItem = async ({ cmbs, category1, category2 }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/education/updateCMBSItemAP", {
      cmbs,
      category1,
      category2,
    });
    return response.data;
  } catch (error) {
    console.log("updateCMBSItem ~ error", error);
    return errorHandler(error);
  }
};

export const deleteCMBSItem = async ({ cmbsId }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/education/deleteCMBSItemAP", {
      cmbsId,
    });
    return response.data;
  } catch (error) {
    console.log("deleteCMBSItem ~ error", error);
    return errorHandler(error);
  }
};

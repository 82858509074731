import React from "react";

const InfoItem = ({ label, value, ...props }) => {
  return (
    <div className="mb-2">
      <b>
        <p className="mb-0">{label}:</p>
      </b>
      {value || "-"}
    </div>
  );
};

export default InfoItem;

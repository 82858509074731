import React, { Component } from "react";
import swal from "sweetalert";
import firebase from "firebase/app";
import { Prompt } from "react-router-dom";

import { createItem, getCreateReqObj } from "./utils";
import { MOMENT_TIME_ZONES } from "../../../utils/constants";

class AddItems extends Component {
  state = {
    loading: false,
    isModified: false,

    siteCode: "",
    episoftSiteCode: "",
    email: "",
    address: "",
    address2: "",
    geo: {
      lat: "",
      long: "",
    },
    latitude: "",
    longitude: "",
    name: "",
    postCode: "",
    state: "",
    timeZone: "",
    phone: "",
    suburb: "",
    type: "",
    website: "",
    cardImage: "",
    goShareIdentifier: "",
    shareCont: "",
    status: "",
    fields: {},
    errors: [],
  };

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleSave = () => {
    if (this.handleValidation()) {
      this.submitItemInfo();
    } else {
      let text = this.state.errors;
      let errorString = "";
      text.reduce((result, item) => {
        errorString = errorString + "\n" + item;
        return `${item}|`;
      }, "");
      swal("Error", errorString, "error");
    }
  };

  handleStatusChange = (event) => {
    this.state.isModified = true;
    let updatedStatus = !this.state.status;
    this.setState({ status: updatedStatus });
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({ errors: fields, status: updatedStatus });
  };

  handleChange = (event) => {
    this.state.isModified = true;
    if ("latitude" === event.target.name) {
      this.setState((prevState) => {
        let geo = Object.assign({}, prevState.geo);
        geo.lat = event.target.value;
        return { geo };
      });
    }

    if ("longitude" === event.target.name) {
      this.setState((prevState) => {
        let geo = Object.assign({}, prevState.geo);
        geo.long = event.target.value;
        return { geo };
      });
    }

    if ("state" === event.target.name) {
      this.state.timeZone = MOMENT_TIME_ZONES[event.target.value];
    }

    this.setState({ [event.target.name]: event.target.value });
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({ errors: fields });
  };

  submitItemInfo = () => {
    this.setState({ loading: true }, () => {
      const createdAt = firebase.firestore.Timestamp.now();
      let dataObj = getCreateReqObj([
        this.state.name,
        this.state.siteCode,
        this.state.episoftSiteCode,
        this.state.email,
        this.state.phone,
        this.state.address,
        this.state.address2,
        this.state.postCode,
        this.state.state,
        this.state.suburb,
        this.state.timeZone,
        this.state.website,
        this.state.type,
        this.state.geo,
        this.state.cardImage,
        this.state.goShareIdentifier,
        this.state.shareCont,
        this.state.status,
        createdAt,
        createdAt, // for updatedAt field
      ]);

      createItem(dataObj).then((result) => {
        if (result.status) {
          this.setState({ loading: false, isModified: false });
          swal("success", "Item created sucessfully", "success");
        } else {
          this.setState({ loading: false });
          swal("Error", "Something Went Wrong", "error");
        }
      });
    });
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = [];
    let formIsValid = true;

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors.push("Hospital name cannot be empty");
    }

    // site code
    if (!fields["siteCode"]) {
      formIsValid = false;
      errors.push("Hospital site code cannot be empty");
    }

    // episoft site code
    if (!fields["episoftSiteCode"]) {
      formIsValid = false;
      errors.push("Hospital episoft site code cannot be empty");
    }

    //address
    if (!fields["address"]) {
      formIsValid = false;
      errors.push("Address cannot be empty");
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors.push("Email cannot be empty");
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors.push("Email is not valid");
      }
    }

    //Mobile No
    if (!fields["phone"]) {
      formIsValid = false;
      errors.push("Phone cannot be empty");
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^\d{10}$/)) {
        formIsValid = false;
        errors.push("Phone is not valid");
      }
    }

    //post code
    if (!fields["postCode"]) {
      formIsValid = false;
      errors.push("post Code cannot be empty");
    }

    if (typeof fields["postCode"] !== "undefined") {
      if (!fields["postCode"].match(/^\d{4}$/)) {
        formIsValid = false;
        errors.push("Post Code is not valid");
      }
    }

    //suburb
    if (!fields["suburb"]) {
      formIsValid = false;
      errors.push("Suburb cannot be empty");
    }

    //website
    if (!fields["website"]) {
      formIsValid = false;
      errors.push("Website cannot be empty");
    }

    // goShareIdentifier
    if (!fields["goShareIdentifier"]) {
      formIsValid = false;
      errors.push("Go Share Identification cannot be empty");
    }

    // shareCont
    if (!fields["shareCont"]) {
      formIsValid = false;
      errors.push("Share Content cannot be empty");
    }

    // cardImage
    if (!fields["cardImage"]) {
      formIsValid = false;
      errors.push("Card Image cannot be empty");
    }

    this.state.errors = errors;
    return formIsValid;
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    <div className="dimmer active">
                      {loading ? <div className="loader" /> : null}

                      <div
                        className={`card-body ${
                          loading ? "dimmer-content" : null
                        }`}
                      >
                        <div className="row clearfix">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Type</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="type"
                                value={this.state.type}
                                onChange={this.handleChange}
                              >
                                <option>Select Type</option>
                                <option>Hospital</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row clearfix">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name *"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Site Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Site Code *"
                                name="siteCode"
                                value={this.state.siteCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">
                                Episoft Site Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Episoft Site Code *"
                                name="episoftSiteCode"
                                value={this.state.episoftSiteCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                name="address"
                                value={this.state.address}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address Line 2"
                                name="address2"
                                value={this.state.address2}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Suburb</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Suburb"
                                name="suburb"
                                value={this.state.suburb}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">State</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="state"
                                value={this.state.state}
                                onChange={this.handleChange}
                              >
                                <option>Select State</option>
                                <option>ACT</option>
                                <option>NSW</option>
                                <option>NT</option>
                                <option>QLD</option>
                                <option>SA</option>
                                <option>TAS</option>
                                <option>VIC</option>
                                <option>WA</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Post Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Post Code"
                                name="postCode"
                                value={this.state.postCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Geo - Latitude
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Latitude"
                                name="latitude"
                                value={this.state.geo.lat}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Geo - Longitude
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Longitude"
                                name="longitude"
                                value={this.state.geo.long}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Card Image</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Image URL https://..."
                                name="cardImage"
                                value={this.state.cardImage}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-lg-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Website</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Website"
                                name="website"
                                value={this.state.website}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Go Share Identifier
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Go Share Identifier"
                                name="goShareIdentifier"
                                value={this.state.goShareIdentifier}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <div className="card-options">
                                <label className="custom-switch m-0">
                                  <input
                                    type="checkbox"
                                    value={this.state.status}
                                    className="custom-switch-input"
                                    defaultChecked={this.state.status}
                                    onChange={this.handleStatusChange}
                                  />
                                  <span className="custom-switch-indicator" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Share Content
                              </label>
                              <textarea
                                type="text"
                                rows={5}
                                className="form-control"
                                placeholder="Share Content"
                                name="shareCont"
                                value={this.state.shareCont}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddItems;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "./config";

export default class ItemListItem extends Component {
  render() {
    const { doc, ...hospital } = this.props.itemData?.sourceObj;
    const { id, avatarName, subTitle, title, col3Data, col2Data, col1Data } =
      this.props.itemData;

    let statusValue = "";

    if (col1Data) {
      statusValue = "ACTIVE";
    } else {
      statusValue = "DISABLE";
    }

    return (
      <>
        <tr>
          <td className="width45">
            <span
              className="avatar avatar-blue"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Avatar Name"
            >
              {avatarName}
            </span>
          </td>
          <td>
            <h6 className="mb-0">{title}</h6>
            <span>{subTitle}</span>
          </td>
          <td>
            <span>{statusValue}</span>
          </td>
          <td>{col2Data}</td>
          <td>
            <span>{col3Data}</span>
          </td>
          <td>
            <Link
              to={{
                pathname: config.updatePath,
                state: {
                  item: hospital,
                  id: id,
                  search: false,
                },
              }}
              className="btn btn-icon"
            >
              <i className="fa fa-edit" />
            </Link>
            <button
              type="button"
              onClick={() => this.props.onPressDelete(id)}
              className="btn btn-icon js-sweetalert"
              title="Delete"
              data-type="confirm"
            >
              <i className="fa fa-trash-o text-danger" />
            </button>
          </td>
        </tr>
      </>
    );
  }
}

import _ from "lodash";
import { convertToRaw } from "draft-js";
import draftToHtmlPuri from "draftjs-to-html";
import { v4 as uuidv4 } from "uuid";

import config from "./config";
import appConfig from "../../../config";
import { store } from "../../../index";

import {
  createLearnFolderAPI,
  createGlobalLearnFolderAPI,
  createGlobalMyHealthFolderAPI,
  updateLearnFolderAPI,
  updateMyHealthFolderAPI,
  createSiteSpecificLearnFolderAPI,
} from "../../../api/documents";
import {
  firebase,
  getFirestoreMultipleDocs,
} from "../../../assets/js/firebase";
import {
  ERROR_GETTING_DOCUMENT,
  GENERAL_LEARN,
  GENERAL_MY_HEALTH,
  NO_MATCHING_DOCUMENTS,
  SITE_SPECIFIC_LEARN,
  SITE_SPECIFIC_TYPE,
  GLOBAL_TYPE,
  USER_TYPE,
  VIDEO,
} from "../../../utils/constants";
import { updateIdlerStatusAction } from "../../../actions/appAction";
import { lowerCaseFormatter } from "../../../utils/helpers";

const storage = firebase.app().storage(appConfig.bucketDocuments);

export const getAllUser = async () => {
  try {
    const getUserRes = await getFirestoreMultipleDocs(config.userCollection);

    if (getUserRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getUserRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    const orderedUsers = _.orderBy(getUserRes, (o) => o.email);

    return { status: true, data: orderedUsers };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getUserByEmail = async (email) => {
  try {
    let user = null;

    const getUserRes = await getFirestoreMultipleDocs(config.userCollection, [
      ["email", "==", lowerCaseFormatter(email)],
    ]);

    if (getUserRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getUserRes !== NO_MATCHING_DOCUMENTS) {
      user = getUserRes[0];
      delete user.doc;
    }

    return { status: true, data: user };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getAllCalvaryProfiles = async (userId) => {
  try {
    const getUserRes = await getFirestoreMultipleDocs(
      config.calvaryUserProfileCollection,
      [["userId", "==", userId]]
    );

    if (getUserRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getUserRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    const orderedUsers = _.orderBy(getUserRes, (o) => o.firstname);

    return { status: true, data: orderedUsers };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getAllHospitals = async () => {
  try {
    const getLocationRes = await getFirestoreMultipleDocs(
      config.locationCollection,
      [["status", "==", true]]
    );

    if (getLocationRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getLocationRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    const orderedLocations = _.orderBy(getLocationRes, (o) => o.name);

    return { status: true, data: orderedLocations };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getUserAdmissions = async (calvaryProfileId, userId) => {
  try {
    if (!userId) {
      return { status: false, message: "Missing userId param" };
    }

    const getAdmissionRes = await getFirestoreMultipleDocs(
      config.admissionCollection,
      [
        ["userId", "==", userId],
        ["calvaryProfileId", "==", calvaryProfileId],
        ["currentBookingStatus", "==", true],
      ]
    );

    if (getAdmissionRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getAdmissionRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    const orderedAdmissions = _.orderBy(getAdmissionRes, (o) => o.specialty);

    return { status: true, data: orderedAdmissions };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getGeneralMyHealth = async () => {
  try {
    const getMyHealthRes = await getFirestoreMultipleDocs(
      config.generalDocumentCollection,
      [["type", "==", GENERAL_MY_HEALTH]]
    );

    if (getMyHealthRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getMyHealthRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    return { status: true, data: getMyHealthRes };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getGeneralLearn = async () => {
  try {
    const getLearnRes = await getFirestoreMultipleDocs(
      config.generalDocumentCollection,
      [["type", "==", GENERAL_LEARN]]
    );

    if (getLearnRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getLearnRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    return { status: true, data: getLearnRes };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getUserLearns = async ({
  admissionId,
  userId,
  calvaryProfileId,
}) => {
  try {
    if (!(userId && admissionId)) {
      return { status: false, message: "Missing one or more params" };
    }

    const getLearnRes = await getFirestoreMultipleDocs(
      config.otherLearnCollection,
      [
        ["calvaryProfileId", "==", calvaryProfileId],
        ["userId", "==", userId],
        ["admissionId", "==", admissionId],
      ]
    );

    if (getLearnRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getLearnRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    return { status: true, data: getLearnRes };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getSiteSpecificLearns = async ({ locationId }) => {
  try {
    if (!locationId) {
      return { status: false, message: "Missing one or more params" };
    }

    const getLearnRes = await getFirestoreMultipleDocs(
      config.siteSpecificDocumentCollection,
      [
        ["type", "==", SITE_SPECIFIC_LEARN],
        ["locationId", "==", locationId],
      ]
    );

    if (getLearnRes === ERROR_GETTING_DOCUMENT) {
      return { status: false, message: ERROR_GETTING_DOCUMENT };
    }

    if (getLearnRes === NO_MATCHING_DOCUMENTS) {
      return { status: true, data: [] };
    }

    return { status: true, data: getLearnRes };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const createLearnFolder = async (
  {
    calvaryProfileId,
    userId,
    admissionId,
    folderName,
    folderDescription,
    documents,
  },
  callback
) => {
  try {
    var data = {
      calvaryProfileId,
      userId,
      admissionId,
      folderName,
      folderDescription,
    };

    let newDocumentInfo = [];

    // pause idler
    store.dispatch(updateIdlerStatusAction(true));

    let totalFileSize = 0;
    let uploadedBytes = 0;
    _.forEach(documents, (document) => {
      totalFileSize += document.file?.size || 0;
    });

    for (const document of documents) {
      const newDocInfo = {
        id: document.id,
        name: document.name,
        description: document.description,
        fileType: document.fileType,
        fileName: `${appConfig.folderLearnUser}/${userId}/${uuidv4()}.${
          document.extension
        }`,
        extension: document.extension,
      };

      if (document.fileType === VIDEO) {
        newDocInfo.videoTitle = document.videoTitle;
        newDocInfo.content = draftToHtmlPuri(
          convertToRaw(document.editorState.getCurrentContent())
        );
      }

      const uploadDocumentRes = await uploadFileToBucket(
        document.file,
        newDocInfo.fileName,
        uploadingProgressHelper(totalFileSize, uploadedBytes, callback)
      );

      uploadedBytes += document.file.size;

      if (uploadDocumentRes?.status) {
        newDocInfo.fileName = uploadDocumentRes.uri;
        newDocumentInfo.push(newDocInfo);
      } else {
        console.log("Error while uploading the following document", newDocInfo);
      }
    }

    data.documentInfo = JSON.stringify(newDocumentInfo);
    const response = await createLearnFolderAPI(data, callback);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    if (response.message === "OK") {
      return { status: true };
    } else {
      return { status: false, message: response.message };
    }
  } catch (error) {
    console.log("createLearnFolder ~ error", error);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    return { status: false, message: "Something went wrong" };
  }
};

const uploadingProgressHelper =
  (totalSizeInBytes, uploadedBytes, callback) => (bytesTransferred) => {
    var progress =
      ((uploadedBytes + bytesTransferred) / totalSizeInBytes) * 100;
    callback(progress > 100 ? 100 : progress);
  };

export const uploadFileToBucket = async (file, fileName, callback) => {
  var promise = new Promise(function (resolve, reject) {
    try {
      const storageRef = storage.ref();

      var fileRef = storageRef.child(fileName);

      var uploadTask = fileRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          callback(snapshot.bytesTransferred);
        },
        (error) => {
          console.log("error: ", error);
          // Handle unsuccessful uploads
          reject({
            status: false,
            fileName: fileName,
            uri: `${appConfig.bucketDocuments}/${fileName}`,
            file: file,
          });
        },
        () => {
          // Handle successful uploads on complete
          resolve({
            status: true,
            fileName: fileName,
            uri: `${appConfig.bucketDocuments}/${fileName}`,
          });
        }
      );
    } catch (error) {
      console.log("error", error);
      reject({
        status: false,
        fileName: fileName,
        uri: `${appConfig.bucketDocuments}/${fileName}`,
        file: file,
      });
    }
  });
  return promise;
};

export const createGlobalLearnFolder = async (
  { folderName, folderDescription, documents },
  callback
) => {
  try {
    var data = {
      folderName,
      folderDescription,
    };

    // pause idler
    store.dispatch(updateIdlerStatusAction(true));

    let totalFileSize = 0;
    let uploadedBytes = 0;
    _.forEach(documents, (document) => {
      totalFileSize += document.file?.size || 0;
    });

    let newDocumentInfo = [];
    for (const document of documents) {
      // add id to the document
      const newDocInfo = {
        id: document.id,
        name: document.name,
        description: document.description,
        fileType: document.fileType,
        fileName: `${appConfig.folderLearnGeneral}/${uuidv4()}.${
          document.extension
        }`,
        extension: document.extension,
      };

      // add content to document
      if (document.fileType === VIDEO) {
        newDocInfo.videoTitle = document.videoTitle;
        newDocInfo.content = draftToHtmlPuri(
          convertToRaw(document.editorState.getCurrentContent())
        );
      }

      // upload file document to storage
      const uploadDocumentRes = await uploadFileToBucket(
        document.file,
        newDocInfo.fileName,
        uploadingProgressHelper(totalFileSize, uploadedBytes, callback)
      );

      uploadedBytes += document.file.size;

      if (uploadDocumentRes?.status) {
        newDocInfo.fileName = uploadDocumentRes.uri;
        newDocumentInfo.push(newDocInfo);
      } else {
        console.log("Error while uploading the following document", newDocInfo);
      }
    }

    data.documentInfo = JSON.stringify(newDocumentInfo);
    const response = await createGlobalLearnFolderAPI(data, callback);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    if (response.message === "OK") {
      return { status: true };
    } else {
      return { status: false, message: response.message };
    }
  } catch (error) {
    console.log("createGlobalLearnFolder ~ error", error);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    return { status: false, message: "Something went wrong" };
  }
};

export const createGlobalMyHealthFolder = async (
  { folderName, folderDescription, documents },
  callback
) => {
  try {
    var formData = new FormData();
    formData.append("folderName", folderName);
    formData.append("folderDescription", folderDescription);

    let newDocumentInfo = [];
    const promises = [];
    for (const document of documents) {
      // add id to the document
      const newDocInfo = {
        id: document.id,
        name: document.name,
        description: document.description,
        fileType: document.fileType,
        extension: document.extension,
      };

      // add content to document
      let filePath = "";
      if (document.fileType === VIDEO) {
        newDocInfo.videoTitle = document.videoTitle;
        newDocInfo.content = draftToHtmlPuri(
          convertToRaw(document.editorState.getCurrentContent())
        );
        filePath = `${appConfig.folderMyHealthGeneral}/${uuidv4()}.mp4`;
      } else {
        filePath = `${appConfig.folderMyHealthGeneral}/${uuidv4()}.pdf`;
      }

      promises.push(
        await uploadFileToBucket(document.file, filePath, callback).then(
          (res) => {
            if (res && res.status && res.fileName) {
              newDocInfo.fileName = document.fileName;
              newDocumentInfo.push(newDocInfo);
            }
          }
        )
      );
    }

    Promise.all(promises).then(async () => {
      formData.append("documentInfo", JSON.stringify(newDocumentInfo));
      const response = await createGlobalMyHealthFolderAPI(formData, callback);

      if (response.message === "OK") {
        return { status: true };
      } else {
        return { status: false, message: response.message };
      }
    });
  } catch (error) {
    console.log("createGlobalMyHealthFolder ~ error", error);
    return { status: false, message: "Something went wrong" };
  }
};

export const createSiteSpecificLearnFolder = async (
  { locationId, folderName, folderDescription, documents },
  callback
) => {
  try {
    var data = {
      locationId,
      folderName,
      folderDescription,
    };

    // pause idler
    store.dispatch(updateIdlerStatusAction(true));

    let totalFileSize = 0;
    let uploadedBytes = 0;
    _.forEach(documents, (document) => {
      totalFileSize += document.file?.size || 0;
    });

    let newDocumentInfo = [];
    for (const document of documents) {
      // add id to the document
      const newDocInfo = {
        id: document.id,
        name: document.name,
        description: document.description,
        fileType: document.fileType,
        fileName: `${appConfig.folderLearnSiteSpecific}/${uuidv4()}.${
          document.extension
        }`,
        extension: document.extension,
      };

      // add content to document
      if (document.fileType === VIDEO) {
        newDocInfo.videoTitle = document.videoTitle;
        newDocInfo.content = draftToHtmlPuri(
          convertToRaw(document.editorState.getCurrentContent())
        );
      }

      // upload file document to storage
      const uploadDocumentRes = await uploadFileToBucket(
        document.file,
        newDocInfo.fileName,
        uploadingProgressHelper(totalFileSize, uploadedBytes, callback)
      );

      uploadedBytes += document.file.size;

      if (uploadDocumentRes?.status) {
        newDocInfo.fileName = uploadDocumentRes.uri;
        newDocumentInfo.push(newDocInfo);
      } else {
        console.log("Error while uploading the following document", newDocInfo);
      }
    }

    data.documentInfo = JSON.stringify(newDocumentInfo);
    const response = await createSiteSpecificLearnFolderAPI(data, callback);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    if (response.message === "OK") {
      return { status: true };
    } else {
      return { status: false, message: response.message };
    }
  } catch (error) {
    console.log("createSiteSpecificLearnFolder ~ error", error);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    return { status: false, message: "Something went wrong" };
  }
};

export const updateLearnFolder = async (
  {
    type,
    id,
    folderId,
    folderName,
    folderDescription,
    documents,
    deletedIds,
    userId,
  },
  callback
) => {
  try {
    var data = {
      type,
      id,
      folderId,
      folderName,
      folderDescription,
      deletedIds,
    };

    let addDocuments = [];
    let updateDocuments = [];

    // pause idler
    store.dispatch(updateIdlerStatusAction(true));

    let totalFileSize = 0;
    let uploadedBytes = 0;
    _.forEach(documents, (document) => {
      if (document.isNew) {
        totalFileSize += document.file?.size || 0;
      }
    });

    for (const document of documents) {
      if (document.isNew) {
        const newDocInfo = {
          id: document.id,
          name: document.name,
          description: document.description,
          fileType: document.fileType,
          extension: document.extension,
        };

        if (type === SITE_SPECIFIC_TYPE) {
          newDocInfo.fileName = `${
            appConfig.folderLearnSiteSpecific
          }/${uuidv4()}.${document.extension}`;
        } else if (type === GLOBAL_TYPE) {
          newDocInfo.fileName = `${appConfig.folderLearnGeneral}/${uuidv4()}.${
            document.extension
          }`;
        } else if (type === USER_TYPE) {
          newDocInfo.fileName = `${
            appConfig.folderLearnUser
          }/${userId}/${uuidv4()}.${document.extension}`;
        }

        // add content to document
        if (document.fileType === VIDEO) {
          newDocInfo.videoTitle = document.videoTitle;
          newDocInfo.content = draftToHtmlPuri(
            convertToRaw(document.editorState.getCurrentContent())
          );
        }

        // upload file document to storage
        const uploadDocumentRes = await uploadFileToBucket(
          document.file,
          newDocInfo.fileName,
          uploadingProgressHelper(totalFileSize, uploadedBytes, callback)
        );

        uploadedBytes += document.file.size;

        if (uploadDocumentRes?.status) {
          newDocInfo.fileName = uploadDocumentRes.uri;
          addDocuments.push(newDocInfo);
        } else {
          console.log(
            "Error while uploading the following document",
            newDocInfo
          );
        }
      } else {
        const updateDocInfo = { ...document };

        // add content to document
        if (document.fileType === VIDEO) {
          updateDocInfo.videoTitle = document.videoTitle;
          updateDocInfo.content = draftToHtmlPuri(
            convertToRaw(document.editorState.getCurrentContent())
          );
        }

        delete updateDocInfo.editorState;
        updateDocuments.push(updateDocInfo);
      }
    }

    data.addDocuments = JSON.stringify(addDocuments);
    data.updateDocuments = JSON.stringify(updateDocuments);
    const response = await updateLearnFolderAPI(data, callback);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    if (response.message === "OK") {
      return { status: true };
    } else {
      return { status: false, message: response.message };
    }
  } catch (error) {
    console.log("updateLearnFolder ~ error", error);

    // resume idler
    store.dispatch(updateIdlerStatusAction(false));

    return { status: false, message: "Something went wrong" };
  }
};

export const updateMyHealthFolder = async (
  { id, folderId, folderName, folderDescription, documents, deletedIds },
  callback
) => {
  try {
    var formData = new FormData();
    formData.append("id", id);
    formData.append("folderId", folderId);
    formData.append("folderName", folderName);
    formData.append("folderDescription", folderDescription);
    formData.append("deletedIds", JSON.stringify(deletedIds));

    let addDocuments = [];
    let updateDocuments = [];
    for (const document of documents) {
      if (document.isNew) {
        const newDocInfo = {
          id: document.id,
          name: document.name,
          description: document.description,
          fileType: document.fileType,
          extension: document.extension,
        };

        // add content to document
        if (document.fileType === VIDEO) {
          newDocInfo.videoTitle = document.videoTitle;
          newDocInfo.content = draftToHtmlPuri(
            convertToRaw(document.editorState.getCurrentContent())
          );
        }

        addDocuments.push(newDocInfo);
        formData.append("documents", document.file, document.id);
      } else {
        const updateDocInfo = { ...document };

        // add content to document
        if (document.fileType === VIDEO) {
          updateDocInfo.videoTitle = document.videoTitle;
          updateDocInfo.content = draftToHtmlPuri(
            convertToRaw(document.editorState.getCurrentContent())
          );
        }

        delete updateDocInfo.editorState;
        updateDocuments.push(updateDocInfo);
      }
    }
    formData.append("addDocuments", JSON.stringify(addDocuments));
    formData.append("updateDocuments", JSON.stringify(updateDocuments));

    const response = await updateMyHealthFolderAPI(formData, callback);

    if (response.message === "OK") {
      return { status: true };
    } else {
      return { status: false, message: response.message };
    }
  } catch (error) {
    console.log("updateMyHealthFolder ~ error", error);
    return { status: false, message: "Something went wrong" };
  }
};

import axios, { errorHandler } from "./index";

export const deleteAdmission = async (admissionId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/admission/deleteAdmission", {
      admissionId,
    });

    return response?.data;
  } catch (error) {
    console.log("deleteAdmission ~ error", error);
    return errorHandler(error);
  }
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import swal from "sweetalert";

import {
  getPhoneMFA,
  signIn,
  userSignOut,
  addMultifactor,
  sendEmailVerfication,
  fba_screen_view,
  fba_action_event,
  EMAIL_VERIFIED,
  EMAIL_NOT_VERIFIED,
  SIGNIN_ERROR,
  MULTI_FACTOR_REQUIRED,
} from "../../assets/js/firebase";
import { logout } from "../../utils/index";
import { validateEmail, hidePhoneDigits } from "../../utils/helpers";
import CalvaryIcon from "../../assets/images/calvary-icon.svg";

import AuthRightComponent from "./AuthRightComponent";
import { postLoginHandler } from "../../api/auth";
import Routes from "../../routes";

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      value: "",
      phone: "",
      errors: [],
      isRememberMe: false,
      siginInProgress: false,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    fba_screen_view("login");
  }

  componentDidMount() {
    // if user is login, redirect to the home dashboard
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this.props.history.push(Routes.dashboard.index.path);
    }

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
  }

  componentWillUnmount() {
    window.recaptchaVerifier = null;
  }

  handleCheckboxChange(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleChange(event) {
    //handle text input changes
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidation() {
    let errors = [];
    let formIsValid = true;

    const { email, password } = this.state;

    // Email
    if (!email) {
      formIsValid = false;
      errors.push("Email cannot be empty");
    } else if (!validateEmail(email)) {
      formIsValid = false;
      errors.push("Invalid email");
    }

    // Password
    if (!password) {
      formIsValid = false;
      errors.push("Password cannot be empty");
    }

    this.state.errors = errors;
    return formIsValid;
  }

  handleSubmit(event) {
    // handle button click event
    event.preventDefault();
    fba_action_event("button_click", "login");

    if (this.handleValidation()) {
      // perform sign in
      this.firebaseAction(
        this.state.email,
        this.state.password,
        this.state.isRememberMe
      );
    } else {
      // validation fail
      let errorString = this.state.errors.join("\n");
      swal("Error", errorString, "error");

      // empty the errors state
      this.state.errors = [];
    }
  }

  //signin function using backend
  // signinHandlerBackend() {
  //   login(this.state.email, this.state.password).then((results) => {
  //     if (results.message == "OK") {
  //       this.getUserBasicInfo(results.results.data.uid);
  //     } else if (results.message == "error") {
  //       let text =
  //         results.results.data.code == "auth/user-not-found"
  //           ? "User name is incorrect !"
  //           : "Something went wrong!";
  //       swal("Error", text, "error");
  //     } else {
  //       swal("Error", "Something went wrong! Please try agian.", "error");
  //     }
  //   });
  // }

  firebaseAction(email, password, isRememberMe) {
    //sign in using firebase
    // Disable button click
    this.setState({ siginInProgress: true });

    //firebase signin helper
    signIn(email, password, isRememberMe).then((response) => {
      if (response.status === EMAIL_VERIFIED) {
        //email verified, proceed for multifactor
        this.setState({ userData: response.data, siginInProgress: false });
        this.requestMultiFactor(response.data.user.uid);
      } else if (response.status === EMAIL_NOT_VERIFIED) {
        this.setState({ siginInProgress: false });
        // email is not verified, procced to verify email process
        this.handleEmailVerification();
      } else if (response.status === SIGNIN_ERROR) {
        //sign in error
        // Disable button click
        this.setState({ siginInProgress: false });
      } else if (response.status === MULTI_FACTOR_REQUIRED) {
        //credential authenticated, email is verfied, multifactor enable user. proceed to multifactor
        this.setState({ siginInProgress: false });
        this.handleMultiFactor(response.data, window.recaptchaVerifier);
      }
    });
  }

  requestMultiFactor(userId) {
    swal({
      title: "Sign In Successfully",
      text: "You have signed in successfully, next step is to add multi factor authentication to your account.",
      icon: "success",
      button: "Continue",
      closeOnClickOutside: false,
    }).then(async (cont) => {
      var clearToken = false; // use for indicate to clear token
      if (cont) {
        const getPhoneRes = await getPhoneMFA(userId);
        if (getPhoneRes.status) {
          const phone = getPhoneRes.data;
          // hide first 6 digits of phone number
          const hiddenDigit = hidePhoneDigits(phone);
          const isContinue = await swal({
            text: `A one time verification code will be sent to ${hiddenDigit}`,
            buttons: ["Cancel", "Continue"],
            closeOnClickOutside: false,
          });

          if (isContinue) {
            const addMFARes = await addMultifactor(
              window.recaptchaVerifier,
              phone
            );
            if (addMFARes.status) {
              this.onSuccessSignin(userId);
              this.setState({ siginInProgress: false });
            } else {
              addMFARes.showAlert &&
                (await swal("Error", addMFARes.message, "error"));
              // clear token and reload the page to get rid of recaptcha
              await userSignOut();
              logout();
            }
          } else {
            clearToken = true;
          }
        } else {
          // flag true to clear token
          clearToken = true;
          swal("Error", "Something went wrong", "error");
        }
      }

      if (clearToken) {
        // clear token
        await userSignOut();
        logout();
      }
    });
  }

  async handleMultiFactor(resolver, appVerifier) {
    var clearToken = false; // use for indicate to clear token

    try {
      const isContinue = await swal({
        text: `A one time verification code will be sent to ${resolver.hints[0].phoneNumber}`,
        buttons: ["Cancel", { text: "Continue", closeModal: false }],
        closeOnClickOutside: false,
      });

      if (isContinue) {
        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        var phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        // Send SMS verification code
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          appVerifier
        );

        let verificationCode;
        let numTries = 0;
        while (numTries < 3) {
          verificationCode = await swal({
            text: "Please enter your one time code below.",
            content: "input",
            buttons: ["Cancel", { text: "Continue", closeModal: false }],
            closeOnClickOutside: false,
          });

          if (verificationCode === "") {
            await swal("Error", "The one time code cannot be empty.", "error");
            continue;
          } else if (verificationCode === null) {
            // if cancel
            clearToken = true;
            break;
          }

          var cred = firebase.auth.PhoneAuthProvider.credential(
            verificationId,
            verificationCode
          );
          var multiFactorAssertion =
            firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

          try {
            // Complete sign-in.
            const userCredential = await resolver.resolveSignIn(
              multiFactorAssertion
            );
            // process login
            this.onSuccessSignin(userCredential.user.uid);
            // close modals, clear token then reload the page to refresh recaptcha
            swal.stopLoading();
            swal.close();
            break; // break the loop
          } catch (error) {
            numTries += 1;
            let message = "Something went wrong, please try again.";
            if (numTries >= 3) {
              clearToken = true;
              message = "You have reached maximum tries";
            } else if (error.code === "auth/invalid-verification-code") {
              message =
                "Invalid verification code. Please check and try again.";
            }
            await swal("Error", message, "error");
          }
        }
      } else {
        clearToken = true;
      }
    } catch (error) {
      await swal("Error", error.message || "Something went wrong", "error");
      swal.stopLoading();
      swal.close();
      clearToken = true;
    }

    if (clearToken) {
      // clear token
      await userSignOut();
      logout();
    }
  }

  onSuccessSignin = async () => {
    try {
      const handlePostLoginRes = await postLoginHandler();
      if (handlePostLoginRes.message !== "OK") {
        throw handlePostLoginRes.message;
      }

      // get user role
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult();

      // check if the role is allowed
      if (
        idTokenResult.claims.admin ||
        idTokenResult.claims.superadmin ||
        idTokenResult.claims.clinical
      ) {
        this.props.history.push("/");
      } else {
        userSignOut();
        swal(
          "Error",
          "Sorry, Your account is not allowed to this platform. Please contact support.",
          "error"
        );
      }
    } catch (error) {
      console.log("onSuccessSignin= ~ error", error);
      userSignOut();
      swal("Error", "Something went wrong!", "error");
    }
  };

  async handleEmailVerification() {
    //helper method to resend verification email
    const isConfirm = await swal({
      icon: "info",
      text: `Your email has not been verified. If you have not received a verification email in you inbox, please click resend.
      *Remember to check your spam/junk folder`,
      buttons: ["Cancel", { text: "Resend Email", closeModal: false }],
      closeOnClickOutside: false,
    });

    if (isConfirm) {
      const response = await sendEmailVerfication();
      if (!response.status) {
        await swal("Error", response.message, "error");
      }
      swal.stopLoading();
      swal.close();
    }

    // clear token
    await userSignOut();
    logout();
  }

  validateForm() {
    //basic form input validation
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  render() {
    return (
      <div className="auth" id="auth">
        <div id="recaptcha-container"></div>
        <div className="auth_left">
          <div className="card">
            <div className="text-center mb-2">
              <Link className="header-brand" to="/">
                <img
                  className="fe fe-command brand-logo"
                  src={CalvaryIcon}
                  width="50"
                  alt="Calvary Health Care"
                />
              </Link>
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="card-title">Login to your account</div>
                <div className="form-group">
                  <label className="form-label" htmlFor="exampleInputEmail1">
                    Email *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Password *
                    <Link className="float-right small" to="/forgotpassword">
                      Forgot password?
                    </Link>
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="custom-control custom-checkbox">
                    <input
                      checked={this.state.isRememberMe}
                      type="checkbox"
                      name="isRememberMe"
                      className="custom-control-input"
                      onChange={this.handleCheckboxChange}
                    />
                    <span className="custom-control-label">Remember me</span>
                  </label>
                </div> */}
                <div id="recaptcha-container"></div>
                <div className="form-footer">
                  <input
                    className="btn btn-primary btn-block"
                    id="sign-in-button"
                    type="submit"
                    value="Click to Login"
                    disabled={
                      !this.validateForm() || this.state.siginInProgress
                    }
                  />
                </div>
              </form>
            </div>
            {/* <div className="text-center text-muted">
              Don't have account yet? <Link to="/signup">Sign Up</Link>
            </div> */}
          </div>
        </div>
        <AuthRightComponent />
      </div>
    );
  }
}

import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { fba_screen_view } from "../../../assets/js/firebase";
import { USER_ROLES } from "../../../utils/constants";
import { capitalizeEachWord, userInfoHelper } from "../../../utils/helpers";
import Routes, { roleRoute } from "../../../routes/index";

class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      dashCards: [],
      firstName: "",
      lastName: "",
      role: "",
      value: "",
    };
    fba_screen_view("dashboard");
  }

  async componentDidMount() {
    // initial dash cards
    const DASH_CARDS = [
      {
        label: "Users",
        icon: "icon-users",
        to: Routes.users.index.path,
      },
      {
        label: "Hospitals",
        icon: "fa fa-building-o",
        to: Routes.hospitals.index.path,
      },
      // {
      //   label: "Admissions",
      //   icon: "fa fa-pencil-square-o",
      //   to: Routes.admissions.index.path,
      // },
      {
        label: "Documents",
        icon: "fa fa-file-o",
        to: Routes.documents.education.path,
      },
      {
        label: "CMBS",
        icon: "fa fa-bookmark-o",
        to: Routes.cmbs.index.path,
      },
      {
        label: "Notifications",
        icon: "icon-bell",
        to: Routes.notifications.add.path,
      },
      {
        label: "Logs",
        icon: "fa fa-server",
        to: Routes.logs.index.path,
      },
    ];

    // get userinfo
    const userInfo = await userInfoHelper(window);

    // get routes based on role
    const routes = [
      ...roleRoute[userInfo.role].routes,
      ...roleRoute.common.routes,
    ];

    // filter the dash card based on role
    let dashCards = [];
    _.map(DASH_CARDS, (card) => {
      const isFound = _.findIndex(routes, ["path", card.to]);
      if (isFound !== -1) {
        // add card if index is not -1
        dashCards.push(card);
      }
    });

    // update state with new value
    if (userInfo) {
      this.setState({
        dashCards,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        role: userInfo.role,
        loading: false,
      });
    } else {
      this.setState({ dashCards, loading: false });
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const { fixNavbar } = this.props;
    return (
      <>
        <div>
          <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div
                    className={`section-body ${
                      fixNavbar ? "mb-4 mt-3" : "mb-4"
                    }`}
                  >
                    <h4>
                      Welcome{" "}
                      {`${capitalizeEachWord(
                        this.state.firstName
                      )} ${capitalizeEachWord(this.state.lastName)}!`}
                    </h4>
                    <small>
                      {USER_ROLES[this.state.role] || "Admin"} Portal
                    </small>
                  </div>
                </div>
              </div>
              <div className="row clearfix">
                {/* Render all the dash cards */}
                {this.state.dashCards.map((card, idx) => (
                  <div key={idx} className="col-6 col-md-4 col-xl-2">
                    <div className="card">
                      <div className="card-body ribbon">
                        <Link to={card.to} className="my_sort_cut text-muted">
                          <i className={card.icon} />
                          <span>{card.label}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

let controller = "/cmbs";
let collection = "cmbsGoShareMapping";
let rootRouteName = "cmbs";
let navigationRoot = "/cmbs";
let rootPageTitle = "CMBS";

module.exports = {
  controller: controller,
  navigationRoot: navigationRoot,
  collection: collection,

  searchFilter: [
    { value: "cmbs", title: "CMBS Code" },
    { value: "goShareCategory", title: "Category1" },
    { value: "goShareCategory2", title: "Category2" },
  ],

  // EndPoints
  deleteEP: controller + "/delete",
  searchEP: controller + "/search",
  createEP: controller + "/create",
  updateEP: controller + "/update",
  getAllEP: controller + "/",

  // Routing values
  rootPageTitle: rootPageTitle,
  rootRouteName: rootRouteName,
  updatePath: navigationRoot + "-update",
  updatePathName: rootRouteName + "-update",
  updatePageTitle: "Update " + rootPageTitle,
  createPath: navigationRoot + "-create",
  createPathName: rootRouteName + "-create",
  createPageTitle: rootPageTitle + " Create",
};

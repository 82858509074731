import React, { Component } from "react";
import Pagination from "./pagination";

class Table extends Component {
  render() {
    return (
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <div className="tab-pane fade show active" role="tabpanel">
              <div className="card">
                {/* header */}
                {this.props.header ? (
                  <div className="card-header">{this.props.header}</div>
                ) : null}

                <div
                  className={`card-body ${
                    this.props.showBorder ? "table-border" : ""
                  }`}
                >
                  <div className="dimmer active">
                    {this.props.loading ? <div className="loader" /> : null}
                    <div
                      className={`table-responsive ${
                        this.props.loading ? "dimmer-content" : null
                      }`}
                    >
                      <table
                        className={`table table-striped ${
                          this.props.className
                        } table-vcenter text-nowrap ${
                          this.props.verticalScroll ? "table-vscroll" : ""
                        }`}
                      >
                        <thead>
                          <tr>
                            {this.props.headerList.map((header, idx) => (
                              <th key={idx} className={header.className}>
                                {header.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.children || (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {this.props.loading || this.props.hasContent ? null : (
                        <div className="text-center">No Results Found</div>
                      )}
                    </div>

                    {/* pagination */}
                    {this.props.hidePagination ? null : (
                      <Pagination
                        hasPrevious={this.props.hasPrevious}
                        hasNext={this.props.hasNext}
                        onNextClick={this.props.onNextClick}
                        onPreviousClick={this.props.onPreviousClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Table.defaultProps = {
  loading: false,
  header: <></>, // header html component
  headerList: [{}], // array of obj with title and className
  hasContent: false, // accept boolean if the table has content
  hidePagination: true, // hide the pagination section
  hasPrevious: false, // accept boolean to enable or disable the button
  hasNext: false, // accept boolean to enable or disable the button
  onNextClick: () => {}, // function to handle to the next page when clicked
  onPreviousClick: () => {}, // function to handle to the previous page when clicked
};

export default Table;

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import firebase from "firebase/app";

import Routes from "./routes/index";

import "./App.css";
import Layout from "./components/Shared/Layout";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import IdleSession from "./components/common/IdleSession";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // refresh token from being expired
          await firebase.auth()?.currentUser?.getIdToken(true);
        }
        setLoading(false);
      } catch (error) {
        console.log("onAuthStateChanged ~ error", error);
        setLoading(false);
      }
    });
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        // this is the default behavior
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <Switch>
        {/* Authentication Routes */}
        {_.map(Routes.auths, (route, idx) => (
          <PublicRoute
            exact
            key={idx}
            restricted={false}
            path={route.path}
            component={route.component}
          />
        ))}

        <PublicRoute
          restricted={false}
          path={Routes.others.welcome.path}
          component={Routes.others.welcome.component}
        />

        <PublicRoute
          restricted={false}
          path={Routes.others.notFound.path}
          component={Routes.others.notFound.component}
        />

        <PublicRoute
          restricted={false}
          path={Routes.others.internalServer.path}
          component={Routes.others.internalServer.component}
        />

        {/* Restricted Routes */}
        <PrivateRoute component={Layout} path="/" />
      </Switch>

      {/* component to handle session expired while inactive for period of time */}
      <IdleSession />
    </Router>
  );
};

export default App;

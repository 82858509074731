import React, { Component } from "react";
import { connect } from "react-redux";

class Header extends Component {
  render() {
    const { fixNavbar, darkHeader } = this.props;
    return (
      <div>
        <div
          id="page_top"
          className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
            darkHeader ? "top_dark" : ""
          }`}
        >
          <div className="container-fluid">
            <div className="page-header">
              <div className="left">
                <h1 className="page-title">{this.props.dataFromSubParent}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  fixNavbar: state.settings.isFixNavbar,
  darkHeader: state.settings.isDarkHeader,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);

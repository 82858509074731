import axios, { errorHandler } from "./index";

export const sendCart = async (userId, bundleId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/learn/sendCart", {
      recipient_id: userId,
      cart_id: bundleId,
    });
    return response.data;
  } catch (error) {
    console.log("sendCart ~ error", error);
    return errorHandler(error);
  }
};

export const getUserChart = async (goShareId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/learn/getUserCart", {
      data: goShareId,
    });
    return response.data;
  } catch (error) {
    console.log("getUserChart ~ error", error);
    return errorHandler(error);
  }
};

import _ from "lodash";
import React, { Component } from "react";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";

import { updateItem, getCreateReqObj } from "./utils";
import Routes from "../../../routes/index";
import { MOMENT_TIME_ZONES } from "../../../utils/constants";

class Update extends Component {
  constructor(props) {
    super(props);
    let propItem = this.props.location?.state?.item;
    let propItemId = this.props.location?.state?.id;

    //assign props values to state values
    this.state = {
      loading: false,
      isModified: false,

      email: propItem != null ? propItem.email : "",
      siteCode: propItem != null ? propItem.siteCode : "",
      episoftSiteCode: propItem != null ? propItem.episoftSiteCode : "",
      id: propItemId != null ? propItemId : "",
      address: propItem != null ? propItem.address : "",
      address2: propItem != null ? propItem.address2 : "",
      geo: {
        lat: propItem != null ? propItem.geo.lat : "",
        long: propItem != null ? propItem.geo.long : "",
      },
      name: propItem != null ? propItem.name : "",
      shortName: propItem != null ? propItem.shortName : "",
      postCode: propItem != null ? propItem.postCode : "",
      state: propItem != null ? propItem.state : "",
      timeZone: propItem != null ? propItem.timeZone : "",
      status: propItem != null ? propItem.status : "",
      phone: propItem != null ? propItem.phone : "",
      suburb: propItem != null ? propItem.suburb : "",
      type: propItem != null ? propItem.type : "",
      isPrivate: Boolean(propItem.private) ? propItem.private : "",
      website: propItem != null ? propItem.website : "",
      cardImage: propItem != null ? propItem.cardImage : "",
      cardURI: propItem != null ? propItem.cardURI : "",
      goShareIdentifier: propItem != null ? propItem.goShareIdentifier : "",
      visitingHrs: propItem != null ? propItem.visitingHrs : "",
      shareCont: propItem != null ? propItem.shareCont : "",
      verifyPatientEmail:
        propItem != null && _.isArray(propItem.verifyPatientEmail)
          ? propItem.verifyPatientEmail
          : [""],
      fields: {},
      errors: [],
    };

    if (propItem) {
      this.state.fields = {
        email: propItem != null ? propItem.email : "",
        siteCode: propItem != null ? propItem.siteCode : "",
        episoftSiteCode: propItem != null ? propItem.episoftSiteCode : "",
        id: propItemId != null ? propItemId : "",
        address: propItem != null ? propItem.address : "",
        address2: propItem != null ? propItem.address2 : "",
        geo: {
          latitude: propItem != null ? propItem.geo.lat : "",
          longitude: propItem != null ? propItem.geo.long : "",
        },
        name: propItem != null ? propItem.name : "",
        shortName: propItem != null ? propItem.shortName : "",
        postCode: propItem != null ? propItem.postCode : "",
        state: propItem != null ? propItem.state : "",
        timeZone: propItem != null ? propItem.timeZone : "",
        status: propItem != null ? propItem.status : "",
        phone: propItem != null ? propItem.phone : "",
        suburb: propItem != null ? propItem.suburb : "",
        type: propItem != null ? propItem.type : "",
        isPrivate: Boolean(propItem.private) ? propItem.private : "",
        website: propItem != null ? propItem.website : "",
        cardImage: propItem != null ? propItem.cardImage : "",
        cardURI: propItem != null ? propItem.cardURI : "",
        goShareIdentifier: propItem != null ? propItem.goShareIdentifier : "",
        visitingHrs: propItem != null ? propItem.visitingHrs : "",
        shareCont: propItem != null ? propItem.shareCont : "",
        verifyPatientEmail:
          propItem != null && _.isArray(propItem.verifyPatientEmail)
            ? propItem.verifyPatientEmail
            : [""],
      };
    }
  }

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleSave = () => {
    if (this.handleValidation()) {
      this.submitData();
    } else {
      let text = this.state.errors;
      let errorString = "";
      text.reduce((result, item) => {
        errorString = errorString + "\n" + item;
        return `${item}|`;
      }, "");

      swal("Error", errorString, "error");
    }
  };

  handleStatusChange = (event) => {
    this.state.isModified = true;

    let updatedStatus = !this.state.status;
    this.setState({ status: updatedStatus });
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;
    this.setState({ errors: fields, status: updatedStatus });
  };

  handleChange = (event) => {
    this.state.isModified = true;

    if ("latitude" === event.target.name) {
      this.setState((prevState) => {
        let geo = Object.assign({}, prevState.geo);
        geo.lat = event.target.value;
        return { geo };
      });
    }

    if ("longitude" === event.target.name) {
      this.setState((prevState) => {
        let geo = Object.assign({}, prevState.geo);
        geo.long = event.target.value;
        return { geo };
      });
    }

    if ("state" === event.target.name) {
      this.state.timeZone = MOMENT_TIME_ZONES[event.target.value];
    }

    let fields = this.state.fields;
    fields[event.target.name] = event.target.value;

    this.setState({ [event.target.name]: event.target.value, errors: fields });
  };

  handleVerifyEmailChange = (index) => (event) => {
    this.state.isModified = true;

    let { verifyPatientEmail } = this.state;
    verifyPatientEmail[index] = event.target.value;
    let fields = this.state.fields;
    fields["verifyPatientEmail"] = verifyPatientEmail;
    this.setState({
      verifyPatientEmail: [...verifyPatientEmail],
      errors: fields,
    });
  };

  addVerifyPatientEmail = () => {
    this.state.isModified = true;

    let { verifyPatientEmail } = this.state;
    if (verifyPatientEmail[verifyPatientEmail.length - 1]) {
      let fields = this.state.fields;
      fields["verifyPatientEmail"] = verifyPatientEmail;

      this.setState({
        verifyPatientEmail: [...verifyPatientEmail, ""],
        errors: fields,
      });
    }
  };

  deleteVerifyPatientEmail = (index) => () => {
    this.state.isModified = true;

    const { verifyPatientEmail } = this.state;
    _.pullAt(verifyPatientEmail, [index]);

    let fields = this.state.fields;
    fields["verifyPatientEmail"] = verifyPatientEmail;

    this.setState({
      errors: fields,
      verifyPatientEmail: _.isEmpty(verifyPatientEmail)
        ? [""]
        : [...verifyPatientEmail],
    });
  };

  submitData = () => {
    this.setState({ loading: true }, () => {
      let dataObj = getCreateReqObj([
        this.state.name,
        this.state.shortName,
        this.state.siteCode,
        this.state.episoftSiteCode,
        this.state.email,
        this.state.phone,
        this.state.address,
        this.state.address2,
        this.state.postCode,
        this.state.state,
        this.state.suburb,
        this.state.timeZone,
        this.state.website,
        this.state.type,
        this.state.isPrivate,
        this.state.geo,
        this.state.cardImage,
        this.state.cardURI,
        this.state.goShareIdentifier,
        this.state.visitingHrs,
        this.state.shareCont,
        this.state.verifyPatientEmail,
        this.state.status,
      ]);

      updateItem(this.state.id, dataObj).then((result) => {
        if (result.status) {
          this.setState({ loading: false, isModified: false });
          swal("success", "Saved sucessfully", "success").then(() => {
            this.props.history.push(Routes.hospitals.index.path);
          });
        } else {
          this.setState({ loading: false });
          swal("Error", result.message || "Something went wrong", "error");
        }
      });
    });
  };

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = [];
    let formIsValid = true;

    // type
    if (!fields["type"]) {
      formIsValid = false;
      errors.push("Type cannot be empty.");
    }

    // hospital type
    if (!Boolean(fields["isPrivate"])) {
      formIsValid = false;
      errors.push("Hospital type cannot be empty");
    }

    // name
    if (!fields["name"]) {
      formIsValid = false;
      errors.push("Hospital name cannot be empty");
    }

    // short name
    if (!fields["shortName"]) {
      formIsValid = false;
      errors.push("Hospital short name cannot be empty");
    }

    // site code
    if (!fields["siteCode"]) {
      formIsValid = false;
      errors.push("Site code cannot be empty");
    }

    // episoft site code
    if (!fields["episoftSiteCode"]) {
      formIsValid = false;
      errors.push("Episoft site code cannot be empty");
    }

    //address
    if (!fields["address"]) {
      formIsValid = false;
      errors.push("Address cannot be empty");
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors.push("Email cannot be empty");
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors.push("Email is not valid");
      }
    }

    //Mobile No
    if (!fields["phone"]) {
      formIsValid = false;
      errors.push("Phone cannot be empty");
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^\d{10}$/)) {
        formIsValid = false;
        errors.push("Phone is not valid");
      }
    }

    //post code
    if (!fields["postCode"]) {
      formIsValid = false;
      errors.push("post Code cannot be empty");
    }

    if (typeof fields["postCode"] !== "undefined") {
      if (!fields["postCode"].match(/^\d{4}$/)) {
        formIsValid = false;
        errors.push("Post Code is not valid");
      }
    }

    //suburb
    if (!fields["suburb"]) {
      formIsValid = false;
      errors.push("Suburb cannot be empty");
    }

    //website
    if (!fields["website"]) {
      formIsValid = false;
      errors.push("Website cannot be empty");
    }

    // goShareIdentifier
    if (!fields["goShareIdentifier"]) {
      formIsValid = false;
      errors.push("Go Share Identifier cannot be empty");
    }

    // shareCont
    if (!fields["shareCont"]) {
      formIsValid = false;
      errors.push("Share Content cannot be empty");
    }

    // cardImage
    if (!fields["cardImage"]) {
      formIsValid = false;
      errors.push("Card Image cannot be empty");
    }

    // cardURI
    if (!fields["cardURI"]) {
      formIsValid = false;
      errors.push("Card URI cannot be empty");
    }

    // verify patient email
    if (
      !_.isArray(fields["verifyPatientEmail"]) ||
      _.isEmpty(fields["verifyPatientEmail"])
    ) {
      formIsValid = false;
      errors.push("Verify patient email cannot be empty");
    }

    // visiting hours
    if (!fields["visitingHrs"]) {
      formIsValid = false;
      errors.push("Visiting Hours cannot be empty");
    }

    this.state.errors = errors;
    return formIsValid;
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    <div className="dimmer active">
                      {loading ? <div className="loader" /> : null}

                      <div
                        className={`card-body ${
                          loading ? "dimmer-content" : null
                        }`}
                      >
                        <div className="row clearfix">
                          {/* location type */}
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Type</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="type"
                                value={this.state.type}
                                onChange={this.handleChange}
                              >
                                <option value={""}>Select Type</option>
                                <option value={"Hospital"}>Hospital</option>
                              </select>
                            </div>
                          </div>

                          {/* hospital type */}
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Hospital Type
                              </label>
                              <select
                                className="form-control custom-select show-tick"
                                name="isPrivate"
                                value={this.state.isPrivate}
                                onChange={this.handleChange}
                              >
                                <option value={""}>Select Type</option>
                                <option value={true}>Private</option>
                                <option value={false}>Public</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row clearfix">
                          {/* name */}
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name *"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* short name */}
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Short Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Short Name *"
                                name="shortName"
                                value={this.state.shortName}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* site code */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Site Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Site Code *"
                                name="siteCode"
                                value={this.state.siteCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* episoft site code */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">
                                Episoft Site Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Episoft Site Code *"
                                name="episoftSiteCode"
                                value={this.state.episoftSiteCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* address 1 */}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                name="address"
                                value={this.state.address}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* address 2 */}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address Line 2"
                                name="address2"
                                value={this.state.address2}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* suburb */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Suburb</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Suburb *"
                                name="suburb"
                                value={this.state.suburb}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* state */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">State</label>
                              <select
                                className="form-control custom-select show-tick"
                                name="state"
                                value={this.state.state}
                                onChange={this.handleChange}
                              >
                                <option>Select State</option>
                                <option>ACT</option>
                                <option>NSW</option>
                                <option>NT</option>
                                <option>QLD</option>
                                <option>SA</option>
                                <option>TAS</option>
                                <option>VIC</option>
                                <option>WA</option>
                              </select>
                            </div>
                          </div>

                          {/* post code */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Post Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Post Code *"
                                name="postCode"
                                value={this.state.postCode}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* latitude */}
                          <div className="col-lg-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Geo - Latitude
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Latitude *"
                                name="latitude"
                                value={this.state.geo.lat}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* longitude */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Geo - Longitude
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Longitude *"
                                name="longitude"
                                value={this.state.geo.long}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* card image url */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Card Image</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Image URL https://... *"
                                name="cardImage"
                                value={this.state.cardImage}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* email */}
                          <div className="col-lg-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email *"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* phone number */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone *"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* card uri */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Card URI</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Card URI gs://... *"
                                name="cardURI"
                                value={this.state.cardURI}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* go share identifier */}
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Go Share Identifier
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Go Share Identifier *"
                                name="goShareIdentifier"
                                value={this.state.goShareIdentifier}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* website */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Website</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Website *"
                                name="website"
                                value={this.state.website}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* visiting hours */}
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Visiting Hours
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Visiting Hours *"
                                name="visitingHrs"
                                value={this.state.visitingHrs}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* share content */}
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Share Content
                              </label>
                              <textarea
                                type="text"
                                rows={5}
                                className="form-control"
                                placeholder="Share Content *"
                                name="shareCont"
                                value={this.state.shareCont}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* verify patient emails */}
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Verify Patient Emails
                              </label>

                              {_.isArray(this.state.verifyPatientEmail)
                                ? this.state.verifyPatientEmail?.map(
                                    (email, index) => {
                                      return (
                                        <div
                                          className="row-input-container mb-10"
                                          key={index}
                                        >
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Verify Patient Email *"
                                            value={email}
                                            onChange={this.handleVerifyEmailChange(
                                              index
                                            )}
                                          />

                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={this.deleteVerifyPatientEmail(
                                              index
                                            )}
                                          >
                                            <i className="fa fa-trash-o text-white" />
                                          </button>
                                        </div>
                                      );
                                    }
                                  )
                                : null}

                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.addVerifyPatientEmail}
                              >
                                <i className="fa fa-plus" /> Add
                              </button>
                            </div>
                          </div>

                          {/* status */}
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <div className="card-options">
                                <label className="custom-switch m-0">
                                  <input
                                    type="checkbox"
                                    value={this.state.status}
                                    className="custom-switch-input"
                                    defaultChecked={this.state.status}
                                    onChange={this.handleStatusChange}
                                  />
                                  <span className="custom-switch-indicator" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Update;

import _ from "lodash";
import React, { PureComponent } from "react";
import swal from "sweetalert";
import ReactJson from "react-json-view";

import { getAllLogs } from "./utils";
import config from "./config";
import ItemListItem from "./ItemListItem";

import SearchBox from "../../common/searchBox";
import Table from "../../common/table";

import globalConfig from "../../../config/index.json";
import { NO_MATCHING_DOCUMENTS } from "../../../utils/constants";
import Modal from "../../common/Modal";
import InfoItem from "../../common/InfoItem";
import { convertToMomentInstance } from "../../../utils/helpers";

const headerList = [
  { title: "NO." },
  { title: "Path" },
  { title: "Code" },
  { title: "User ID" },
  { title: "Messages" },
  { title: "Created At" },
  { title: "Action", className: "w100 text-center" },
];

const jsonContainerStyle = {
  fontSize: 12,
  width: 400,
  overflowWrap: "break-word",
};

class Index extends PureComponent {
  state = {
    loading: true,
    logs: [],
    searchField: "notEqualCode",
    searchValue: "",

    // state for pagination
    logPagination: [],

    // detail modal
    showLogModal: false,
    selectedLog: null,
  };

  componentDidMount() {
    //load list data for the component
    this.getAllLogs({ next: true });
  }

  handleChange = (event) => {
    if (event.target.id === "searchKeyInput") {
      this.setState({ searchField: event.target.value });
    } else if (event.target.id === "searchValueInput") {
      this.setState({ searchValue: event.target.value });
    }
  };

  getAllLogs = async ({ next = true, refresh = false, reset = false }) => {
    try {
      this.setState({ loading: true });

      let { logPagination } = this.state;
      let nextState = { loading: false };
      let logPaginationNextDoc;

      if (reset) {
        logPagination = [];
      } else if (refresh) {
        logPaginationNextDoc = logPagination[logPagination.length - 2];
        logPagination = _.slice(logPagination, 0, logPagination.length - 1);
      } else if (next) {
        logPaginationNextDoc = logPagination[logPagination.length - 1];
      } else {
        logPaginationNextDoc = logPagination[logPagination.length - 3];
        logPagination = _.slice(logPagination, 0, logPagination.length - 2);
      }

      const getAllLogsRes = await getAllLogs({
        searchField: this.state.searchField,
        searchValue: this.state.searchValue,
        nextDoc: logPaginationNextDoc,
      });

      if (!getAllLogsRes.status) {
        throw { message: getAllLogsRes.message };
      }

      if (getAllLogsRes.data !== NO_MATCHING_DOCUMENTS) {
        const { documents, nextDoc } = getAllLogsRes.data;
        nextState.logs = documents;
        nextState.logPagination = [...logPagination, nextDoc];
      } else {
        nextState.logs = [];
        nextState.logPagination = [];
      }

      // update state
      this.setState(nextState);
    } catch (error) {
      console.log("getAllLogs ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  handleRefresh = () => {
    this.setState({ searchValue: "" }, () => this.getAllLogs({ reset: true }));
  };

  handleSearch = async (selectedFilter, searchValue) => {
    this.setState(
      {
        searchField: selectedFilter,
        searchValue: searchValue,
      },
      () => {
        if (searchValue !== "") {
          this.getAllLogs({ reset: true });
        } else {
          swal("Error!", "Search text can not be empty!", "warning");
        }
      }
    );
  };

  renderTableHeader = () => {
    return (
      <>
        <h3 className="card-title">Database Logs</h3>
        <SearchBox
          filterList={config.searchFilter}
          handleSearch={this.handleSearch}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  };

  renderTableData = () => {
    const startNumber =
      globalConfig.paginationLimit * (this.state.logPagination.length - 1) + 1;

    return this.state.logs.map((item, index) => {
      return (
        <ItemListItem
          key={item.id}
          item={{ ...item, orderNumber: startNumber + index }}
          onPress={() => this.showLogModal(item)}
        />
      );
    });
  };

  handlePreviousBtn = async () => {
    this.getAllLogs({ next: false }); // false is flag to go to previous page
  };

  handleNextBtn = async () => {
    this.getAllLogs({ next: true });
  };

  showLogModal = (item) => {
    if (!_.isEmpty(item)) {
      this.setState({ showLogModal: true, selectedLog: item });
    } else {
      this.closeLogModal();
    }
  };

  closeLogModal = () => {
    this.setState({ showLogModal: false, selectedLog: null });
  };

  render() {
    const hasLogs = this.state.logs.length > 0;

    return (
      <>
        <Table
          className="table-hover"
          loading={this.state.loading}
          header={this.renderTableHeader()}
          headerList={headerList}
          hasContent={hasLogs}
          hidePagination={false}
          hasPrevious={this.state.logPagination.length > 1}
          hasNext={
            this.state.logPagination[this.state.logPagination.length - 1]
          }
          onNextClick={this.handleNextBtn}
          onPreviousClick={this.handlePreviousBtn}
        >
          {hasLogs ? this.renderTableData() : null}
        </Table>

        {/* detail modal */}
        <Modal
          isOpen={this.state.showLogModal}
          onRequestClose={this.closeLogModal}
          style={{ content: { minWidth: 1500 } }}
        >
          <div className="modal-content">
            {/* header */}
            <div className="modal-header">
              <h5 className="modal-title">Log Detail</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.closeLogModal}
              />
            </div>

            {/* body */}
            <div className="modal-body">
              <div className="card-body">
                <div className="row clearfix">
                  {/* left panel */}
                  <div className="col-md-4 col-sm-12">
                    <h6>General Details</h6>
                    <hr />

                    {/* app engine version */}
                    <InfoItem
                      label="App Engine Version"
                      value={this.state.selectedLog?.appEngineVersion}
                    />
                    <br />

                    {/* path */}
                    <InfoItem
                      label="Path"
                      value={this.state.selectedLog?.path}
                    />
                    <br />

                    {/* code */}
                    <InfoItem
                      label="Code"
                      value={
                        <span
                          className={`tag tag-${
                            this.state.selectedLog?.code === 200
                              ? "success"
                              : "danger"
                          }`}
                        >
                          <b>{this.state.selectedLog?.code}</b>
                        </span>
                      }
                    />
                    <br />

                    {/* userId */}
                    <InfoItem
                      label="User ID"
                      value={this.state.selectedLog?.userId}
                    />
                    <br />

                    {/* created at */}
                    <InfoItem
                      label="Created At"
                      value={convertToMomentInstance(
                        this.state.selectedLog?.createdAt
                      ).format("D MMMM yyyy h:mm a")}
                    />
                    <br />

                    {/* updated at */}
                    <InfoItem
                      label="Last Updated At"
                      value={convertToMomentInstance(
                        this.state.selectedLog?.updatedAt
                      ).format("D MMMM yyyy h:mm a")}
                    />
                    <br />
                  </div>

                  {/* middle panel */}
                  <div className="col-md-4 col-sm-12">
                    {/* body */}
                    <h6>Body</h6>
                    <hr />
                    <ReactJson
                      name="Body"
                      indentWidth={2}
                      displayDataTypes={false}
                      src={this.state.selectedLog?.body || {}}
                      style={jsonContainerStyle}
                      sortKeys={true}
                    />
                    <br />

                    {/* response */}
                    <h6>Response</h6>
                    <hr />
                    <ReactJson
                      name="Response"
                      indentWidth={2}
                      displayDataTypes={false}
                      src={this.state.selectedLog?.response || {}}
                      style={jsonContainerStyle}
                      sortKeys={true}
                    />
                    <br />

                    {/* messages */}
                    <h6>Messages</h6>
                    <hr />
                    <ReactJson
                      name="Messages"
                      indentWidth={2}
                      displayDataTypes={false}
                      src={this.state.selectedLog?.messages || {}}
                      style={jsonContainerStyle}
                      sortKeys={true}
                    />
                    <br />

                    {/* claims */}
                    <h6>Claims</h6>
                    <hr />
                    <ReactJson
                      name="Claims"
                      indentWidth={2}
                      collapsed={true}
                      displayDataTypes={false}
                      src={this.state.selectedLog?.claims || {}}
                      style={jsonContainerStyle}
                      sortKeys={true}
                    />
                  </div>

                  {/* right panel */}
                  <div className="col-md-4 col-sm-12">
                    {/* header */}
                    <h6>Header</h6>
                    <hr />
                    <ReactJson
                      name="Header"
                      indentWidth={2}
                      displayDataTypes={false}
                      src={this.state.selectedLog?.header || {}}
                      style={jsonContainerStyle}
                      sortKeys={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default Index;

import React, { Component } from "react";

export default class AuthRightComponent extends Component {
  render() {
    return (
      <>
        <div className="auth_right">
          {/* <div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src={Slider1} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Calvary Admin Portal</h4>
									<p>Manage Everything</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src={Slider2} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Quality Code and Easy Customizability</h4>
									<p>There are many variations of passages of Lorem Ipsum available.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src={Slider3} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>Cross Browser Compatibility</h4>
									<p>Overview We're a group of women who want to learn JavaScript.</p>
								</div>
							</div>
						</div>
					</div> */}
        </div>
      </>
    );
  }
}

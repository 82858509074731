import config from "./config";

import { getFirestoreMultipleDocsByPagination } from "../../../assets/js/firebase";
import { ERROR_GETTING_DOCUMENT } from "../../../utils/constants";
import globalConfig from "../../../config/index.json";
import {
  createCMBSItem,
  deleteCMBSItem,
  updateCMBSItem,
} from "../../../api/education";
import { lowerCaseFormatter } from "../../../utils/helpers";

// Axios  Helpers

export const deleteCMBS = async ({ cmbsId }) => {
  try {
    const deleteCMBSRes = await deleteCMBSItem({ cmbsId });

    if (deleteCMBSRes.message === "OK") {
      return { status: true };
    } else {
      throw { message: deleteCMBSRes.message };
    }
  } catch (error) {
    console.log("deleteCMBS ~ error", error);
    return { status: false, message: "Something went wrong" };
  }
};

export const getAllCMBS = async ({
  searchField,
  searchValue,
  nextDoc = null,
} = {}) => {
  try {
    let condition = [];
    if (searchField && searchValue) {
      condition = [
        ...condition,
        [searchField, "==", lowerCaseFormatter(searchValue)],
      ];
    }

    let orderBy = [];
    if (searchField === "cmbs" && searchValue) {
      orderBy = ["createdAt desc"];
    } else {
      orderBy = ["cmbs asc"];
    }

    const getCMBSRes = await getFirestoreMultipleDocsByPagination(
      config.collection,
      condition,
      {
        nextDoc,
        orderBy,
        limit: globalConfig.paginationLimit,
      }
    );

    if (getCMBSRes === ERROR_GETTING_DOCUMENT) {
      return {
        status: false,
        data: null,
        message: "Error while getting cmbs codes.",
      };
    }

    return { status: true, data: getCMBSRes };
  } catch (error) {
    console.log("getAllCMBS ~ error", error);
    return { status: false, data: null, message: "Something went wrong" };
  }
};

export const createItem = async function createItem({
  cmbs,
  category1,
  category2,
}) {
  const createItemRes = await createCMBSItem({
    cmbs,
    category1,
    category2,
  });

  if (createItemRes.message === "OK") {
    return { status: true };
  } else {
    return { status: false, message: createItemRes.message };
  }
};

export const updateItem = async function updateItem({
  cmbs,
  category1,
  category2,
}) {
  const updateLocationRes = await updateCMBSItem({
    cmbs,
    category1,
    category2,
  });

  if (updateLocationRes.message === "OK") {
    return { status: true };
  } else {
    return { status: false, message: updateLocationRes.message };
  }
};

// End of Axios Helpers

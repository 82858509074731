import _ from "lodash";
import React, { PureComponent } from "react";
import { convertToMomentInstance } from "../../../utils/helpers";

export default class ItemListItem extends PureComponent {
  renderMessage = () => {
    const { messages } = this.props.item;
    if (_.isEmpty(messages)) {
      return null;
    }
    return (
      <div className="w350">
        {messages.map(({ msg }) => (
          <li className="truncate">{msg}</li>
        ))}
      </div>
    );
  };

  render() {
    const { orderNumber, path, code, userId, createdAt } = this.props.item;
    return (
      <tr onClick={this.props.onPress}>
        {/* number */}
        <td>
          <span>{orderNumber}</span>
        </td>

        {/* path */}
        <td>
          <span>{path}</span>
        </td>

        {/* code */}
        <td>
          <span className={`tag tag-${code === 200 ? "success" : "danger"}`}>
            <b>{code}</b>
          </span>
        </td>

        {/* header */}
        <td>
          <span>{userId}</span>
        </td>

        {/* messages */}
        <td>{this.renderMessage()}</td>

        {/* created at */}
        <td>
          <span>
            {createdAt
              ? convertToMomentInstance(createdAt).format("D MMMM yyyy h:mm a")
              : null}
          </span>
        </td>

        {/* action */}
        <td>
          {/* view button */}
          <button
            type="button"
            onClick={this.props.onPress}
            className="btn btn-icon js-sweetalert"
            title="View"
            data-type="confirm"
          >
            <i className="fa fa-search" />
            &nbsp; View Detail
          </button>
        </td>
      </tr>
    );
  }
}

import React, { useEffect, useState } from "react";
import swal from "sweetalert";

import {
  ACCEPTED_PDF_TYPES,
  ACCEPTED_VIDEO_TYPES,
  PDF,
  VIDEO,
} from "../../utils/constants";

const FileUpload = (props) => {
  const [state, setState] = useState({
    filename: "",
    file: "",
  });

  const { disabled } = props;

  useEffect(() => {
    // delete file path
    if (!props.file && (state.filename || state.file)) {
      handleStatesChange({ filename: "", file: "" });
    }
  }, [props.file]);

  // update multiple fields in states by passing object
  const handleStatesChange = (values = {}) => {
    setState({ ...state, ...values });
  };

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      // check if the file is in accepted type
      if (
        (props.fileType === PDF && !ACCEPTED_PDF_TYPES.includes(file.type)) ||
        (props.fileType === VIDEO && !ACCEPTED_VIDEO_TYPES.includes(file.type))
      ) {
        return swal(
          "Error",
          `Please choose only ${
            props.fileType === PDF ? "PDF file type" : "MP4 or MOV video type"
          }.`,
          "error"
        );
      }

      const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB
      let fileSizeLimit = props.fileSizeLimit;

      if (!fileSizeLimit) {
        if (props.fileType === PDF) {
          fileSizeLimit = 5;
        } else if (props.fileType === VIDEO) {
          fileSizeLimit = 300;
        } else {
          // default file size limit
          fileSizeLimit = 5;
        }
      }

      // check file size limit
      if (fileSize <= fileSizeLimit) {
        handleStatesChange({ filename: file.name, file });
        event.target.value = null;
        props.onChange(file);
      } else {
        swal(
          "Error",
          `${
            props.fileType === PDF ? "PDF" : "Video"
          } file size could not larger than ${fileSizeLimit}MB`,
          "error"
        );
      }
    }
  };

  const onDeleteClick = () => {
    if (!props.disabled) {
      handleStatesChange({ filename: "", file: "" });
      props.onDelete();
    }
  };

  return (
    <>
      {/* header */}
      {props.header || <label className="form-label">Document File *</label>}

      <div className="upload-container">
        {/* delete icon */}
        {state.filename && (
          <i
            className={`fa fa-close ${disabled ? "disabled" : ""}`}
            onClick={onDeleteClick}
          ></i>
        )}

        {/* file icon */}
        {state.filename && <i className="fa fa-file-o"></i>}

        {/* file name */}
        <span className={disabled ? "disabled" : null}>
          {state.filename ||
            `Choose a ${
              props.fileType ? (props.fileType === PDF ? "PDF " : "Video ") : ""
            }file`}
        </span>

        {/* file input */}
        <input
          disabled={disabled}
          className="form-control"
          type="file"
          accept={props.acceptTypes || ".pdf"}
          onChange={onFileChange}
        />
      </div>

      {props.fileType === VIDEO ? (
        <div className="txt-orange text-center mt-10">
          *Note: The maximum supported video resolution is 1920x1080.
        </div>
      ) : null}
    </>
  );
};

export default FileUpload;

// session key
export const USER_INFO = "userInfo";

// user roles
export const SUPER_ADMIN = "superadmin";
export const ADMIN = "admin";
export const CLINICAL = "clinical";
export const USER = "user";

// list of roles
export const USER_ROLES = {
  [SUPER_ADMIN]: "Super Admin",
  [ADMIN]: "Admin",
  [CLINICAL]: "Clinical",
  [USER]: "User",
};

// firestore response messages
export const NO_MATCHING_DOCUMENTS = "No matching documents.";
export const ERROR_GETTING_DOCUMENT = "Error getting document.";
export const ERROR_ADDING_DOCUMENT = "Error adding document.";
export const ERROR_DELETING_DOCUMENT = "Error deleting document.";
export const ERROR_UPDATING_DOCUMENT = "Error updating document.";
export const ERROR_SETTING_DOCUMENT = "Error setting document.";

// create file related name
export const GLOBAL_TYPE = "Global";
export const USER_TYPE = "User";
export const SITE_SPECIFIC_TYPE = "Site Specific";
export const MY_HEALTH = "My Health";
export const LEARN = "Learn";
export const PDF = "pdf";
export const VIDEO = "video";

// general types
export const GENERAL_LEARN = "learn";
export const GENERAL_MY_HEALTH = "myHealth";

// site specific types
export const SITE_SPECIFIC_LEARN = "learn";

// list of accepted file types
export const ACCEPTED_PDF_TYPES = ["application/pdf"];
export const ACCEPTED_VIDEO_TYPES = ["video/mp4", "video/quicktime"];

// list of time zones
export const MOMENT_TIME_ZONES = {
  ACT: "Australia/Sydney",
  NSW: "Australia/Sydney",
  NT: "Australia/Darwin",
  QLD: "Australia/Brisbane",
  SA: "Australia/Adelaide",
  TAS: "Australia/Hobart",
  VIC: "Australia/Melbourne",
  WA: "Australia/Perth",
};

// list of States -> State dropdown data
export const STATES_LIST = [
  { label: "Australian Capital Territory", value: "ACT" },
  { label: "New South Wales", value: "NSW" },
  { label: "Northern Territory", value: "NT" },
  { label: "Queensland", value: "Qld" },
  { label: "South Australia", value: "SA" },
  { label: "Tasmania", value: "Tas" },
  { label: "Victoria", value: "Vic" },
  { label: "Western Australia", value: "WA" },
  { label: "Other", value: "Other" },
];

// list of email types
export const EMAIL_VERIFICATION = "Email Verification";
export const RESET_PASSWORD = "Reset Password";

// text editor toolbar options
export const EDITOR_STYLE = { minHeight: "100px", lineHeight: "120%" };
export const EDITOR_LABELS = {
  "components.controls.blocktype.h4": "Title",
  "components.controls.blocktype.h5": "Subtitle",
  "components.controls.blocktype.normal": "Normal",
};
export const EDITOR_TOOLBAR_OPTIONS = [
  "inline",
  "blockType",
  "link",
  "history",
];
export const EDITOR_INLINE = {
  options: ["bold", "underline"],
};
export const EDITOR_BLOCK_TYPE = {
  options: ["Normal", "H4", "H5"],
};

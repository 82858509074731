import _ from "lodash";
import React, { PureComponent } from "react";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";

import config from "./config";
import { updateItem } from "./utils";
import Routes from "../../../routes/index";
import {
  ERROR_GETTING_DOCUMENT,
  NO_MATCHING_DOCUMENTS,
} from "../../../utils/constants";
import { getFirestoreDoc } from "../../../assets/js/firebase";
import { capitalizeEachWord } from "../../../utils/helpers";

class Update extends PureComponent {
  constructor(props) {
    super(props);

    let cmbsId = this.props.location?.item?.id;

    if (!cmbsId) {
      // no cmbs id, go back to manage cmbs page
      this.props.history.push(Routes.cmbs.index.path);
    }

    //assign props values to state values
    this.state = {
      loading: false,
      isModified: false,

      cmbsId: cmbsId,
      category1: "",
      category2: "",

      errors: [],
    };
  }

  componentDidMount() {
    // prompt user when reload the page without saving
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (this.state.isModified) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };

    // get cmbs item detail
    this.getCMBS();
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  getCMBS = () => {
    try {
      this.setState({ loading: true }, async () => {
        // get cmbs item
        const getCMBSRes = await getFirestoreDoc(
          config.collection,
          this.state.cmbsId
        );

        if (getCMBSRes === ERROR_GETTING_DOCUMENT) {
          throw { message: "Something went wrong while getting CMBS details." };
        }

        if (getCMBSRes === NO_MATCHING_DOCUMENTS) {
          throw { message: "CMBS not found." };
        }

        this.setState({
          loading: false,
          category1: capitalizeEachWord(getCMBSRes.goShareCategory),
          category2: capitalizeEachWord(getCMBSRes.goShareCategory2),
        });
      });
    } catch (error) {
      console.log("getCMBS ~ error", error);
      this.setState({ loading: false });
      swal("Error", error.message, "error");
    }
  };

  handleSave = async () => {
    // check if cmbsId
    if (!this.state.cmbsId) {
      await swal({
        title: "Error",
        text: "Something went wrong with current CMBS item.",
        icon: "error",
        button: "Go Back",
        closeOnEsc: false,
        dangerMode: true,
        closeOnClickOutside: false,
      });
      this.props.history.push(Routes.cmbs.index.path);
      return;
    }

    if (this.handleValidation()) {
      this.submitData();
    } else {
      let text = this.state.errors;
      let errorString = "";
      text.reduce((result, item) => {
        errorString = errorString + "\n" + item;
        return `${item}|`;
      }, "");

      swal("Error", errorString, "error");
    }
  };

  handleChange = (event) => {
    this.state.isModified = true;
    this.setState({ [event.target.name]: event.target.value });
  };

  submitData = () => {
    this.setState({ loading: true }, async () => {
      const result = await updateItem({
        cmbs: this.state.cmbsId,
        category1: this.state.category1,
        category2: this.state.category2,
      });
      if (result.status) {
        this.setState({ loading: false, isModified: false });
        swal("success", "Saved sucessfully", "success").then(() => {
          this.props.history.push(Routes.cmbs.index.path);
        });
      } else {
        this.setState({ loading: false });
        swal("Error", result.message || "Something went wrong", "error");
      }
    });
  };

  handleValidation = () => {
    let errors = [];
    let formIsValid = true;

    // category1
    if (!this.state.category1) {
      formIsValid = false;
      errors.push("Category1 cannot be empty");
    }

    this.state.errors = errors;
    return formIsValid;
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        {/* prompt user when leaving the page before saving the change */}
        <Prompt
          when={this.state.isModified}
          message="You've made some changes. Are you sure you want to leave without saving the change?"
        />

        <div>
          <div className="section-body mt-3">
            <div className="container-fluid">
              <div className="tab-content mt-3">
                <div className="tab-pane active" id="user-add" role="tabpanel">
                  <div className="card">
                    <div className="dimmer active">
                      {loading ? <div className="loader" /> : null}

                      <div
                        className={`card-body ${
                          loading ? "dimmer-content" : null
                        }`}
                      >
                        <div className="row clearfix">
                          {/* cmbs code */}
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">CMBS Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="CMBS *"
                                name="cmbsCode"
                                disabled={true}
                                value={this.state.cmbsId}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row clearfix">
                          {/* category1 */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Category1</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Category1 *"
                                name="category1"
                                value={this.state.category1}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          {/* category2 */}
                          <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Category2</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Category2"
                                name="category2"
                                value={this.state.category2}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>

                        {/* update button */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSave}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Update;

import React, { Component } from "react";
import swal from "sweetalert";

import UserListItem from "./UserListItem";
import config from "./config";

import SearchBox from "../../common/searchBox";
import Table from "../../common/table";
import { userInfoHelper, roleFormatter } from "../../../utils/helpers";
import { deleteUser } from "../../../api/user";
import { fba_screen_view, fba_action_event } from "../../../assets/js/firebase";
import globalConfig from "../../../config/index.json";

import { getAllUsers } from "./utils";

const headerList = [
  { title: "" },
  { title: "Name", className: "w60" },
  { title: "Status" },
  { title: "Role" },
  { title: "Phone" },
  { title: "Action", className: "w100" },
];

class Users extends Component {
  constructor() {
    super();
    fba_screen_view("users");
  }

  state = {
    loading: true,
    userData: [],
    searchField: "email",
    searchValue: "",
    currentUserRole: null,

    // state for pagination
    hasNext: false,
    lastUser: [],
  };

  async componentDidMount() {
    //load list data for the component
    const userInfo = await userInfoHelper(window);
    this.setState({ currentUserRole: userInfo.role });
    this.loadInitailData();
  }

  handleChange = (event) => {
    if (event.target.id === "searchKeyInput") {
      this.setState({ searchField: event.target.value });
    } else if (event.target.id === "searchValueInput") {
      this.setState({ searchValue: event.target.value });
    }
  };

  loadInitailData = async () => {
    try {
      const response = await getAllUsers({
        currentUserRole: this.state.currentUserRole,
      });
      const { data: users } = response;
      let hasNext = false;
      // handle data for pagination
      if (users.length > globalConfig.paginationLimit) {
        users.pop();
        hasNext = true;
      }

      this.setState({
        loading: false,
        userData: users,
        hasNext,
        lastUser: [],
      });
      if (!response.status) {
        swal("Error", response.message, "error");
      }
    } catch (error) {
      this.setState({ loading: false });
      swal("Error!", "Something went wrong!", "error");
    }
  };

  handleUserCallback = ({ status, message, data: users }) => {
    if (status) {
      let hasNext = false;
      // handle data for pagination
      if (users.length > globalConfig.paginationLimit) {
        users.pop();
        hasNext = true;
      }
      this.setState({ loading: false, userData: users, hasNext });
    } else {
      this.setState({ loading: false });
      swal("Error!", message, "error");
    }
  };

  handleRefresh = () => {
    this.setState({ searchValue: "", loading: true }, this.loadInitailData);
  };

  handleSearch = async (selectedFilter, searchValue) => {
    if (selectedFilter === "role") {
      searchValue = roleFormatter(searchValue);
    }
    this.setState({
      searchField: selectedFilter,
      searchValue: searchValue,
      lastUser: [],
    });
    if (searchValue !== "") {
      this.setState({ loading: true });
      const getUserRes = await getAllUsers({
        currentUserRole: this.state.currentUserRole,
        searchField: selectedFilter,
        searchValue,
        startAt: null, // startAfter
      });
      this.handleUserCallback(getUserRes);
    } else {
      swal("Error!", "Search text can not be empty!", "warning");
    }
  };

  handleDelete = async (user) => {
    console.log(user);
    const willDelete = await swal({
      title: `Are you sure you want to remove ${user.firstName} ${user.lastName}?`,
      text: "Once deleted, you will not be able to recover this User",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    });

    if (willDelete) {
      this.setState({ loading: true }, async () => {
        fba_action_event("button_click", "user delete");

        // perform delete user
        const deleteUserRes = await deleteUser(user.id);
        if (deleteUserRes.message === "OK") {
          swal("success", "User deleted sucessfully", "success");
          this.loadInitailData();
        } else {
          this.setState({ loading: false });
          swal("Error", deleteUserRes.message, "error");
        }
      });
    }
  };

  renderTableHeader = () => {
    return (
      <>
        <h3 className="card-title">User List</h3>
        <SearchBox
          filterList={config.searchFilter}
          handleSearch={this.handleSearch}
          handleRefresh={this.handleRefresh}
        />
      </>
    );
  };

  renderTableData = () => {
    return this.state.userData.map((user) => (
      <UserListItem
        key={user.id}
        userInfo={user}
        userId={user.id}
        currentUserRole={this.state.currentUserRole}
        onPressDelete={() => this.handleDelete(user)}
      />
    ));
  };

  handlePreviousBtn = async () => {
    this.setState({ loading: true });

    // pop twice to get the previous cursor
    this.state.lastUser.pop();
    const lastDoc = this.state.lastUser[this.state.lastUser.length - 1];
    const getUserRes = await getAllUsers({
      currentUserRole: this.state.currentUserRole,
      searchField: this.state.searchField,
      searchValue: this.state.searchValue,
      startAt: lastDoc, // startAfter
    });
    this.handleUserCallback(getUserRes);
  };

  handleNextBtn = async () => {
    this.setState({ loading: true });

    const { userData } = this.state;
    const lastDoc = userData[userData.length - 1].doc;

    // update state this way to previous re-render
    this.state.lastUser.push(lastDoc);

    const getUserRes = await getAllUsers({
      currentUserRole: this.state.currentUserRole,
      searchField: this.state.searchField,
      searchValue: this.state.searchValue,
      startAt: lastDoc, // startAfter
    });
    this.handleUserCallback(getUserRes);
  };

  render() {
    const hasUser = this.state.userData.length > 0;

    return (
      <Table
        loading={this.state.loading}
        header={this.renderTableHeader()}
        headerList={headerList}
        hasContent={hasUser}
        hidePagination={false}
        hasPrevious={this.state.lastUser.length > 0}
        hasNext={this.state.hasNext}
        onNextClick={this.handleNextBtn}
        onPreviousClick={this.handlePreviousBtn}
      >
        {hasUser ? this.renderTableData() : null}
      </Table>
    );
  }
}

export default Users;

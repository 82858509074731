import Dashboard from "../components/Calvary/Dashboard/Dashboard";
import Users from "../components/Calvary/Users/Users";
import AddUsers from "../components/Calvary/Users/AddUsers";
import UpdateUser from "../components/Calvary/Users/UpdateUser";
import AppSetting from "../components/AppPages/AppSetting";
import Login from "../components/Authentication/login";
import Signup from "../components/Authentication/signup";
import ForgotPassword from "../components/Authentication/forgotpassword";
import NotFound from "../components/Authentication/404";
import InternalServer from "../components/Authentication/500";
import Welcome from "../components/Calvary/Welcome";

// hospitals import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import hospitalConfig from "../components/Calvary/Hospital/config";
import hospitalIndex from "../components/Calvary/Hospital/index";
import hospitalAdd from "../components/Calvary/Hospital/Add";
import hospitalUpdate from "../components/Calvary/Hospital/Update";

// admissions import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import admissionConfig from "../components/Calvary/Admission/config";
import admissionIndex from "../components/Calvary/Admission/index";
import admissionUpdate from "../components/Calvary/Admission/Update";

// documents import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import documentConfig from "../components/Calvary/Document/config";
import documentCreateNew from "../components/Calvary/Document/CreateNew";
import documentMyHealth from "../components/Calvary/Document/MyHealth";
import documentMyHealthUpdate from "../components/Calvary/Document/MyHealthUpdate";
import documentEducation from "../components/Calvary/Document/Education";
import documentEducationUpdate from "../components/Calvary/Document/EducationUpdate";

// cmbs import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import cmbsConfig from "../components/Calvary/CMBS/config";
import cmbsIndex from "../components/Calvary/CMBS/index";
import cmbsAdd from "../components/Calvary/CMBS/Add";
import cmbsUpdate from "../components/Calvary/CMBS/Update";

// notofocations import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import notificationConfig from "../components/Calvary/Notifications/config";
// import notificationIndex from './Calvary/Notifications/index'
import notificationGeneral from "../components/Calvary/Notifications/GeneralNotification";
// import notificationUpdate from './Calvary/Notifications/Update'

// logs import section, change the path and cofig object name only, config object name need to be replace on the routes definitions
import logConfig from "../components/Calvary/Logs/config";
import logIndex from "../components/Calvary/Logs/index";

const Routes = {
  // all dashboard routes
  dashboard: {
    index: {
      path: "/",
      name: "dashboard",
      exact: true,
      pageTitle: "Dashboard",
      component: Dashboard,
    },
  },
  // end of dashboard routes

  // all user routes
  users: {
    index: {
      path: "/users",
      name: "dashboard",
      exact: true,
      pageTitle: "All Users",
      component: Users,
    },
    add: {
      path: "/users-add",
      name: "users-add",
      exact: true,
      pageTitle: "Add User",
      component: AddUsers,
    },
    update: {
      path: "/users-update",
      name: "users-update",
      exact: true,
      pageTitle: "Update Users",
      component: UpdateUser,
    },
  },
  // end of user routes

  // all hospital routes
  hospitals: {
    index: {
      path: hospitalConfig.navigationRoot,
      name: hospitalConfig.rootRouteName,
      exact: true,
      pageTitle: hospitalConfig.rootPageTitle,
      component: hospitalIndex,
    },
    add: {
      path: hospitalConfig.createPath,
      name: hospitalConfig.createPathName,
      exact: true,
      pageTitle: hospitalConfig.createPageTitle,
      component: hospitalAdd,
    },
    update: {
      path: hospitalConfig.updatePath,
      name: hospitalConfig.updatePathName,
      exact: true,
      pageTitle: hospitalConfig.updatePageTitle,
      component: hospitalUpdate,
    },
  },
  // end of hospital routes

  // all admission routes
  admissions: {
    index: {
      path: admissionConfig.navigationRoot,
      name: admissionConfig.rootRouteName,
      exact: true,
      pageTitle: admissionConfig.rootPageTitle,
      component: admissionIndex,
    },
    update: {
      path: admissionConfig.updatePath,
      name: admissionConfig.updatePathName,
      exact: true,
      pageTitle: admissionConfig.updatePageTitle,
      component: admissionUpdate,
    },
  },
  // end of admission routes

  // all documents routes
  documents: {
    createNew: {
      path: documentConfig.createNewPath,
      name: documentConfig.createNewPathName,
      exact: true,
      pageTitle: documentConfig.createNewPageTitle,
      component: documentCreateNew,
    },
    myHealth: {
      path: documentConfig.myHealthPath,
      name: documentConfig.myHealthPathName,
      exact: true,
      pageTitle: documentConfig.myHealthPageTitle,
      component: documentMyHealth,
    },
    myHealthUpdate: {
      path: documentConfig.myHealthUpdatePath,
      name: documentConfig.myHealthUpdatePathName,
      exact: true,
      pageTitle: documentConfig.myHealthUpdatePageTitle,
      component: documentMyHealthUpdate,
    },
    education: {
      path: documentConfig.educationPath,
      name: documentConfig.educationPathName,
      exact: true,
      pageTitle: documentConfig.educationPageTitle,
      component: documentEducation,
    },
    educationUpdate: {
      path: documentConfig.educationUpdatePath,
      name: documentConfig.educationUpdatePathName,
      exact: true,
      pageTitle: documentConfig.educationUpdatePageTitle,
      component: documentEducationUpdate,
    },
  },
  // end of documents routes

  // all cmbs routes
  cmbs: {
    index: {
      path: cmbsConfig.navigationRoot,
      name: cmbsConfig.rootRouteName,
      exact: true,
      pageTitle: cmbsConfig.rootPageTitle,
      component: cmbsIndex,
    },
    add: {
      path: cmbsConfig.createPath,
      name: cmbsConfig.createPathName,
      exact: true,
      pageTitle: cmbsConfig.createPageTitle,
      component: cmbsAdd,
    },
    update: {
      path: cmbsConfig.updatePath,
      name: cmbsConfig.updatePathName,
      exact: true,
      pageTitle: cmbsConfig.updatePageTitle,
      component: cmbsUpdate,
    },
  },
  // end of cmbs routes

  // all notification routes
  notifications: {
    add: {
      path: notificationConfig.createGeneralPath,
      name: notificationConfig.createGeneralPathName,
      exact: true,
      pageTitle: notificationConfig.createGeneralPageTitle,
      component: notificationGeneral,
    },
  },
  // end of notification routes

  // all log routes
  logs: {
    index: {
      path: logConfig.navigationRoot,
      name: logConfig.rootRouteName,
      exact: true,
      pageTitle: logConfig.rootPageTitle,
      component: logIndex,
    },
  },
  // end of log routes

  // all authentication routes
  auths: {
    login: {
      path: "/login",
      name: "login",
      exact: true,
      pageTitle: "Tables",
      component: Login,
    },
    signUp: {
      path: "/signup",
      name: "signup",
      exact: true,
      pageTitle: "Tables",
      component: Signup,
    },
    forgotPassword: {
      path: "/forgotpassword",
      name: "forgotpassword",
      exact: true,
      pageTitle: "Tables",
      component: ForgotPassword,
    },
  },
  // end of authentication routes

  // other routes
  others: {
    welcome: {
      path: "/welcome",
      name: "welcome",
      exact: true,
      pageTitle: "Welcome",
      component: Welcome,
    },
    notFound: {
      path: "/notfound",
      name: "notfound",
      exact: true,
      pageTitle: "Tables",
      component: NotFound,
    },
    internalServer: {
      path: "/internalserver",
      name: "internalserver",
      exact: true,
      pageTitle: "Tables",
      component: InternalServer,
    },
    appSetting: {
      path: "/app-setting",
      name: "app-setting",
      exact: true,
      pageTitle: "App Setting",
      component: AppSetting,
    },
  },
  // end of other routes
};

export const roleRoute = {
  superadmin: {
    routes: [
      Routes.users.index,
      Routes.users.add,
      Routes.users.update,
      Routes.hospitals.index,
      Routes.hospitals.add,
      Routes.hospitals.update,
      Routes.documents.createNew,
      Routes.documents.education,
      Routes.documents.educationUpdate,
      Routes.cmbs.index,
      Routes.cmbs.add,
      Routes.cmbs.update,
      Routes.notifications.add,
      Routes.logs.index,
    ],
  },
  admin: {
    routes: [
      Routes.users.index,
      Routes.users.add,
      Routes.users.update,
      Routes.hospitals.index,
      Routes.hospitals.update,
      Routes.cmbs.index,
      Routes.cmbs.add,
      Routes.cmbs.update,
    ],
  },
  clinical: {
    routes: [
      Routes.documents.createNew,
      Routes.documents.education,
      Routes.documents.educationUpdate,
    ],
  },
  common: {
    routes: [
      Routes.dashboard.index,
      Routes.auths.login,
      Routes.auths.signUp,
      Routes.others.notFound,
      Routes.others.internalServer,
    ],
  },
};

export default Routes;

let controller = '/notification';
let collection = 'notifications';
let rootRouteName =  'notifications';
let navigationRoot = '/notifications';
let rootPageTitle = 'Notifications';
module.exports = {
    controller: controller,
    tableFieldList:[
        "title",
        "body",
    ],
    notificationFieldList:[
        "title",
        "body",
    ],
    emailFieldList:[
        "subject",
        "body",
    ],
    navigationRoot: '/notifications',
    collection: collection,
    messageType: ["Notification", "Email"],

    //EndPoints
    deleteEP: controller + '/delete',
    searchEP: controller + '/search',
    createEP: controller + '/create',
    updateEP: controller + '/update',
    getAllEP: controller + '/',

    // Routing values
    rootPageTitle: rootPageTitle,
    rootRouteName: rootRouteName,
    updatePath: navigationRoot + '-update',
    updatePathName: rootRouteName + '-update',
    updatePageTitle: 'Update ' + rootPageTitle,
    createGeneralPath: navigationRoot + '-general',
    createGeneralPathName: rootRouteName + '-General',
    createGeneralPageTitle: rootPageTitle + ' Create General',

}
import axios, { preAuthAxios, errorHandler } from "./index";
import { roleFormatter } from "../utils/helpers";

export const createUser = async function createUser(
  email,
  password,
  firstName,
  lastName,
  phone,
  role,
  dob,
  state,
  status
) {
  try {
    const instance = await axios();
    const response = await instance.post("/user/createUser", {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      role: roleFormatter(role),
      dob,
      state,
      status: status,
    });
    return response?.data;
  } catch (error) {
    console.log("createUser ~ error", error);
    return errorHandler(error);
  }
};

export const updateUser = async (
  userName,
  email,
  firstName,
  lastName,
  phone,
  role,
  status
) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/updateUser", {
      userId: userName,
      // email: email,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      role: roleFormatter(role),
      status: status,
    });
    return response?.data;
  } catch (error) {
    console.log("updateUser ~ error", error);
    return errorHandler(error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/deleteUser", {
      userId,
    });
    return response?.data;
  } catch (error) {
    console.log("deleteUser ~ error", error);
    return errorHandler(error);
  }
};

export const passwordReset = async (data) => {
  try {
    const instance = await preAuthAxios();
    const response = await instance.post("/user/sendPasswordResetEmail", {
      data: data,
    });

    return response?.data;
  } catch (error) {
    console.log("passwordReset ~ error", error);
    return errorHandler(error);
  }
};

export const sendVerifyEmailAP = async (email) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/sendVerifyEmailAP", {
      data: email,
    });

    return response?.data;
  } catch (error) {
    console.log("sendVerifyEmailAP ~ error", error);
    return errorHandler(error);
  }
};

export const checkEmailStatusAP = async (userId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/checkEmailStatusAP", {
      userId,
    });

    return response?.data;
  } catch (error) {
    console.log("checkEmailStatusAP ~ error", error);
    return errorHandler(error);
  }
};

export const getEmailVerificationUrlAP = async (userId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/getEmailVerificationUrlAP", {
      userId,
    });

    return response?.data;
  } catch (error) {
    console.log("getEmailVerificationUrlAP ~ error", error);
    return errorHandler(error);
  }
};

export const getResetPasswordUrlAP = async (userId) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/getResetPasswordUrlAP", {
      userId,
    });

    return response?.data;
  } catch (error) {
    console.log("getResetPasswordUrlAP ~ error", error);
    return errorHandler(error);
  }
};

export const updateEmailAddressAP = async ({ userId, email }) => {
  try {
    const instance = await axios();
    const response = await instance.post("/user/updateEmailAddressAP", {
      userId,
      email,
    });

    return response?.data;
  } catch (error) {
    console.log("updateEmailAddressAP ~ error", error);
    return errorHandler(error);
  }
};

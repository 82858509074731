//Testing code
// window.location.replace("calvaryApp://");
// setTimeout(function () {
//   window.location.replace("https://itunes.apple.com/app/id12345678");
// }, 2000);
//End Basic Testing

function DeepLinker(options) {
  if (!options) {
    throw new Error("no options");
  }

  var hasFocus = true;
  var didHide = false;

  // window is blurred when dialogs are shown
  function onBlur() {
    hasFocus = false;
  }

  // document is hidden when native app is shown or browser is backgrounded
  function onVisibilityChange(e) {
    if (e.target.visibilityState === "hidden") {
      didHide = true;
    }
  }

  // window is focused when dialogs are hidden, or browser comes into view
  function onFocus() {
    if (didHide) {
      if (options.onReturn) {
        options.onReturn();
      }

      didHide = false; // reset
    } else {
      // ignore duplicate focus event when returning from native app on
      // iOS Safari 13.3+
      if (!hasFocus && options.onFallback) {
        // wait for app switch transition to fully complete - only then is
        // 'visibilitychange' fired
        setTimeout(function () {
          // if browser was not hidden, the deep link failed
          if (!didHide) {
            options.onFallback();
          }
        }, 1000);
      }
    }

    hasFocus = true;
  }

  // add/remove event listeners
  // `mode` can be "add" or "remove"
  function bindEvents(mode) {
    [
      [window, "blur", onBlur],
      [document, "visibilitychange", onVisibilityChange],
      [window, "focus", onFocus],
    ].forEach(function (conf) {
      conf[0][mode + "EventListener"](conf[1], conf[2]);
    });
  }

  // add event listeners
  bindEvents("add");

  // expose public API
  this.destroy = bindEvents.bind(null, "remove");
  this.openURL = function (url) {
    // it can take a while for the dialog to appear
    var dialogTimeout = 500;

    setTimeout(function () {
      if (hasFocus && options.onIgnored) {
        options.onIgnored();
      }
    }, dialogTimeout);

    window.location = url;
  };
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function openAppStore() {
  var OS = getMobileOperatingSystem();
  console.log("openAppStore OS: ", OS);
  if ("iOS" === OS) {
    // window.location.replace(
    //   "https://apps.apple.com/au/app/disney/id1446075923"
    // );
    // window.location = "https://apps.apple.com/au/app/disney/id1446075923";
  } else if ("Android" === OS) {
    // window.location =
    //   "http://play.google.com/store/apps/details?id=com.bigwig.calvaryAppTest";
  } else {
  }
}

/* usage */

// var url = "calvaryApp://";
// var badURL = "lksadjgajsdhfaskd://slkdfs";

var linker = new DeepLinker({
  onIgnored: function () {
    //App is not available navigate to app store base on the OS
    openAppStore();
    console.log("browser failed to respond to the deep link");
  },
  onFallback: function () {
    console.log("dialog hidden or user returned to tab");
  },
  onReturn: function () {
    console.log("user returned to the page from the native app");
  },
});

export default linker;

let controller = "/hospitals";
let collection = "locations";
let rootRouteName = "hospitals";
let navigationRoot = "/hospitals";
let rootPageTitle = "Hospitals";
module.exports = {
  controller: controller,
  tableFieldList: [
    "name",
    "shortName",
    "siteCode",
    "episoftSiteCode",
    "email",
    "phone",
    "address",
    "address2",
    "postCode",
    "state",
    "suburb",
    "timeZone",
    "website",
    "type",
    "isPrivate",
    "geo",
    "cardImage",
    "cardURI",
    "goShareIdentifier",
    "visitingHrs",
    "shareCont",
    "verifyPatientEmail",
    "status",
    "createdAt",
    "updatedAt",
  ],
  navigationRoot: "/hospitals",
  collection: collection,

  searchFilter: [
    { value: "name", title: "Name" },
    { value: "postCode", title: "Post Code" },
    { value: "state", title: "State" },
  ],

  //EndPoints
  deleteEP: controller + "/delete",
  searchEP: controller + "/search",
  createEP: controller + "/create",
  updateEP: controller + "/update",
  getAllEP: controller + "/",

  // Routing values
  rootPageTitle: rootPageTitle,
  rootRouteName: rootRouteName,
  updatePath: navigationRoot + "-update",
  updatePathName: rootRouteName + "-update",
  updatePageTitle: "Update " + rootPageTitle,
  createPath: navigationRoot + "-create",
  createPathName: rootRouteName + "-create",
  createPageTitle: rootPageTitle + " Create",
};

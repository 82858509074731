const initialState = {
  isIdlerPaused: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_IDLER_STATUS":
      return {
        ...state,
        isIdlerPaused: !!action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
